@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,700,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,500,600,900);
/*
** Table Of Contents

**bootstrap overrides
**vertical margins and paddings
**general styles
**icons
**buttons
**common sections styles
**subpages
**light section - .ls
**grey section - .ls.ms
**dark sections - .ds
**color section - .cs
**boxed layout
**header
**footer
**intro slider
**main menu (min 1200px) styles
**main menu mobile styles
**widgets
**shortcodes
**utilites
**plugins
	- Owl Carousel
	- FlexSlider
	- prettyPhoto
	- Easy pieChart
	- toTop
*/
/*
**bootstrap overrides
*/
/* modal */
.modal-backdrop.in {
	filter: alpha(opacity=80);
	opacity: .8;
}

/*breadcrumbs*/
/*forms*/
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea,
select,
.form-control {
	height: 60px;
	border: none;
	background-color: #ffffff;
	border-radius: 0;
	color: #212225;
	font-style: normal;
	line-height: 1.42857143;
	padding-left: 35px;
	padding-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
}

select {
	background-color: transparent;
	border: none;
}

textarea {
	height: auto;
}

textarea:focus,
select:focus,
button:focus {
	outline: none;
}

/* select styling */
.select-group {
	position: relative;
}

.select-group select:hover {
	cursor: pointer;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select::-ms-expand {
	display: none;
}

.form-control option {
	color: #292a2e;
	background-color: #ffffff;
}

.form-control, .form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-control.invalid {
	-webkit-box-shadow: 0 0 0 2px #f96380;
	box-shadow: 0 0 0 2px #f96380;
}

.form-control:-moz-placeholder {
	opacity: 1;
}

.form-control::-moz-placeholder {
	opacity: 1;
}

.form-control:focus:-moz-placeholder {
	color: transparent;
	opacity: 1;
}

.form-control:focus::-moz-placeholder {
	color: transparent;
	opacity: 1;
}

.form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

/*icons for forms */
form label + [class*="icon2-"] {
	position: absolute;
	font-size: 24px;
	color: #989898;
	left: 10px;
	top: 19px;
}

form label + [class*="icon2-"] + .form-control {
	padding-left: 50px;
}

label {
	font-weight: normal;
}

.form-control + [class*="icon2-"] {
	position: absolute;
	font-size: 24px;
	left: 20px;
	top: 50%;
	color: #f96380;
}

/*custom styles*/
.paypal-button-container {
	background-color: white;
}

/*carousel*/
/*collapse accordion*/
.panel-heading .panel-title {
	font-size: inherit;
	letter-spacing: 0;
	padding: 0;
	position: relative;
}

.panel-heading .panel-title > a {
	line-height: 1;
	display: block;
	padding: 22px 65px 22px 40px;
	color: #ffffff;
	background-color: #f96380;
	border: none;
	word-wrap: break-word;
}

.panel-heading .panel-title > a.collapsed:hover,
.panel-heading .panel-title > a:hover {
	color: #ffffff;
	background-color: #f96380;
}

.panel-heading .panel-title > a.collapsed:hover i,
.panel-heading .panel-title > a:hover i {
	color: #ffffff;
}

.panel-heading .panel-title > a.collapsed:hover:before, .panel-heading .panel-title > a.collapsed:hover:after,
.panel-heading .panel-title > a:hover:before,
.panel-heading .panel-title > a:hover:after {
	color: #ffffff;
}

.panel-heading .panel-title > a.collapsed {
	background-color: #ffffff;
}

.panel-heading .panel-title > a:after {
	color: #ffffff;
	content: '--';
	letter-spacing: -2px;
	position: absolute;
	line-height: 58px;
	text-align: center;
	right: 0;
	top: 0;
	bottom: 0;
	width: 60px;
}

.panel-heading .panel-title > a.collapsed:after {
	content: '+';
	color: #292a2e;
}

.panel-heading .panel-title > a > i {
	position: relative;
	top: 1px;
	padding-right: 12px;
	color: #ffffff;
}

.panel-heading .panel-title > a.collapsed > i {
	color: #f96380;
}

.panel {
	box-shadow: none;
}

.panel-group .panel {
	border-radius: 0;
	background-color: transparent;
}

.panel-default > .panel-heading {
	background-color: transparent;
	color: inherit;
	position: relative;
	border: none;
	border-radius: 0;
	padding: 0;
}

.panel-default {
	border-color: transparent;
}

.panel-group .panel + .panel {
	margin-top: 10px;
}

.panel-body {
	border: 1px solid transparent;
	border-top: none !important;
	background-color: #ffffff;
}

.panel-body .media-left {
	padding-top: 8px;
	padding-right: 28px;
}

.panel-body .media-left .img-circle {
	max-width: 50px;
}

.panel-body .media-left img {
	max-width: none;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
	padding: 23px 30px 30px;
	border-radius: 0px;
}

/*progress bars*/
p + .progress {
	margin-top: -10px;
}

.progress {
	overflow: hidden;
	height: 40px;
	position: relative;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
}

.progress-bar {
	background-color: #f96380;
	color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: 40px;
}

.progress-bar span {
	position: relative;
	color: #ffffff;
	top: 10px;
	font-size: 0.9em;
}

.progress-bar-info {
	background-color: #8aa5ec;
}

.progress-bar-warning {
	background-color: #f0ad4e;
}

.progress-bar-danger {
	background-color: #d9534f;
}

.alert {
	border-radius: 0;
	border-width: 0 0 0 4px;
	padding: 30px;
}

/*nav*/
.nav > li > a {
	padding: 7px 10px 8px;
	color: #292a2e;
}

.nav > li > a:hover,
.nav > li > a:focus {
	background-color: inherit;
}

/*tabs*/
.tab-content {
	border-radius: 0 0 0 0;
	padding: 30px 30px;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.tab-content.no-border {
	padding: 36px 0 0;
	border: none;
	background-color: transparent !important;
}

.tab-content.top-color-border {
	position: relative;
}

.tab-content.top-color-border:before {
	content: '';
	position: absolute;
	left: 0px;
	right: 0px;
	top: -1px;
	height: 6px;
	background-color: #f96380;
}

.tab-content .featured-tab-image {
	margin: -30px -31px 24px -31px;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.nav-tabs {
	border-bottom-color: transparent;
	margin-top: 0px;
	margin-bottom: -1px;
}

.nav-tabs > li > a {
	font-size: 14px;
	text-transform: uppercase;
	padding: 7px 13px 8px;
	margin-right: 1px;
	margin-top: 0px;
	border: none;
	border: 1px solid #292a2e;
	background-color: #292a2e;
	color: #ffffff;
	border-radius: 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	border-color: transparent;
	background-color: #f96380;
	color: #ffffff;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs.nav-justified > .active > a {
	border-color: #292a2e;
	background-color: #292a2e;
	color: #f96380;
}

/*vertical tabs*/
.vertical-tabs .tab-content.no-border {
	padding-top: 0;
}

.vertical-tabs .nav > li > a {
	background-color: #ffffff;
	color: #365510;
	text-transform: capitalize;
	font-weight: 600;
	letter-spacing: 0.2em;
	margin-bottom: 10px;
	position: relative;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.vertical-tabs .nav > li > a i {
	position: relative;
	top: 0;
	padding-right: 15px;
	font-size: 24px;
	color: #50cc60;
	vertical-align: middle;
}

.vertical-tabs .nav > li.active a,
.vertical-tabs .nav > li > a:hover {
	color: #fff;
	background-color: #50cc60;
}

.vertical-tabs .nav > li.active a i,
.vertical-tabs .nav > li > a:hover i {
	color: #ffffff;
}

@media (min-width: 768px) {
	.vertical-tabs .nav > li.active {
		position: relative;
		z-index: 2;
	}
}

.bach-sign {
	color: #50cc60;
	text-transform: none !important;
	letter-spacing: 0em !important;
}

/*bs buttons*/
.btn {
	padding: 18px 30px;
	text-transform: uppercase;
	margin: 0 2px 6px 0;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.btn:hover, .btn:focus {
	outline: none;
}

li.media:before,
li.media:after {
	display: block;
	content: '';
	clear: both;
}

/* dropdown menu */
.dropdown-menu {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0;
	border-color: rgba(0, 0, 0, 0.1);
	z-index: 1001;
	font-size: 16px;
	margin-top: 0;
}

[class*="right"] .dropdown-menu {
	right: 0;
	left: auto;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
	color: #292a2e;
	background-color: transparent;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
	padding: 13px 8px;
	border-color: rgba(0, 0, 0, 0.1);
}

.table {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table td + td {
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
	border-bottom-width: 1px;
}

.table-responsive {
	border: none;
}

.well {
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
	border-radius: 0;
}

.embed-responsive-3by2 {
	padding-bottom: 66.666%;
}

/*.embed-placeholder:before {
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	content: "\e696";
	font-family: 'rt-icons-2';
	position: absolute;
	color: #ffffff;
	font-size: 30px;
	left: 50%;
	top: 50%;
	margin-left: -20px;
	margin-top: -5px;
}*/

.embed-placeholder:hover:before {
	opacity: 0.4;
}

@media (max-width: 767px) {
	.container {
		max-width: 500px;
	}
}

/*
**vertical margins and paddings
*/
/*top and bottom paddings for sections*/
.section_padding_0 > [class*="container"] {
	padding-top: 0;
	padding-bottom: 0;
}

.section_padding_15 > [class*="container"] {
	padding-top: 15px;
	padding-bottom: 15px;
}

.section_padding_25 > [class*="container"] {
	padding-top: 25px;
	padding-bottom: 25px;
}

.section_padding_50 > [class*="container"] {
	padding-top: 50px;
	padding-bottom: 50px;
}

.section_padding_75 > [class*="container"] {
	padding-top: 75px;
	padding-bottom: 75px;
}

.section_padding_100 > [class*="container"] {
	padding-top: 100px;
	padding-bottom: 100px;
}

/*top paddings for sections*/
.section_padding_top_0 > [class*="container"] {
	padding-top: 0;
}

.section_padding_top_5 > [class*="container"] {
	padding-top: 5px;
}

.section_padding_top_15 > [class*="container"] {
	padding-top: 15px;
}

.section_padding_top_25 > [class*="container"] {
	padding-top: 25px;
}

.section_padding_top_30 > [class*="container"] {
	padding-top: 30px;
}

.section_padding_top_40 > [class*="container"] {
	padding-top: 40px;
}

.section_padding_top_50 > [class*="container"] {
	padding-top: 50px;
}

.section_padding_top_65 > [class*="container"] {
	padding-top: 65px;
}

.section_padding_top_75 > [class*="container"] {
	padding-top: 55px;
}

.section_padding_top_100 > [class*="container"] {
	padding-top: 100px;
}

.section_padding_top_120 > [class*="container"] {
	padding-top: 120px;
}

/*bottom paddings for sections*/
.section_padding_bottom_0 > [class*="container"] {
	padding-bottom: 0;
}

.section_padding_bottom_5 > [class*="container"] {
	padding-bottom: 5px;
}

.section_padding_bottom_15 > [class*="container"] {
	padding-bottom: 15px;
}

.section_padding_bottom_25 > [class*="container"] {
	padding-bottom: 25px;
}

.section_padding_bottom_30 > [class*="container"] {
	padding-bottom: 30px;
}

.section_padding_bottom_40 > [class*="container"] {
	padding-bottom: 40px;
}

.section_padding_bottom_50 > [class*="container"] {
	padding-bottom: 50px;
}

.section_padding_bottom_65 > [class*="container"] {
	padding-bottom: 65px;
}

.section_padding_bottom_75 > [class*="container"] {
	padding-bottom: 75px;
}

.section_padding_bottom_100 > [class*="container"] {
	padding-bottom: 100px;
}

.section_padding_v120 > [class*="container"] {
	padding-top: 120px;
	padding-bottom: 120px;
}

.section_padding_v100 > [class*="container"] {
	padding-top: 50px;
	padding-bottom: 100px;
}

.section_padding_v75 > [class*="container"] {
	padding-top: 75px;
	padding-bottom: 75px;
}

.section_padding_v60 > [class*="container"] {
	padding-top: 60px;
	padding-bottom: 60px;
}

/* Vertical margins and paddings for all */
.margin-b0 {
	margin-bottom: 0 !important;
}

.margin-t0 {
	margin-top: 0;
}

.margin-t-5 {
	margin-top: -5px;
}

.margin-b5 {
	margin-bottom: 5px !important;
}

.margin-b25 {
	margin-bottom: 25px !important;
}

.margin-b35 {
	margin-bottom: 35px !important;
}

.margin-b40 {
	margin-bottom: 40px !important;
}

.margin-b50 {
	margin-bottom: 50px;
}

.margin-b60 {
	margin-bottom: 60px;
}

.margin-b75 {
	margin-bottom: 75px;
}

.margin-t20 {
	margin-top: 20px;
}

.margin-t30 {
	margin-top: 30px;
}

.margin-t40 {
	margin-top: 40px;
}

.padding-t30 {
	padding-top: 30px;
}

.padding-t60 {
	padding-top: 60px;
}

.padding-t120 {
	padding-top: 120px;
}

.padding-b0 {
	padding-bottom: 0px !important;
}

.padding-b60 {
	padding-bottom: 60px;
}

.padding-b80 {
	padding-bottom: 80px;
}

.padding-v80 {
	padding-top: 80px;
	padding-bottom: 80px;
}

.padding-v5 {
	padding-top: 5px;
	padding-bottom: 5px;
}

/* vertical alignmetn in columns */
.table_section [class*="container"] {
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.table_section .row {
	display: table;
	min-width: 100%;
	height: 100%;
	margin-left: 0;
	margin-right: 0;
}

.table_section [class*="col-"] {
	display: table-cell;
	vertical-align: middle;
	float: none;
}

@media screen and (max-width: 767px) {
	.table_section .row,
	.table_section [class*="col-"] {
		display: block;
		width: auto;
	}
	[class*="col-xs"] + [class*="col-xs"] {
		margin-top: 0 !important;
	}
}

@media screen and (max-width: 991px) {
	.table_section.table_section_md .row,
	.table_section.table_section_md [class*="col-"] {
		display: block;
		width: auto;
	}
}

@media screen and (max-width: 1199px) {
	.table_section.table_section_lg .row,
	.table_section.table_section_lg [class*="col-"] {
		display: block;
		width: auto;
	}
}

@media screen and (min-width: 1200px) {
	/* overlap featured person */
	.top-overlap {
		margin-top: -170px;
	}
	.top-overlap-small {
		margin-top: -100px;
	}
}

html {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: visible;
}

::selection {
	color: #ffffff;
	background-color: #50cc60;
}

::-moz-selection {
	color: #ffffff;
	background-color: #f96380;
}

body {
	color: #365510;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 300;
	line-height: 24px;
	background-color: #FFFAE5;
	overflow: visible;
	overflow-x: hidden;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

img {
	max-width: 100%;
}

a {
	color: #292a2e;
}

a:focus {
	color: #f96380;
}

a,
button {
	text-decoration: none;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

a:hover,
a:before,
a:hover:before,
button:hover {
	text-decoration: none;
	-webkit-transition: all 0.15s linear 0s;
	-moz-transition: all 0.15s linear 0s;
	-o-transition: all 0.15s linear 0s;
	-ms-transition: all 0.15s linear 0s;
	transition: all 0.15s linear 0s;
}

a:focus {
	outline: medium none;
	text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	word-break: break-word;
	color: #292a2e;
}

h4 {
	font-size: 20px;
}

.heading-60 {
	font-size: 60px;
}

@media (max-width: 540px) {
	.heading-60 {
		font-size: 30px;
	}
}

.xlight {
	font-weight: 200;
}

p {
	line-height: 30px;
}

.text-uppercase {
	text-transform: uppercase;
}

.letter-spce-02 {
	letter-spacing: 0.1em;
}

/* Buttons */
.btn-type2 {
	position: relative;
	display: block;
	width: 60px;
	height: 60px;
	margin: auto;
	border-radius: 50%;
	font-size: 12px;
	letter-spacing: normal !important;
	text-align: center;
}

.btn-type2.bg-0 {
	background-image: url("/themes/bach/images/system/new/bg60-1.jpg");
}

.btn-type2.bg-1 {
	background-image: url("/themes/bach/images/system/bg-60-1.jpg");
}

.btn-type2.bg-2 {
	background-image: url("/themes/bach/images/system/bg-60-2.jpg");
}

.btn-type2:before {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	font-family: 'rt-icons-2' !important;
	speak: none;
	font-size: 24px;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	text-align: center;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.btn-type2.menu-btn:before {
	content: "\e6fd";
	line-height: 60px;
	color: #ffffff;
}

.btn-type2.date-btn {
	color: #292a2e;
	font-weight: 400;
	padding-top: 30px;
}

.btn-type2.date-btn:before {
	content: "\e751";
	color: #ffffff;
	padding-top: 10px;
}

.btn-type2.comment-btn {
	color: #ffffff;
	font-weight: 400;
	padding-top: 30px;
}

.btn-type2.comment-btn:hover {
	background-image: url("/themes/bach/images/system/bg-60-2.jpg");
}

.btn-type2.comment-btn:before {
	content: "\e706";
	color: #f96380;
	padding-top: 10px;
}

.btn-type2.comment-btn.bg-1:hover {
	color: #292a2e;
	background-image: url("/themes/bach/images/system/bg-60-2.jpg");
}

.btn-type2.like-btn {
	color: #292a2e;
	font-weight: 400;
	padding-top: 30px;
}

.btn-type2.like-btn:before {
	content: "\e710";
	color: #f96380;
	padding-top: 10px;
}

.btn-type2.like-btn:hover {
	color: #ffffff;
	background-image: url("/themes/bach/images/system/new/bg60-1.jpg");
}

.btn-type2.like-btn:hover:before {
	color: #ffffff;
}

.btn-type2.zoom-btn:before {
	content: "\e735";
	line-height: 60px;
	color: #f96380;
}

.btn-type2.add-btn {
	font-size: 0;
	background-image: url("/themes/bach/images/system/new/bg60-1.jpg");
}

.btn-type2.add-btn:before {
	content: "\e114";
	line-height: 56px;
	font-size: 18px;
	color: #ffffff;
	font-family: 'rt-icons-2' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.btn-type2.add-btn:hover {
	background-image: url("/themes/bach/images/system/bg-60-2.jpg");
}

.btn-type2.add-btn:hover:before {
	color: #f96380;
}

.btn-type2.go-home {
	background-color: #50cc60;
}

.btn-type2.go-home:before {
	content: "\e6ff";
	line-height: 58px;
	color: #ffffff;
}

.btn-type2.go-home:hover {
	background-color: #ffffff;
}

.btn-type2.go-home:hover:before {
	color: #f96380;
}

.widget-title {
	font-size: 30px;
	font-weight: 200;
}

.with-background {
	background-color: #ecedef;
}

.remedy-image {
	margin-bottom: 40px;
}

/*parallax*/
.parallax {
	background-attachment: fixed !important;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-position: 50% 0;
	background-repeat: no-repeat;
	position: relative;
}

@media (max-width: 767px) {
	.parallax {
		-webkit-background-size: auto auto !important;
		-moz-background-size: auto auto !important;
		-o-background-size: auto auto !important;
		background-size: auto auto !important;
	}
}

/*page preloader*/
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #ffffff;
	z-index: 13000;
	height: 100%;
}

.preloader_image {
	width: 100px;
	height: 100px;
	position: absolute;
	left: 50%;
	top: 50%;
	background: url(/themes/bach/images/system/preloader.gif) no-repeat 50% 50% transparent;
	margin: -50px 0 0 -50px;
}

/*
animated elements
*/
.to_animate {
	visibility: hidden;
}

.animated {
	/*IE7 fix*/
	visibility: visible !important;
}

/*video placeholder*/
.embed-responsive-3by2 {
	padding-bottom: 66.666%;
}

/*.embed-placeholder:before {
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	content: "\e696";
	font-family: 'rt-icons-2';
	position: absolute;
	color: #ffffff;
	font-size: 30px;
	left: 50%;
	top: 50%;
	margin-left: -20px;
	margin-top: -5px;
}*/

.embed-placeholder:hover:before {
	opacity: 0.4;
}

.blockquote-big {
	max-width: 82%;
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 42px;
	color: inherit;
	font-size: 20px;
	text-align: center;
	line-height: 1.5;
	padding: 0 70px;
	border: none;
}

.flexslider .blockquote-big {
	margin-top: 0;
}

.blockquote-big h3 {
	font-size: 16px;
	letter-spacing: 0.09em;
	text-transform: uppercase;
	color: inherit;
	margin-top: 30px;
}

.blockquote-big img {
	display: block;
	max-width: 100px;
	border-radius: 50%;
	margin: 25px auto 10px;
}

.blockquote-big .blockqoute-meta {
	font-style: normal;
	font-size: 28px;
}

.blockquote-big .blockqoute-meta:after {
	display: block;
	font-family: 'rt-icons-2';
	font-size: 40px;
	content: "\e601";
	color: #f96380;
	margin-bottom: 10px;
}

.blockquote-big:before, .blockquote-big:after {
	font-style: normal;
	content: "\e977";
	font-family: 'rt-icons-2';
	position: absolute;
	font-size: 420px;
	color: #f9f9f9;
	top: -145px;
	left: -374px;
}

.blockquote-big:after {
	content: "\e978";
	left: auto;
	right: -374px;
}

@media (max-width: 600px) {
	.blockquote-big {
		font-size: 18px;
		max-width: 100%;
		padding-left: 40px;
		padding-right: 40px;
	}
}

img, figure {
	max-width: 100%;
	height: auto;
}

figcaption, .entry-caption {
	padding: 3px 10px;
}

figcaption p, .entry-caption p {
	margin-bottom: 0;
}

a {
	text-decoration: none;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

a:hover {
	color: #f96380;
	text-decoration: none;
	-webkit-transition: all 0.15s linear 0s;
	-moz-transition: all 0.15s linear 0s;
	-o-transition: all 0.15s linear 0s;
	-ms-transition: all 0.15s linear 0s;
	transition: all 0.15s linear 0s;
}

a:focus {
	outline: medium none;
	text-decoration: none;
}

a img {
	-webkit-transition: all 0.15s ease-in-out 0s;
	-moz-transition: all 0.15s ease-in-out 0s;
	-o-transition: all 0.15s ease-in-out 0s;
	-ms-transition: all 0.15s ease-in-out 0s;
	transition: all 0.15s ease-in-out 0s;
}

hr {
	border: none;
	height: 1px;
}

iframe {
	border: none;
	max-width: 100%;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

/*
**styled lists
*/
ul, ol {
	padding-left: 30px;
}

ul.list2 {
	list-style: none;
	padding-left: 20px !important;
	margin-bottom: 20px;
}

ul.list2 li {
	position: relative;
	padding: 8px 0 8px 0px;
}

ul.list2 li:before {
	font-family: 'rt-icons-2';
	font-size: 8px;
	color: #f96380;
	content: "\e7c5";
	position: absolute;
	left: -18px;
	top: 7px;
}

ol.list3 {
	counter-reset: li;
	list-style: none outside none;
	padding: 0;
}

ol.list3 li {
	position: relative;
	padding: 8px 0 8px 38px;
}

ol.list3 li:after {
	content: counter(li, decimal-leading-zero);
	counter-increment: li;
	position: absolute;
	left: 2px;
	top: 50%;
	margin-top: -16px;
	font-weight: 700;
	color: inherit;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	color: #ffffff;
	background-color: #f96380;
	border-radius: 100%;
}

table {
	width: 100%;
	max-width: 100%;
	margin: 10px 0;
}

table td, table th {
	padding: 3px 0;
}

.table_template thead {
	background-color: #f96380;
	color: #292a2e;
}

.table_template thead.light {
	background-color: #ffffff;
}

.table_template td, .table_template th {
	padding: 18px 30px;
}

.table_template tbody tr:nth-of-type(odd) {
	background-color: rgba(238, 238, 238, 0.1);
}

.table_template tbody tr:nth-of-type(even) {
	background-color: rgba(17, 17, 17, 0.05);
}

/*icons in headings, paragraphs and buttons*/
h1 > [class*="rt-icon"],
h2 > [class*="rt-icon"],
h3 > [class*="rt-icon"],
h4 > [class*="rt-icon"],
h5 > [class*="rt-icon"],
h6 > [class*="rt-icon"] {
	font-size: 1.2em;
	position: relative;
	top: .1em;
	line-height: 0;
	padding-right: 0.1em;
}

p > [class*="rt-icon"] {
	font-size: 1.1em;
	position: relative;
	top: .05em;
	line-height: 0;
	padding: 0 0.2em;
}

p > [class*="rt-icon"] + [class*="rt-icon"] {
	margin-left: -0.75em;
}

.theme_buttons a > [class*="rt-icon"],
.theme_button > [class*="rt-icon"] {
	font-size: 1.8em;
	line-height: 0;
	position: relative;
	top: 5px;
	padding: 0 3px;
}

.theme_buttons a > [class*="glyphicon"],
.theme_buttons a > [class*="fa-"],
.theme_button > [class*="glyphicon"],
.theme_button > [class*="fa-"] {
	font-size: 1.5em;
	position: relative;
	top: 3px;
	line-height: 0;
}

/*
**buttons
*/
.theme_buttons a,
.theme_button {
	font-size: 12px;
	font-weight: 900;
	padding: 12px 6px;
	margin-bottom: 2px;
	letter-spacing: 0.2em;
	line-height: 1;
	display: inline-block;
	min-width: 200px;
	text-align: center;
	color: #ffffff;
	border: 2px solid #ffffff;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	position: relative;
	-webkit-transition: all 0.4s linear 0s;
	-moz-transition: all 0.4s linear 0s;
	-o-transition: all 0.4s linear 0s;
	-ms-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.theme_buttons a.with-shadow:hover,
.theme_button.with-shadow:hover {
	text-shadow: -1px -1px 0 #000,
 1px -1px 0 #000,
 -1px 1px 0 #000,
 1px 1px 0 #000;
}

.theme_buttons a.with-bg:hover,
.theme_button.with-bg:hover {
	text-shadow: none !important;
}

.theme_buttons a.with-bg.selected,
.theme_button.with-bg.selected {
	text-shadow: none;
	color: #ffffff !important;
	background-color: #f96380 !important;
	border-color: #f96380 !important;
}

.theme_buttons.color1 a,
.theme_button.color1 {
	color: #f96380;
	border-color: #f96380;
}

.theme_buttons.color1 a:hover, .theme_buttons.color1 a:focus,
.theme_button.color1:hover,
.theme_button.color1:focus {
	color: rgba(249, 99, 128, 0.7);
	text-shadow: none;
}

.theme_buttons.color2 a,
.theme_button.color2 {
	color: #e96ac2;
	border-color: #e96ac2;
}

.theme_buttons.color2 a:hover, .theme_buttons.color2 a:focus,
.theme_button.color2:hover,
.theme_button.color2:focus {
	color: rgba(233, 106, 194, 0.7);
	text-shadow: none;
}

.theme_buttons a:active,
.theme_buttons a:hover,
.theme_buttons a:focus,
.theme_button:active,
.theme_button:hover,
.theme_button:focus {
	color: #f96380;
	opacity: 1;
	text-decoration: none;
	outline: none;
	-webkit-transition: all 0.05s linear 0s;
	-moz-transition: all 0.05s linear 0s;
	-o-transition: all 0.05s linear 0s;
	-ms-transition: all 0.05s linear 0s;
	transition: all 0.05s linear 0s;
}

.theme_buttons a:active,
.theme_button:active {
	top: 1px;
}

.theme_buttons.inverse a,
.theme_button.inverse {
	color: #292a2e;
	background-color: transparent;
	border: 2px solid #292a2e;
}

.muted_buttons a,
.muted_button {
	opacity: 0.2;
}

.small_buttons a,
.small_button {
	min-width: auto;
	padding: 12px 20px;
	text-transform: uppercase;
}

.square_buttons a,
.square_button {
	padding-left: 10px;
	padding-right: 10px;
	min-width: auto;
}

.wide_buttons a,
.wide_button {
	padding-left: 50px;
	padding-right: 50px;
}

.block_buttons a,
.block_button {
	display: block;
	width: 100%;
	text-align: center;
}

/* About Section */
.about .entry-thumbnail {
	position: relative;
	margin-top: -60px;
	z-index: 2;
}

.about .embed-responsive {
	z-index: 2;
}

.about .abot-cont {
	position: relative;
	padding-left: 30px;
	padding-right: 30px;
}

.about .abot-cont > * {
	position: relative;
	z-index: 1;
}

.about .abot-cont:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: -35%;
	z-index: 0;
}

.about .abot-cont h2 {
	margin-top: 0;
	margin-bottom: 25px;
}

.about .meta-item {
	float: left;
	display: block;
	width: 33.333333333%;
	padding: 0 0 5px 40px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-size: 12px;
	font-weight: 500;
	line-height: normal;
	border-right: none;
	border-top: none;
	border-bottom: none;
}

.about .meta-item:first-child {
	width: calc(33.333333% - 30px);
	padding-left: 0;
	border-left-color: transparent;
}

.about .meta-item span {
	display: block;
	font-size: 40px;
	font-weight: 900;
}

@media (max-width: 991px) {
	.boxed .about .abot-cont:before {
		left: 0;
	}
}

.entry-thumbnail img {
	width: 100%;
}

@media (max-width: 1199px) {
	.about .entry-thumbnail {
		margin-top: -30px;
	}
}

@media (max-width: 991px) {
	.about .entry-thumbnail {
		margin-top: 30px;
	}
}

@media (max-width: 1200px) and (min-width: 992px) {
	.about .meta-item:first-child {
		width: 33.333333%;
	}
}

@media (max-width: 480px) {
	.about .abot-cont {
		padding-left: 0;
		padding-right: 0;
	}
	.about .meta-item {
		padding-left: 10px;
		font-size: 10px;
	}
	.about .meta-item:first-child {
		width: 33.333333%;
	}
	.about .meta-item span {
		font-size: 24px;
	}
}

/* Services Section */
.services .col-xs-12 {
	padding: 0;
}

.services .owl-stage-outer {
	padding-top: 10px;
}

.services .vertical-item {
	text-align: center;
}

.services .vertical-item h2 {
	margin-bottom: 20px;
}

.services .vertical-item p {
	padding: 0 30px;
	margin-bottom: 0;
}

@media (max-width: 479px) {
	.services .vertical-item p {
		padding: 0;
	}
}

.services .item-media {
	display: block;
	border-radius: 50%;
	overflow: visible;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}
@media (max-width: 768px) {
	.services .item-media {
		margin: 0 auto;
		width: 60%;

	}

	.services .vertical-item {
		margin-bottom: 20px;
	}
}

.services .item-media:hover {
	transform: scale(1.05);
}

.services .item-media:hover .btn-type2 {
	opacity: 1;
	transform: scale(0.95);
}

.services .item-media:hover .abs-link {
	opacity: 1;
}

.services .item-media:hover .abs-link:before {
	opacity: 0.8;
}

.services .media-links {
	border-radius: 50%;
}

.services .media-links:before {
	display: none;
}

.services .media-links .btn-type2 {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -30px;
	margin-left: -30px;
	opacity: 0;
	z-index: 1;
}

.services .media-links .abs-link {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
}

.services .media-links .abs-link:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border-radius: 50%;
	border: none;
	background-color: #292a2e;
	opacity: 0;
}

.services img {
	border-radius: 50%;
}

.services .owl-nav > div {
	margin-top: -30px;
	width: 60px !important;
	height: 60px;
	background-image: url("/themes/bach/images/system/new/bg60-1.jpg");
	border-radius: 50%;
}

.services .owl-nav > div:first-child {
	left: 22px !important;
}

.services .owl-nav > div:first-child:after {
	content: "\e7c0" !important;
	left: 19px !important;
}

.services .owl-nav > div:last-child {
	right: 22px !important;
}

.services .owl-nav > div:last-child:after {
	content: "\e7c1" !important;
	right: 19px !important;
}

.services .owl-nav > div:after {
	top: 0 !important;
	margin-top: 0 !important;
}

.services .owl-nav > div:hover:after {
	color: #292a2e !important;
}

.services .owl-carousel {
	margin-bottom: 0;
}

.services .owl-carousel:hover .owl-nav {
	opacity: 1;
	filter: alpha(opacity=100);
}

@media (max-width: 1920px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 220px;
	}
}

@media (max-width: 1600px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 182.5px;
	}
}

@media (max-width: 1440px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 164px;
	}
}

@media (max-width: 1366px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 155.3px;
	}
}

@media (max-width: 1280px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 145.3px;
	}
}

@media (max-width: 1024px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 167.8px;
	}
}

@media (max-width: 768px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 164.8px;
	}
}

@media (max-width: 768px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 164.8px;
	}
}

@media (max-width: 640px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 181.5px;
	}
}

@media (max-width: 480px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 135.8px;
	}
}

@media (max-width: 360px) {
	.services .owl-carousel:hover .owl-nav > div {
		top: 140.8px;
	}
}

/* Testimonials Section */
.testimonials {
	background-position: center;
}

.testimonials i {
	font-size: 40px;
}

.testimonials-carousel {
	margin-top: 25px;
}

.testimonials-carousel .carousel-indicators {
	bottom: 50px;
	width: auto;
	margin-left: 0;
	left: 0;
	right: 0;
}

.testimonials-carousel .carousel-indicators li {
	position: relative;
	width: 60px;
	height: 60px;
	margin: 6px;
	font-size: 0;
	border: none;
	border-radius: 50%;
	vertical-align: middle;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	opacity: 0.3;
}

.testimonials-carousel .carousel-indicators li img {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	border-radius: 50%;
}

.testimonials-carousel .carousel-indicators li.active {
	margin: 0;
	width: 70px;
	height: 70px;
	opacity: 1;
}

.testimonials-carousel .carousel-indicators li:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	border-radius: 50%;
	background-color: transparent;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.testimonials-carousel .carousel-indicators li:hover {
	opacity: 1;
}

.testimonials-carousel .carousel-indicators li:hover:after {
	background-color: rgba(41, 42, 46, 0.5);
}

.testimonials-carousel p {
	max-width: 760px;
	margin-right: auto;
	margin-left: auto;
	font-size: 20px;
}

.testimonials-carousel .media {
	margin-top: 120px;
}

.testimonials-carousel .media a {
	font-size: 12px;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

.testimonials-carousel .media span {
	display: block;
	font-size: 12px;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

/* Blog Section */
.blog-sec {
	/*background-image: url("../images/parallax1.jpg");*/
	/* Carusel item */
}

.blog-sec:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(139, 148, 92, 1);
}

.blog-sec .col-xs-12 p {
	font-family: 'Raleway', sans-serif;
	font-size: 20px;
	max-width: 760px;
	margin-right: auto;
	margin-left: auto;
}

.blog-sec .single-item {
	overflow: hidden;
	background-size: cover;
	background-position: top center;
}

.blog-sec .single-item .single-item-body {
	z-index: 1;
}

.blog-sec .single-item .single-item-body h4 {
	margin-bottom: 20px;
}

.blog-sec .single-item .item-meta {
	position: absolute;
	top: -32px;
	left: 0;
	right: 0;
	text-align: center;
}

.blog-sec .single-item .btn-type2 {
	display: inline-block;
	margin: 2px;
	vertical-align: text-top;
}

.blog-sec #carousel-generic {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.blog-sec #carousel-generic .carousel-control {
	background-image: none;
	height: 245px;
	line-height: 245px;
	font-size: 14px;
	opacity: 1;
}

.blog-sec #carousel-generic .carousel-control:hover {
	background-image: none !important;
}

.blog-sec #carousel-generic .carousel-control.left {
	text-align: right;
}

.blog-sec #carousel-generic .carousel-control.right {
	text-align: left;
}

.blog-nav {
	display: inline-block;
	text-align: center;
	color: #ffffff;
	font-size: 10px;
	margin-top: 30px;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.blog-nav .pages {
	margin: 0 40px;
	font-size: 12px;
	font-weight: 900;
	letter-spacing: 0.2em;
}

.blog-nav:hover {
	text-shadow: -1px -1px 0 #000,
 1px -1px 0 #000,
 -1px 1px 0 #000,
 1px 1px 0 #000;
}

/* Team Section */
#team-carousel .media {
	padding: 11px;
}

#team-carousel .media-body {
	display: inline-block;
	max-width: 67%;
	padding: 0 15px;
	text-align: left;
}

#team-carousel .media-body p {
	color: #365510;
	font-size: 14px;
}

#team-carousel .media-body h4 {
	font-size: 23px;
	margin-top: 0;
}

#team-carousel .media-body h4:after {
	content: "";
	display: block;
	margin: 15px;
	width: 100px;
	height: 1px;
	border-top: none;
	border-left: none;
	border-right: none;
}

#team-carousel .media-body span {
	display: block;
	font-family: 'Raleway', sans-serif;
	/*font-size: 20px;*/
	margin-bottom: 15px;
}

#team-carousel .media-body p {
	margin: 15px 0;
}

#team-carousel .member-social {
	display: inline-block;
	margin: auto;
}

#team-carousel .social-icon {
	margin: 1px;
}

#team-carousel .photo-link-wrap {
	display: inline-block;
	max-width: 30%;
	padding-right: 4.3%;
	margin-right: 2.4%;
	font-size: 0;
	line-height: 0;
	border-top: none;
	border-left: none;
	border-bottom: none;
}

#team-carousel .photo-link {
	position: relative;
	display: inline-block;
	max-width: 100%;
	border-radius: 50%;
	overflow: hidden;
}

#team-carousel .photo-link:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(41, 42, 46, 0.8);
	border-radius: 50%;
	opacity: 0;
}

#team-carousel .photo-link img {
	width: 100%;
	border-radius: 50%;
}

#team-carousel .photo-link span {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -30px;
	margin-left: -30px;
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

/*#team-carousel .photo-link:hover {
	transform: scale(1.05);
}

#team-carousel .photo-link:hover:before {
	opacity: 1;
}

#team-carousel .photo-link:hover span {
	opacity: 1;
}*/

#team-carousel .carousel-control {
	position: relative;
	text-shadow: none;
	background-image: none;
	opacity: 1;
	top: auto;
	font-size: 40px;
	text-align: center;
	width: auto;
	margin: 0 20px;
}

#team-carousel .carousel-control span {
	position: relative;
	left: auto;
	right: auto;
}

.team-carousel-nav {
	position: absolute;
	display: inline-block;
	left: 44.6%;
	bottom: 25px;
	margin-left: -63px;
	font-size: 0;
}

@media (max-width: 991px) {
	#team-carousel .media-body {
		display: block;
		max-width: 100%;
		padding: 0;
		text-align: center;
	}
	#team-carousel .photo-link-wrap {
		display: block;
		max-width: 470px;
		padding-right: 0;
		margin: 0 auto 30px;
		border-right: none;
	}
	#team-carousel .team-carousel-nav {
		position: static;
		display: block;
		width: 110px;
		margin: auto;
	}
}

/* Gallery Section */
.gallery .btn-type3 {
	margin: 3px 5px;
}

.gallery div[class*="col-"] {
	padding: 0;
}

.gallery div.col-xs-12 {
	padding-right: 15px;
	padding-left: 15px;
}

.gallery .filters {
	margin-top: 60px;
	margin-bottom: 60px;
}

/* Contacts Section */
.contact-description {
	margin-bottom: 30px;
	color: #365510 !important;
}
.contact-description p {
	text-align: justify;
	font-size: 14px;
}
.contact-description h2 {
	font-size: 16px;
	color: #365510 !important;
}
.contacts .container span {
	display: inline-block;
	font-size: 48px;
	margin-bottom: 15px;
}

.contacts .container a {
	display: block;
}

.contacts .container .col-md-4:nth-of-type(2) {
	border-top: none;
	border-bottom: none;
}

@media (max-width: 991px) {
	.contacts .container .col-md-4:nth-of-type(2) {
		border-right: none;
		border-left: none;
	}
}

.contacts-form-sec .container-fluid {
	position: relative;
	padding-top: 80px;
	padding-bottom: 40px;
}

.contacts-form-sec .container-fluid:before {
	content: "";
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: #402E27;
}

.form-wrap {
	max-width: 570px;
	margin: auto;
}

.contact-form {
	font-size: 0;
	/*target Internet Explorer 9 and Internet Explorer 10:*/
}

.contact-form > * {
	font-size: 14px;
}

.contact-form select {
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.contact-form select option {
	font-size: 14px;
	font-weight: 300;
}

.contact-form select::-ms-expand {
	display: none;
}

@media screen and (min-width: 0\0) {
	.contact-form select {
		background: none;
		padding: 5px;
	}
}

.contact-form #email,
.contact-form #phone,
.contact-form #first_name,
.contact-form #last_name,
.contact-form #country_of_residence,
.contact-form #age,
.contact-form .form-control-group {
	width: calc(50% - 5px);
	display: inline-block;
}

.contact-form #time {
	width: 100%;
}

.contact-form #first_name,
.contact-form #last_name,
.contact-form #email,
.contact-form #phone,
.contact-form #country_of_residence,
.contact-form #age,
.contact-form .form-control-group,
.contact-form .time-select,
.contact-form textarea {
	margin-bottom: 10px;
}

.contact-form #first_name,
.contact-form #country_of_residence,
.contact-form .form-control-group {
	margin-right: 10px;
}

@media (max-width: 480px) {
	.contact-form #first_name,
	.contact-form #last_name,
	.contact-form #email,
	.contact-form #phone,
	.contact-form #country_of_residence,
	.contact-form #age,
	.contact-form .form-control-group,
	.contact-form .time-select {
		display: block;
		width: 100%;
	}
	.contact-form #first_name,
	.contact-form .form-control-group {
		margin-right: 0;
	}
}

.contact-form textarea {
	display: block;
	height: 200px;
	width: 100%;
	resize: none;
}

.contact-form button {
	display: block;
	font-size: 12px;
	background: none;
	margin: 40px auto 0;
}

.contact-form .time-select select {
	position: relative;
	z-index: 1;
}

.contact-form .time-select i {
	position: absolute;
	top: 18px;
	right: 23px;
	font-size: 24px;
	color: #f96380;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	z-index: 0;
}

.contact-form .time-select:hover i {
	filter: alpha(opacity=50);
	opacity: 0.5;
}

.form-control-group {
	position: relative;
}

.form-control-group .ui-datepicker-trigger {
	position: absolute;
	margin: 0;
	right: 20px;
	top: 0;
	bottom: 0;
	font-family: 'rt-icons-2' !important;
	speak: none;
	font-size: 0;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	color: #f96380;
	border: none;
}

.form-control-group .ui-datepicker-trigger:hover {
	color: #292a2e;
}

.form-control-group .ui-datepicker-trigger:before {
	content: "\e751";
	font-size: 24px;
}

.ui-datepicker {
	display: none;
	width: 280px;
	background-color: #ffffff;
}

.ui-datepicker-header {
	height: 60px;
	padding: 17px 35px;
	text-align: center;
	background-color: #f96380;
}

.ui-datepicker-prev {
	float: left;
}

.ui-datepicker-next {
	float: right;
}

.ui-datepicker-title,
.ui-datepicker-header a {
	font-size: 20px;
	font-weight: 300;
	color: #ffffff;
}

.ui-datepicker-header a {
	position: relative;
	top: 5px;
	font-family: 'rt-icons-2' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ui-datepicker-prev:before {
	content: "\f104";
}

.ui-datepicker-next:before {
	content: "\f105";
}

.ui-datepicker-header a:hover {
	cursor: pointer;
	color: #292a2e;
}

.ui-datepicker-calendar {
	position: relative;
	margin: auto;
}

.ui-datepicker-calendar thead th {
	font-size: 16px;
	font-weight: 300;
	color: #ffffff;
	text-align: center;
	height: 40px;
	background-color: #292a2e;
}

.ui-datepicker-calendar tbody tr:first-of-type td {
	padding-top: 25px;
}

.ui-datepicker-calendar tbody tr:last-of-type td {
	padding-bottom: 25px;
}

.ui-datepicker-calendar tbody td {
	width: 30px;
	height: 30px;
	text-align: center;
}

.ui-datepicker-current-day {
	position: relative;
	background-color: #ecedef;
}

.ui-datepicker-current-day:after {
	content: "";
	position: absolute;
	top: -3px;
	right: 0;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-right: 5px solid #f96380;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}

.ui-datepicker-calendar tbody td a {
	font-weight: 300;
	color: #292a2e;
}

.ui-datepicker-calendar tbody td.ui-datepicker-current-day a {
	color: #f96380;
	font-weight: 900;
}

.ui-datepicker-calendar tbody td:hover a {
	color: #f96380;
}

.ui-datepicker-buttonpane {
	height: 70px;
	padding: 23px 35px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 700;
	color: #f96380;
	background-color: #f0f1eb;
}

.ui-datepicker-other-month {
	cursor: pointer;
	color: #989898;
}

.ui-datepicker-buttonpane button:hover {
	color: #292a2e;
}

.ui-datepicker-buttonpane button {
	text-transform: uppercase;
	background: none;
	border: none;
}

.ui-datepicker-buttonpane button:active,
.ui-datepicker-buttonpane button:focus {
	outline: none;
}

.ui-datepicker-close {
	float: right;
}

.ui-datepicker-current {
	float: left;
}

/*.page_breadcrumbs + .contacts + .contacts-form-sec {
	padding-bottom: 120px;
}*/

.page_breadcrumbs + .contacts + .contacts-form-sec input,
.page_breadcrumbs + .contacts + .contacts-form-sec select,
.page_breadcrumbs + .contacts + .contacts-form-sec textarea {
	border: 2px solid rgba(41, 42, 46, 0.1) !important;
}

/* Other Offices */
.other-offices .col-sm-6 {
	padding: 0;
}

.office-link {
	display: inline-block;
	position: relative;
}

.office-link .item-media {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	font-size: 0;
	background-color: rgba(41, 42, 46, 0.8);
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.office-link .item-media div {
	display: inline-block;
	vertical-align: middle;
}

.office-link .item-media div:first-child {
	height: 100%;
}

.office-link p {
	font-size: 20px;
	font-weight: 100;
	margin-bottom: 0;
}

.office-link h2 {
	color: #ffffff !important;
	word-break: break-all;
	margin: 0;
}

.office-link:hover .item-media {
	background-color: rgba(249, 99, 128, 0.8);
}

@media (max-width: 1199px) and (min-width: 768px) {
	.office-link h2.heading-60 {
		font-size: 30px;
	}
}

@media (max-width: 480px) {
	.office-link h2.heading-60 {
		font-size: 30px;
	}
}

/* Breadcrumbs */
.page_breadcrumbs {
	position: relative;
	background-color: rgba(255, 250, 229, 1);
/*	-moz-box-shadow: inset 0 -10px 10px -10px #C0C0C0;
	-webkit-box-shadow: inset 0 -10px 10px -10px #C0C0C0;
	box-shadow: inset 0 -10px 10px -10px #C0C0C0;*/
	/*border-bottom: 1px solid #C0C0C0;*/
}

.page_breadcrumbs .container {
	padding-top: 0px;
	padding-bottom: 4px;
}

.page_breadcrumbs:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: url("../images/breadcrumbs-bg.jpg");
	background-size: cover !important;
	background-position: 50% 0;
	background-repeat: no-repeat;
	z-index: -1;
}

.page_breadcrumbs h1 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 30px;
	line-height: 34px;
	color: #ffffff;
}

.page_breadcrumbs li {
	font-family: "Raleway", sans-serif;
	font-weight: 500;
}

.page_breadcrumbs li a {
	color: #365510;
}

.page_breadcrumbs li a:hover,
.page_breadcrumbs li a:focus {
	text-decoration: underline;
}

.page_breadcrumbs .breadcrumb {
	margin-bottom: 0;
	padding-bottom: 0;
	padding-top: 2px;
	background: transparent;
}

.page_breadcrumbs .breadcrumb li + li:before {
	display: none;
}

.page_breadcrumbs .breadcrumb > .active {
	color: #50cc60;
}

.page_breadcrumbs li {
	margin: 0 0px;
	color: #27282C;
}

/* Blog Gallery Slider*/
.blog-gallery .media {
	position: relative;
}

.blog-gallery .media-object-wrap {
	width: 100%;
	max-height: 600px;
	overflow: hidden;
}

.blog-gallery .media-body {
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	font-size: 0;
	width: 100%;
	text-align: center;
}

.blog-gallery .media-body:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(41, 42, 46, 0.5);
}

.blog-gallery .media-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	z-index: 1;
}

.blog-gallery .media-content p {
	font-family: "Raleway", sans-serif;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: #ffffff;
}

.blog-gallery .media-content p:after {
	content: "";
	display: block;
	margin: 20px auto 0;
	width: 120px;
	height: 3px;
	background-color: #f96380;
}

.blog-gallery .media-content h2 {
	max-width: 700px;
	margin: 40px auto;
	font-size: 70px;
	font-weight: 500;
	color: #ffffff;
}

.blog-gallery .blog-gallery-nav {
	position: absolute;
	display: block;
	bottom: 20px;
	left: 50%;
	font-size: 0;
	margin-left: -55px;
}

.blog-gallery .blog-gallery-nav a {
	position: relative;
	font-size: 0;
	padding: 0 20px;
	background-image: none !important;
	width: auto;
	text-shadow: none;
	vertical-align: middle;
	filter: alpha(opacity=100);
	opacity: 1;
}

.blog-gallery .blog-gallery-nav a:hover {
	color: #f96380;
	background-image: none !important;
}

.blog-gallery .blog-gallery-nav a span {
	font-size: 40px;
	vertical-align: middle;
}

.blog-gallery .blog-gallery-nav .divider {
	display: inline-block;
	width: 1px;
	height: 35px;
	vertical-align: middle;
	background-color: rgba(255, 255, 255, 0.25);
}

.spreader-vbar {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

@media (max-width: 991px) {
	.blog-gallery h2 {
		font-size: 40px;
	}
}

@media (max-width: 767px) {
	.blog-gallery .media-content p:after {
		margin: 5px auto;
	}
	.blog-gallery .media-content h2 {
		font-size: 24px;
		margin: 10px auto;
	}
}

@media (max-width: 480px) {
	.blog-gallery .blog-gallery-nav {
		position: static;
		margin: 15px auto 0;
		text-align: center;
	}
	.blog-gallery .blog-gallery-nav .divider {
		background-color: rgba(41, 42, 46, 0.25);
	}
}

.single-item-body h2 a:hover,
.single-item-body h3 a:hover {
	opacity: 1;
}

.single-item.top-post .item-media {
	position: absolute;
	top: -30px;
	left: 0;
	width: 100%;
	max-width: 100% !important;
	text-align: center;
}

.single-item.top-post .item-media .btn-type2 {
	display: inline-block;
}

.single-item.top-post .single-item-body {
	padding-top: 55px;
	padding-bottom: 60px;
}

.single-item.top-post .single-item-body h4 {
	margin-bottom: 0;
}

@media (max-width: 991px) and (min-width: 768px) {
	.big-item .single-item-body {
		padding: 60px 15px;
	}
}

.entry-header h2 {
	margin-top: 0;
	font-size: 40px;
}

@media (max-width: 540px) {
	.big-item .single-item-body {
		padding: 60px 15px;
	}
	.entry-header h2 {
		font-size: 24px;
	}
}

.post-adds {
	margin: 25px 0;
	font-size: 0;
	font-family: 'Roboto', sans-serif;
}

.post-adds > * {
	display: inline-block;
	padding-top: 13px;
	padding-bottom: 12px;
	vertical-align: middle;
}

.post-adds a {
	width: 50px;
	text-align: center;
	font-size: 24px;
}

.post-adds a:nth-of-type(1) {
	background-color: #404040;
}

.post-adds span {
	padding-right: 20px;
	padding-left: 20px;
	padding-bottom: 13px;
	font-size: 14px;
}

.post-adds .with_border {
	border-left: none;
	border-top: none;
	border-bottom: 0;
}

.post-adds .views-count {
	line-height: 26px;
	padding: 13px;
	color: #ffffff;
	background-color: #50cc60;
}

.post-category a {
	font-weight: 900;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

.slider-post .carousel-control {
	border: 1px solid #ffffff;
	color: #ffffff !important;
	border-radius: 0px;
	width: 50px;
	height: 50px;
	line-height: 50px;
	left: -50px;
	top: 50%;
	margin-top: -25px;
	text-shadow: none;
	opacity: 1;
	background-image: none !important;
	filter: alpha(opacity=100);
}

.slider-post .carousel-control.right {
	left: auto;
	right: -50px;
}

.slider-post .carousel:hover .carousel-control {
	left: 50px;
}

.slider-post .carousel:hover .carousel-control.right {
	left: auto;
	right: 50px;
}

.single-item.small-img {
	background-color: #ffffff;
}

@media (min-width: 991px) {
	.single-item.small-img .post-adds a:nth-of-type(2) {
		display: none;
	}
}

.single-item.small-img .single-item-body {
	padding: 40px 60px 40px 20px;
}

@media (max-width: 1199px) {
	.single-item.small-img .single-item-body {
		padding: 40px 30px 40px 20px;
	}
}

@media (max-width: 991px) {
	.single-item.small-img .single-item-body {
		padding: 40px 30px;
	}
}

.video-container {
	position: relative;
	padding-bottom: 66.5%;
}

.pagination > li a {
	width: 40px;
	height: 40px;
	padding: 0;
	line-height: 40px;
	font-weight: 700;
	text-align: center;
	margin: 0 10px 10px 0;
	border: none;
	border-radius: 50% !important;
	color: #292a2e;
	background-color: #ffffff;
}

.pagination > li a:hover {
	color: #ffffff;
	background-color: #f96380;
}

.pagination > li.active > a,
.pagination > li.active > a:hover,
.pagination > li.active > a:focus {
	color: #ffffff;
	background-color: #f96380;
}

/* Sidebar */
.sidebar {
	max-width: 400px;
}

.sidebar .widget {
	margin-top: 80px;
}

.sidebar .widget:first-child {
	margin-top: 0;
}

.sidebar .widget:first-child h3 {
	margin-top: 0;
}

.sidebar .widget h3 {
	margin-bottom: 35px;
	word-break: break-all;
}

@media (max-width: 767px) {
	.sidebar {
		float: none;
		margin: 80px auto 0;
	}
}

@media (max-width: 400px) {
	.sidebar h3 {
		font-size: 24px;
	}
}

/* Full Blog Post */
.full-post {
	font-family: 'Raleway', sans-serif;
}

.full-post .single-item .single-item-body {
	padding-right: 0;
	padding-left: 0;
	background-color: transparent;
}

p.important {
	font-size: 20px;
	font-weight: 500;
}

blockquote {
	position: relative;
	margin-top: 25px;
	margin-bottom: 25px;
	padding: 10px 20px 20px 30px;
	border-left: 5px solid #f96380;
}

blockquote .media-object {
	max-width: 40px;
	border-radius: 50%;
}

blockquote .media-body {
	font-size: 14px;
	vertical-align: middle;
}

.list1 {
	list-style: none;
	padding: 0;
	margin-top: 20px;
	margin-bottom: 20px;
}

.list1 li {
	margin-bottom: 20px;
}

.list1 li:before {
	content: "";
	position: relative;
	bottom: 1px;
	display: inline-block;
	width: 4px;
	height: 4px;
	margin-right: 10px;
	background-color: #f96380;
	border-radius: 50%;
	vertical-align: middle;
}

.alignleft {
	float: left;
	margin: 5px 30px 15px 0;
	max-width: 50%;
}

.blog-more-toggle {
	display: block;
	padding: 12px 0;
	margin: 30px 0 0;
	background-color: #292a2e;
}

.blog-more-toggle span {
	color: #ffffff;
	font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 0.2em;
}

.blog-more-toggle.collapsed .blog-more-hidden {
	display: inline-block;
}

.blog-more-toggle.collapsed .blog-more-visible {
	display: none;
}

.blog-more-toggle .blog-more-hidden {
	display: none;
}

.author-meta .item-content {
	padding: 30px 10px;
}

.author-meta .item-content p {
	margin-right: 70px;
	margin-bottom: 0;
}

.author-meta h4 {
	font-size: 24px;
	font-weight: 500;
}

.author-meta .author-social {
	position: absolute;
	top: 30px;
	right: 40px;
}

.author-meta .author-social a.social-icon {
	display: block;
	margin-left: 0;
}

@media (max-width: 991px) {
	.author-meta .item-content {
		padding-left: 20px;
	}
}

@media (max-width: 480px) {
	.author-meta .item-content p {
		margin-right: 40px;
	}
	.author-meta .author-social {
		right: 25px;
	}
}

.comment-respond {
	margin: 60px 0;
}

.comment-respond .row {
	margin: 0 -5px;
}

.comment-respond [class*="col-"] {
	padding-right: 5px;
	padding-left: 5px;
}

.comment-respond textarea {
	height: 200px;
	resize: none;
}

.comment-respond .form-control {
	border: none;
}

.comment-respond #submit {
	position: relative;
	width: 250px;
	margin-top: 20px;
	padding-left: 20px;
	text-align: left;
	background-color: #292a2e;
	border: none;
}

.comment-respond #submit:after {
	position: absolute;
	right: 15px;
	top: 15px;
	content: "\f105";
	font-family: 'rt-icons-2' !important;
	speak: none;
	font-size: 30px;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 26px;
	color: #ffffff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.comment-respond #submit:hover {
	color: #ffffff;
	text-shadow: none;
	background-color: #f96380;
}

@media (max-width: 500px) {
	.comments-area {
		font-size: 12px;
		line-height: 22px;
	}
}

.comment-list {
	padding-left: 0;
}

@media (min-width: 992px) {
	.comments-area ol.children {
		padding-left: 90px;
	}
}

@media (max-width: 480px) {
	.comments-area ol.children {
		padding-left: 10px;
	}
}

.comments-area .comment-body {
	margin-bottom: 20px;
}

.comments-area .media-object {
	max-width: 85px;
	border-radius: 50%;
}

.comments-area li {
	list-style: none;
}

.comments-area .media {
	position: relative;
}

.comments-area .media-left {
	padding-right: 0;
}

.comments-area .media-body {
	padding: 15px 0 0 20px;
}

.comments-area .reply {
	position: absolute;
	right: 0;
	top: 0;
}

.comments-area .reply a {
	display: block;
	width: 60px;
	height: 60px;
	font-size: 24px;
	text-align: center;
	line-height: 60px;
	border-radius: 50%;
}

.comment-meta .comment-date {
	display: block;
	margin-bottom: 15px;
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	text-transform: uppercase;
}

.comment-meta .author_url {
	display: inline-block;
	margin-right: 65px;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
}

.nav-buttons .col-sm-6 {
	padding-right: 5px;
	padding-left: 5px;
}

.blog-btn {
	display: inline-block;
	margin: 20px auto 0;
	position: relative;
}

.blog-btn .item-media {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	padding: 10%;
	font-size: 16px;
	text-align: center;
	background-color: rgba(41, 42, 46, 0.5);
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

@media (max-width: 350px) {
	.blog-btn .item-media {
		padding: 10% 5%;
	}
}

.blog-btn .item-media p {
	margin-bottom: 0;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 600;
	color: #50cc60;
}

.blog-btn .item-media h3 {
	font-size: 24px;
	line-height: 30px;
	font-weight: 600;
	margin-bottom: 0;
	margin-top: 10px;
	color: #ffffff;
}

.blog-btn:hover .item-media {
	background-color: rgba(41, 42, 46, 0.8);
}

@media (max-width: 1199px) and (min-width: 768px) {
	.blog-btn .item-media {
		font-size: 12px;
	}
	.blog-btn .item-media p:last-child {
		font-size: 14px;
		line-height: 20px;
	}
}

/* Full Video Post */
.full-post.full-video-post .container {
	padding-top: 40px;
}

.full-post.full-video-post .post-adds {
	margin-top: 0;
}

.full-post.full-video-post .single-item-body {
	padding-top: 0;
}

.full-video-cont .container {
	padding-top: 120px;
	padding-bottom: 40px;
}

.full-video-cont .post-date {
	display: block;
	font-weight: 500;
	text-transform: uppercase;
	color: #e2001a;
}

.full-video-cont h2 {
	margin-top: 0;
	margin-bottom: 35px;
	font-size: 40px;
}

@media (max-width: 480px) {
	.full-video-cont h2 {
		font-size: 24px;
	}
}

/* Page Top line */
.page_topline {
	background-color: #292a2e;
}

.page_topline .media-body {
	padding: 0 25px;
	height: 60px;
	text-align: center;
	font-size: 0;
}

.page_topline .media-body > div {
	display: inline-block;
	vertical-align: middle;
}

.page_topline .media-body .media-content {
	font-size: 14px;
}

.page_topline .post-category {
	margin-right: 25px;
}

@media (max-width: 640px) {
	.page_topline .post-title span {
		display: none;
	}
}

.topline-slider {
	position: relative;
}

.topline-nav a {
	position: absolute;
	top: 15px;
	font-size: 0;
	background-image: none !important;
	width: auto;
	text-shadow: none;
	vertical-align: middle;
	filter: alpha(opacity=100);
	opacity: 1;
}

.topline-nav a.left {
	left: 0;
}

.topline-nav a.right {
	right: 0;
}

.topline-nav a:hover {
	background-image: none !important;
}

.topline-nav a span {
	font-size: 30px;
	vertical-align: middle;
}

/* About Page */
.about.page .abot-cont:before {
	display: none;
}

.team.dark {
	background-color: #292a2e;
}

.team.dark h2,
.team.dark .media-body h4,
.team.dark #team-carousel .media-body span {
	color: #ffffff;
}

.team.dark #team-carousel .media-body h4:after {
	background-color: rgba(255, 255, 255, 0.1);
}

.team.dark #team-carousel .photo-link-wrap {
	border-right-color: rgba(255, 255, 255, 0.1);
}

.team.dark #team-carousel .carousel-control {
	color: #ffffff;
}

.team.dark .btn-type1 {
	border: 2px solid #ffffff;
}

.team.dark .btn-type1:hover {
	text-shadow: -1px -1px 0 #000,
 1px -1px 0 #000,
 -1px 1px 0 #000,
 1px 1px 0 #000;
}

/* 404 Page */
.page_404 h2 {
	margin-bottom: 30px;
}

.not_found {
	font-size: 200px;
	font-weight: 400;
	line-height: 1;
}

.booking_success {
	font-size: 70px;
	font-weight: 400;
	line-height: 1;
}

.booking_failed {
	font-size: 70px;
	font-weight: 400;
	line-height: 1;
	color: red !important;
}

@media (max-width: 600px) {
	.not_found {
		font-size: 100px;
	}
}

.searchform {
	position: relative;
	max-width: 370px;
	margin: 0 auto 10px;
}

.searchform .form-group {
	width: 100%;
}

.searchform .form-control {
	width: 100%;
}

.searchform button {
	vertical-align: middle;
	background: none;
	outline: none;
	border: none;
	position: absolute;
	right: 20px;
	top: 0;
	bottom: 0;
	font-size: 0;
}

.searchform button:before {
	font-size: 24px;
}

/* FAQ Page */
.panel-group .panel {
	margin-bottom: 10px;
}

.panel-default {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	border: none;
}

.panel-default > .panel-heading {
	background-color: #ffffff;
	color: #292a2e;
	position: relative;
	border: none;
	border-radius: 0;
	padding: 0;
}

.panel-title {
	padding: 0;
	position: relative;
}

.panel-title a {
	display: block;
	padding: 22px 65px 22px 40px;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	line-height: 18px;
	border: none;
	word-wrap: break-word;
	background-color: #f96380;
}

.panel-title a i {
	font-size: 24px;
	vertical-align: middle;
	margin-right: 5px;
}

.panel-title a.collapsed {
	color: #365510;
	background-color: transparent;
}

.panel-title a.collapsed:after {
	content: '+';
}

.panel-title a:after {
	content: '--';
	position: absolute;
	font-size: 20px;
	letter-spacing: -1px;
	font-family: "Roboto", sans-serif;
	line-height: 58px;
	text-align: center;
	right: 0;
	top: 0;
	bottom: 0;
	width: 60px;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
	border-top: none;
}

.panel-body {
	padding: 40px;
	line-height: 30px;
}

.faq-section p {
	max-width: 750px;
	font-size: 20px;
	line-height: 24px;
	margin: 0 auto 60px;
}

/* Services Page */
.small-item.service-item {
	margin-bottom: 30px;
	padding-top: 40px;
}

.small-item.service-item .single-item-body {
	padding: 5px 30px 0px;
}

.small-item.service-item .single-item-body p {
	margin-bottom: 0;
}

.small-item.service-item .single-item-body .item-heading {
	display: table;
	height: 60px;
	margin-bottom: 0px;
}

.small-item.service-item img {
	display: block;
	max-width: 225px;
	border-radius: 50%;
	margin: auto;
}

.small-item.service-item h3 {
	display: table-cell;
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	margin-top: 0;
	margin-bottom: 0;
	vertical-align: middle;
}

/* Service details Page */
.service-menu {
	margin-bottom: 50px;
}

.service-menu a {
	display: block;
	height: 40px;
	margin-bottom: 10px;
	padding: 0 10px;
	line-height: 40px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	background-color: #ffffff;
}

.service-menu a i {
	margin-right: 15px;
	font-size: 24px;
	color: #f96380;
	vertical-align: middle;
}

.service-menu a:hover, .service-menu a.active {
	background-color: #f96380;
}

.service-menu a:hover i, .service-menu a.active i {
	color: #ffffff;
}

.service-details h1 {
	margin-top: -9px;
	margin-bottom: 30px;
	font-size: 50px;
}

@media (max-width: 480px) {
	.service-details h1 {
		font-size: 30px;
	}
}

#accordion3 {
	margin-top: 20px;
}

#accordion3 .panel-title a {
	text-transform: uppercase;
	font-weight: 900;
	letter-spacing: 0.2em;
}

#accordion3 .panel-title a:after {
	font-weight: 500;
	letter-spacing: -1px;
}

/* Booking Course */
.left {
	background-color: #8B945C;
	color: #FFFAE5;
	min-height: 360px;
	padding-top: 30px;
}
.checkout {
	font-weight: 300;
	font-size: 15px;
	line-height: 26px;
	padding-top: 10px;
	padding-bottom: 15px;
	border-bottom: 1px solid #dfe2d0;
	height: 15%;
}
	.checkout > span {
		font-weight: 500;
		font-size: 21px;
	}
.entry {
	border-bottom: 1px solid #dfe2d0;
	height: 15%;
	overflow: hidden;
	padding-top: 15px;
}
.entry:last-child {
	border-bottom: none;
}
	.entry > p {
		font-weight: 300;
		font-size: 13px;
		line-height: 26px;
		margin-top: 0px !important;
		float: left;
	}
	.entry span {
		font-weight: 500;
		font-size: 16px;
	}
.right {
	background-color: #fefefe;
	padding: 15px;
	min-height: 360px;
	padding-top: 30px;
}
	.right .header {
		overflow: hidden;
		border-bottom: 1px solid #8B945C;
		padding-bottom: 15px;
		padding-top: 15px;
	}
	.right > form {
		text-align: center;
		margin-top:  30px;
	}
	.main {
		margin-top: 70px;
		text-align: center;
	}
		.main > h3 {
			color: #58636a;
			font-weight: 500;
		}
		.main > h5 {
			color: #99a1aa;
			font-weight: 300;
		}

/* Booking Course End */

/* My Profile Page */
.my-profile label {
	color: #365510 !important;
}
/* My Profile Page Ends */

/* Appointment page */
.calendar-header {
	margin-bottom: 40px;
}

.calendar-navigation {
	position: relative;
	text-align: center;
}

.calendar-navigation h3 {
	font-family: "Roboto", sans-serif;
	font-size: 30px;
	color: #ffffff;
	margin: 0;
	vertical-align: middle;
}

.calendar-navigation button {
	position: absolute;
	top: 5px;
	padding: 0;
	font-size: 14px;
	color: #ffffff;
	background: none;
	border: none;
}

.calendar-navigation button:hover {
	color: #292a2e;
}

.calendar-navigation button.prev {
	left: 15px;
}

.calendar-navigation button.next {
	right: 15px;
}

.week-days,
.week-row,
.month-box {
	font-size: 0;
}

.week-days > div {
	position: relative;
	display: inline-block;
	width: 14.285714%;
	color: #ffffff;
	font-size: 14px;
	font-weight: 800;
	text-align: center;
	padding: 10px 0;
	background-color: #8B935E;
}

.day-cell {
	position: relative;
	padding: 30px 0;
	display: inline-block;
	width: 14.285714286%;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, 0.1);
	cursor: pointer;
	background-color: #ffffff;
}

.day-cell.not-cur-month {
	cursor: default;
	background-color: #ecedef;
}

.day-cell.today {
	background-color: #f96380;
}

.day-cell.today > * {
	color: #ffffff;
}

.week-row:not(:first-child) .day-cell {
	border-top: none;
}

.day-cell:not(:first-child) {
	border-left: none;
}

.day-date {
	color: #292a2e;
}

.not-cur-month .day-date {
	color: #989898;
}

.day-events {
	position: absolute;
	bottom: 5px;
	right: 10px;
	color: #b2b2b2;
}

.day-cell.not-cur-month .day-events {
	display: none;
}

.events-list {
	padding: 15px 30px;
	background-color: #e6eff8;
}

@media (min-width: 768px) {
	.events-list {
		border-right: 1px solid #fff;
	}
}

.events-list h4 {
	margin: 5px 0;
	font-size: 16px;
	font-weight: 500;
}

.events-list + .week-row .day-cell,
.appointment-list + .week-row .day-cell {
	border-top: 1px solid #d9dadc;
}

.appointment-list h4 {
	color: #FFFAE5 !important;
}

.appointment-list p, label {
	color: #FFFAE5;
}

.day-cell.selected-day {
	background-color: transparent;
}

@media (max-width: 639px) {
	.day-cell.today:before,
	.day-cell * {
		font-size: 12px;
	}
	.day-cell.today:before {
		top: 0;
		left: 5px;
	}
	.week-days > div {
		padding: 10px 0;
		font-size: 12px;
	}
	.day-cell {
		padding: 20px 0;
	}
	.day-events {
		bottom: 0;
		right: 5px;
	}
}

@media (max-width: 439px) {
	.day-cell.today:before,
	.day-cell * {
		font-size: 8px;
	}
	.day-cell .day-date {
		font-size: 12px;
	}
	.day-cell {
		padding: 15px 0;
	}
	.events-list h4 {
		font-size: 12px;
	}
}

.appointment-calendar .today {
	background-color: #50cc60;
	color: white;
}

.appointment-calendar .calendar-header {
	margin-bottom: 0;
}

.appointment-calendar .calendar-navigation {
	position: relative;
	background-color: #735243;
	padding: 20px 0 15px;
}

.appointment-calendar .calendar-navigation h3 {
	display: inline-block;
	color: #FFFAE4;
	vertical-align: middle;
}
.appointment-calendar .calendar-navigation h2 {
	color: #FFFAE4;
}

@media (max-width: 480px) {
	.appointment-calendar .calendar-navigation h3 {
		display: block;
	}
}

@media (max-width: 640px) {
	.boxed .appointment-calendar .calendar-navigation h3 {
		display: block;
	}
}

.appointment-calendar .calendar-navigation a {
	color: #ffffff;
	vertical-align: middle;
	margin-left: 20px;
}

.appointment-calendar .calendar-navigation a:hover {
	color: #292a2e;
}

.appointment-calendar .calendar-navigation button {
	top: 50%;
	margin-top: -7px;
	color: #ffffff;
}

.appointment-calendar .calendar-navigation button:hover {
	color: #292a2e;
}

.appointment-calendar .calendar-navigation button.prev {
	left: 30px;
}

.appointment-calendar .calendar-navigation button.next {
	right: 30px;
}

.appointment-list {
	padding: 40px;
	background-color: #8B935E;
}

@media (max-width: 480px) {
	.appointment-list {
		padding: 40px 10px;
	}
}

.appointment-list h4 {
	margin-bottom: 30px;
	font-family: "Roboto", sans-serif;
	font-size: 20px;
	font-weight: 700;
	color: #ffffff;
}

.appointment-list ul {
	margin: 0;
	padding: 0;
}

.appointment-list li:first-child {
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.appointment-list li {
	padding: 8px 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.appointment-list li div * {
	font-size: 14px;
}

.appointment-list li > div {
	height: 60px;
	line-height: 60px;
	display: inline-block;
}

.appointment-time span {
	margin-left: 15px;
	color: #FFFAE5;
}

.appointment-slots {
	margin-left: 110px;
}

@media (max-width: 991px) {
	.appointment-slots {
		margin-left: 40px;
	}
	.boxed .appointment-list li > div {
		display: block;
		float: none;
	}
	.boxed .appointment-slots {
		margin-left: 0;
	}
}

@media (max-width: 767px) {
	.appointment-list li > div {
		display: block;
		float: none;
	}
	.appointment-slots {
		margin-left: 0;
	}
}

.appointment-slots span {
	color: #50cc60;
}

.unavailable .appointment-slots span {
	color: #989898;
}

.book-appointment {
	float: right;
}

.appointment-list li i {
	font-size: 18px;
	color: #50cc60;
	vertical-align: middle;
}

.appointment-list li span {
	vertical-align: middle;
}

.appointment-list .theme_button {
	position: relative;
	margin-top: 0;
	width: 300px;
	padding: 0 40px;
	font-size: 12px;
	line-height: 60px;
	text-align: left;
	border: none;
	color: #292a2e !important;
	background-color: #ffffff;
}

@media (max-width: 480px) {
	.appointment-list .theme_button {
		width: 100%;
		max-width: 300px;
	}
}

.appointment-list .theme_button:after {
	content: "\e7c1";
	position: absolute;
	right: 20px;
	top: 12px;
	font-size: 36px;
	color: #50cc60;
	font-family: 'rt-icons-2' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
}

.appointment-list .theme_button:hover {
	text-shadow: none;
	color: #ffffff !important;
	background-color: #50cc60;
}

.appointment-list .theme_button:hover:after {
	color: #ffffff;
}

.unavailable .theme_button {
	text-align: center;
	background-color: #3f4043;
	color: #ffffff;
	pointer-events: none;
}

.unavailable .theme_button:after {
	display: none;
}

.unavailable .theme_button:hover {
	background-color: #3f4043;
	cursor: default;
}

/* Gallery */
.gallery-page .isotope-item .media-links:before {
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.gallery-page .isotope-item:hover .media-links:before {
	filter: alpha(opacity=80);
	opacity: 0.8;
}

.gallery-page .filters {
	margin-bottom: 60px;
}

.gallery-page .pagination > li a:hover,
.gallery-page .pagination > li.active a,
.gallery-page .pagination > li.active a:hover,
.gallery-page .pagination > li.active a:focus,
.gallery-page .pagination > li.active > a:focus {
	color: #f96380;
	background-color: transparent !important;
}

.gallery-page .categories-links a {
	display: inline-block;
	width: 120px;
	height: 20px;
	text-transform: uppercase;
	line-height: 20px;
	color: #ffffff;
	background-color: #f96380;
}

.gallery-page .categories-links a:hover {
	color: #ffffff;
	background-color: #292a2e;
}

.gallery-page .item-title {
	padding: 30px 15px 35px;
}

.gallery-page .item-title h3 {
	margin-top: 0;
	margin-bottom: 3px;
	padding: 0;
	font-family: "Roboto", sans-serif;
	font-weight: 200;
	font-size: 30px;
}

.gallery-page .item-title p {
	line-height: 24px;
	max-width: 460px;
	margin: 20px auto 0;
}

.gallery-page .theme_button.small_button {
	margin-right: 4px;
	margin-left: 4px;
	padding-right: 28px;
	padding-left: 28px;
}

.gallery-page .load_more {
	letter-spacing: 0;
}

.gallery-regular .isotope-item {
	margin-bottom: 30px;
}

@media (min-width: 1200px) {
	.gallery-regular .isotope-item .links-wrap .btn-type2.add-btn {
		margin-top: 10px;
	}
}

.gallery-regular .pagination {
	margin-top: 15px;
	margin-bottom: 25px;
}

.gallery-regular .pagination > li a {
	width: 30px;
	margin: 0;
	font-size: 12px;
}

.gallery-regular .pagination > li:first-child a,
.gallery-regular .pagination > li:last-child a {
	font-size: 10px;
}

.gallery-regular .pagination > li:first-child a {
	margin-right: 25px;
}

.gallery-regular .pagination > li:last-child a {
	margin-left: 25px;
}

.gallery-fullwidth .isotope-item {
	padding: 0;
}

.gallery-extended .isotope-item {
	margin-bottom: 30px;
}

.gallery-extended .isotope-item .media-links:before {
	display: none;
}

.gallery-extended .abs-link:hover {
	background-color: rgba(41, 42, 46, 0.8);
}

.gallery-single-title {
	font-size: 50px;
	font-family: 'Roboto', sans-serif;
	font-weight: 100;
	margin-top: -10px;
}

.gallery-extended-item .item-content {
	padding: 40px;
}

@media (max-width: 480px) {
	.gallery-extended-item .item-content {
		padding: 40px 20px;
	}
}

.gallery-extended-item .item-content p {
	margin-bottom: 25px;
	line-height: 24px;
}

.gallery-extended-item .item-content p.important {
	font-weight: 300;
}

.gallery-extended-item .item-content a.social-icon {
	margin-left: 2px;
}

@media (max-width: 1800px) {
	.related-photos-carousel .isotope-item .media-links {
		padding: 0 20px;
	}
}

.gallery-single-slider-nav {
	width: 150px;
	margin: auto;
	font-weight: 700;
}

.gallery-single-slider-nav div {
	display: inline-block;
	height: 50px;
	line-height: 50px;
}

.gallery-single-slider-nav div.owl-prev, .gallery-single-slider-nav div.owl-next {
	width: 30px;
	font-family: 'rt-icons-2' !important;
	speak: none;
	font-size: 0;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.gallery-single-slider-nav div.owl-prev:before, .gallery-single-slider-nav div.owl-next:before {
	font-size: 18px;
}

.gallery-single-slider-nav div.owl-prev:hover, .gallery-single-slider-nav div.owl-next:hover {
	color: #f96380;
	cursor: pointer;
}

.gallery-single-slider-nav div.owl-prev {
	float: left;
}

.gallery-single-slider-nav div.owl-next {
	float: right;
}

.gallery-single-slider-nav div.owl-prev:before {
	content: "\f104";
}

.gallery-single-slider-nav div.owl-next:before {
	content: "\f105";
}
:4662;
/**light section - .ls*/
.ls {
	background-color: #FFFAE5;
	color: #365510;
	/* Firefox 19+ */
	/* Firefox 18- */
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6 {
	color: #365510;
}

.ls h1 a,
.ls h2 a,
.ls h3 a,
.ls h4 a,
.ls h5 a,
.ls h6 a {
	color: inherit;
}

.ls h1 a:hover,
.ls h2 a:hover,
.ls h3 a:hover,
.ls h4 a:hover,
.ls h5 a:hover,
.ls h6 a:hover {
	color: #50cc60;
}

.ls a {
	color: #50cc60;
}

.ls a:hover {
	color: #50cc60;
}

.ls ::-webkit-input-placeholder {
	color: #292a2e;
}

.ls ::-moz-placeholder {
	color: #292a2e;
}

.ls :-moz-placeholder {
	color: #292a2e;
}

.ls :-ms-input-placeholder {
	color: #292a2e;
}

.ls input[type="search"] {
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.ls .theme_buttons a,
.ls .theme_button {
	color: #50cc60;
	border-color: #50cc60;
}

.ls .theme_buttons a:hover, .ls .theme_buttons a:focus,
.ls .theme_button:hover,
.ls .theme_button:focus {
	color: #f96380;
}

.ls .theme_buttons a.with-bg,
.ls .theme_button.with-bg {
	color: #292a2e;
	background-color: transparent;
	border: 1px solid #292a2e;
}

.ls .theme_buttons a.with-bg:hover,
.ls .theme_button.with-bg:hover {
	background-color: #292a2e;
	color: #ffffff !important;
	border-color: #292a2e;
}

.ls .theme_buttons.color1 a,
.ls .theme_button.color1 {
	color: #50cc60;
	border-color: #50cc60;
}

.ls .theme_buttons.color1 a:hover, .ls .theme_buttons.color1 a:focus,
.ls .theme_button.color1:hover,
.ls .theme_button.color1:focus {
	color: rgba(249, 99, 128, 0.7);
	text-shadow: none;
}

.ls .theme_buttons.color2 a,
.ls .theme_button.color2 {
	color: #e96ac2;
	border-color: #e96ac2;
}

.ls .theme_buttons.color2 a:hover, .ls .theme_buttons.color2 a:focus,
.ls .theme_button.color2:hover,
.ls .theme_button.color2:focus {
	color: rgba(233, 106, 194, 0.7);
	text-shadow: none;
}

.ls .theme_button.inverse a,
.ls .theme_button.inverse {
	color: #50cc60;
}

.ls .theme_button.inverse a:hover, .ls .theme_button.inverse a:focus,
.ls .theme_button.inverse:hover,
.ls .theme_button.inverse:focus {
	color: #50cc60;
}

.ls .color-icon.bg-icon {
	background-color: #f5f5f5;
}

.ls .color-icon.bg-icon:hover {
	color: #ffffff !important;
}

.ls .highlight {
	color: #50cc60;
}

.ls .highlight2 {
	color: #fff;
}

.ls .greylinks a {
	color: #989898;
}

.ls .greylinks a:hover {
	color: #f96380;
}

.ls .darklinks a {
	color: #365510;
}

.ls .darklinks a:hover {
	color: #50cc60;
}

.ls .invertlink:hover {
	color: #292a2e !important;
}

.ls.other-offices {
	background: #ffffff;
	/* For browsers that do not support gradients */
	background: -webkit-linear-gradient(bottom, #292a2e 120px, #ffffff 120px);
	/* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(bottom, #292a2e 120px, #ffffff 120px);
	/* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(bottom, #292a2e 120px, #ffffff 120px);
	/* For Firefox 3.6 to 15 */
	background: linear-gradient(to top, #292a2e 120px, #ffffff 120px);
	/* Standard syntax */
}

@media (max-width: 480px) {
	.ls .blog-gallery-nav .lightlink {
		color: #292a2e !important;
	}
}

.ls .select-group.with_background {
	background-color: #ffffff;
}

/**grey section - light section, muted section - .ls.ms*/
.ls.ms {
	background-color: #FFFAE4;
}

.ls.ms .theme_buttons a,
.ls.ms .theme_button {
	color: #ffffff;
	border-color: #ffffff;
}

.ls.ms .theme_buttons a:hover, .ls.ms .theme_buttons a:focus,
.ls.ms .theme_button:hover,
.ls.ms .theme_button:focus {
	color: #fff;
}

.ls.ms .theme_buttons a.with-bg,
.ls.ms .theme_button.with-bg {
	color: #292a2e;
	background-color: #ffffff;
	border-color: #ffffff;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.ls.ms .theme_buttons a.with-bg.hover-bg,
.ls.ms .theme_button.with-bg.hover-bg {
	background-color: transparent;
	border-color: #292a2e;
}

.ls.ms .theme_buttons a.with-bg:hover,
.ls.ms .theme_button.with-bg:hover {
	background-color: #292a2e;
	color: #ffffff !important;
	border-color: #292a2e;
}

.ls.ms .theme_buttons.color1 a,
.ls.ms .theme_button.color1 {
	color: #50cc60;
	border-color: #50cc60;
}

.ls.ms .theme_buttons.color1 a:hover, .ls.ms .theme_buttons.color1 a:focus,
.ls.ms .theme_button.color1:hover,
.ls.ms .theme_button.color1:focus {
	color: rgba(80, 204, 96, 0.7);
	text-shadow: none;
}

.ls.ms .theme_buttons.color2 a,
.ls.ms .theme_button.color2 {
	color: #e96ac2;
	border-color: #365510;
}

.ls.ms .theme_buttons.color2 a:hover, .ls.ms .theme_buttons.color2 a:focus,
.ls.ms .theme_button.color2:hover,
.ls.ms .theme_button.color2:focus {
	color: rgba(233, 106, 194, 0.7);
	text-shadow: none;
}

.ls.ms .theme_button.inverse a,
.ls.ms .theme_button.inverse {
	color: #365510;
	border-color: #365510;
}

.ls.ms .theme_button.inverse a:hover, .ls.ms .theme_button.inverse a:focus,
.ls.ms .theme_button.inverse:hover,
.ls.ms .theme_button.inverse:focus {
	color: #50cc60;
}

.ls.ms .greylinks a:hover {
	color: #292a2e;
}

.ls.ms .with_background {
	background-color: #ffffff;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.post-with-shadow {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.entry-date {
	color: #365510;
}

.ls.ms hr {
	background-color: #ffffff;
}

.ls.ms input[type="text"],
.ls.ms input[type="email"],
.ls.ms input[type="url"],
.ls.ms input[type="password"],
.ls.ms input[type="search"],
.ls.ms input[type="tel"],
.ls.ms textarea,
.ls.ms .form-control {
	border-color: transparent;
	background-color: #ffffff;
}

.ls.ms .panel-title > a {
	background-color: #50cc60;
}

.ls.ms .panel-title > a.collapsed {
	background-color: #ffffff;
}

.ls.ms .panel-title > a.collapsed:hover,
.ls.ms .panel-title > a:hover {
	background-color: #50cc60;
}

.ls.ms .breadcrumb {
	color: #989898;
}

.ls.ms .breadcrumb a {
	color: #7f7f7f;
}

.ls.ms .breadcrumb a:hover {
	color: #f96380;
}

/**common dark and dark muted sections - .ds and .ds.ms*/
/**dark section - .ds*/
.ds {
	background-color: #292a2e;
	color: #989898;
}

/**darkgrey section - .ds.ms*/
.ds.ms {
	background-color: #212225;
}

.ds {
	/* Firefox 19+ */
	/* Firefox 18- */
	/* shop */
}

.ds h1,
.ds h2,
.ds h3,
.ds h4,
.ds h5,
.ds h6 {
	color: #ffffff;
}

.ds h1 a,
.ds h2 a,
.ds h3 a,
.ds h4 a,
.ds h5 a,
.ds h6 a {
	color: #ffffff;
}

.ds h1 a:hover,
.ds h2 a:hover,
.ds h3 a:hover,
.ds h4 a:hover,
.ds h5 a:hover,
.ds h6 a:hover {
	color: #50cc60;
}

.ds .ls {
	background-color: #ffffff;
	color: #989898;
}

.ds .ls h1,
.ds .ls h2,
.ds .ls h3,
.ds .ls h4,
.ds .ls h5,
.ds .ls h6 {
	color: #292a2e;
}

.ds .ls h1 a,
.ds .ls h2 a,
.ds .ls h3 a,
.ds .ls h4 a,
.ds .ls h5 a,
.ds .ls h6 a {
	color: inherit;
}

.ds .ls h1 a:hover,
.ds .ls h2 a:hover,
.ds .ls h3 a:hover,
.ds .ls h4 a:hover,
.ds .ls h5 a:hover,
.ds .ls h6 a:hover {
	color: #f96380;
}

.ds .ls a {
	color: #f96380;
}

.ds .ls a:hover {
	color: #292a2e;
}

.ds a {
	color: #ffffff;
}

.ds a:hover {
	color: #f96380;
}

.ds hr {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds blockquote, .ds .blockquote {
	color: #ffffff;
}

.ds ::-webkit-input-placeholder {
	color: #ffffff;
}

.ds ::-moz-placeholder {
	color: #ffffff;
}

.ds :-moz-placeholder {
	color: #ffffff;
}

.ds :-ms-input-placeholder {
	color: #ffffff;
}

.ds .blockquote-big:before, .ds .blockquote-big:after {
	color: #2c2c2c;
}

.ds .theme_buttons a,
.ds .theme_button {
	color: #ffffff;
	border-color: #ffffff;
}

.ds .theme_buttons a:hover, .ds .theme_buttons a:focus,
.ds .theme_button:hover,
.ds .theme_button:focus {
	color: #f96380;
}

.ds .theme_buttons a.with-bg,
.ds .theme_button.with-bg {
	color: #ffffff;
	background-color: #292a2e;
	border: 1px solid #292a2e;
}

.ds .theme_buttons a.with-bg:hover,
.ds .theme_button.with-bg:hover {
	background-color: #ffffff;
	color: #292a2e;
	border-color: #ffffff;
}

.ds .theme_buttons.color1 a,
.ds .theme_button.color1 {
	color: #f96380;
	border-color: #f96380;
}

.ds .theme_buttons.color1 a:hover, .ds .theme_buttons.color1 a:focus,
.ds .theme_button.color1:hover,
.ds .theme_button.color1:focus {
	color: rgba(249, 99, 128, 0.7);
}

.ds .theme_buttons.color2 a,
.ds .theme_button.color2 {
	color: #e96ac2;
	border-color: #e96ac2;
}

.ds .theme_buttons.color2 a:hover, .ds .theme_buttons.color2 a:focus,
.ds .theme_button.color2:hover,
.ds .theme_button.color2:focus {
	color: rgba(233, 106, 194, 0.7);
}

.ds .theme_buttons.inverse a,
.ds .theme_button.inverse {
	border-color: #ffffff;
	background-color: transparent;
	color: #ffffff;
}

.ds .theme_buttons.inverse a:after,
.ds .theme_button.inverse:after {
	display: none;
}

.ds .theme_buttons.inverse a:hover, .ds .theme_buttons.inverse a:focus,
.ds .theme_button.inverse:hover,
.ds .theme_button.inverse:focus {
	color: #f96380;
}

.ds .img_background, .ds.img_background {
	background-image: url("/themes/bach/images/system/dark_muted_bg.jpg");
}

.ds .img_background_before:before {
	background-image: url("/themes/bach/images/system/dark_muted_bg.jpg");
}

.ds .with_background,
.ds .well {
	background-color: #292a2e;
}

.ds .muted_background {
	background-color: #212225;
}

.ds .transp_background,
.ds .single-item-body.transp_background {
	background-color: rgba(0, 0, 0, 0.9);
}

.ds.muted_section:before {
	background-color: #292a2e;
}

.ds .with_border {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .with_border_pseudo:after,
.ds .with_border_pseudo:before {
	border: 1px solid rgba(255, 255, 255, 0.1);
}

.ds .highlight {
	color: #f96380;
}

.ds .highlight2 {
	color: #e96ac2;
}

.ds .grey, .ds .black {
	color: #ffffff;
}

.ds .highlightlinks a {
	color: #f96380;
}

.ds .highlightlinks a:hover {
	opacity: 0.5;
}

.ds .greylinks a {
	color: #989898;
}

.ds .greylinks a:hover {
	color: #f96380 !important;
}

.ds .darklinks a {
	color: #ffffff;
}

.ds .darklinks a:hover {
	color: #f96380 !important;
}

.ds .invertlink:hover {
	color: #ffffff !important;
}

.ds.with_top_border:before, .ds.with_bottom_border:after {
	background-color: rgba(255, 255, 255, 0.1);
}

.ds .section_header + p {
	color: inherit;
}

.ds input[type="text"],
.ds input[type="email"],
.ds input[type="url"],
.ds input[type="password"],
.ds input[type="search"],
.ds input[type="tel"],
.ds textarea,
.ds .form-control {
	color: #ffffff;
	background-color: #292a2e !important;
	border-color: rgba(255, 255, 255, 0.1) !important;
}

.ds select {
	color: #ffffff;
	border-color: rgba(255, 255, 255, 0.1) !important;
}

.ds select option {
	background-color: #292a2e;
}

.ds form label + [class*="icon-"] {
	color: #ffffff;
}

.ds .form-group-select:before {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .ui-datepicker-trigger:hover {
	color: #ffffff;
}

.ds .contact-form.transparent-background .form-control {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .border-paragraphs p {
	color: #ffffff;
	border-color: rgba(255, 255, 255, 0.1) !important;
}

.ds .table,
.ds .table-bordered,
.ds .table th,
.ds .table td {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .table-striped > tbody > tr:nth-child(odd),
.ds .table-striped > tbody > tr:nth-child(odd) > td,
.ds .table-striped > tbody > tr:nth-child(odd) > th {
	border-color: rgba(255, 255, 255, 0.1);
	background-color: #292a2e;
}

.ds .color-icon.bg-icon {
	background-color: #ffffff;
}

.ds .color-icon.bg-icon:hover {
	color: #ffffff !important;
}

.ds .color-bg-icon:hover {
	background-color: #f96380;
}

.ds .tab-content,
.ds .nav-tabs > li > a {
	background-color: #292a2e;
}

.ds .nav-tabs > li.active > a, .ds .nav-tabs > li.active > a:hover, .ds .nav-tabs > li.active > a:focus {
	border-color: #f96380;
	border-bottom-color: transparent;
	background-color: #f96380;
	color: #ffffff;
}

.ds .price-table.style2 {
	background-color: #292a2e;
}

.ds .plan-name {
	background-color: #292a2e;
}

.ds .plan-price p {
	color: #ffffff;
}

.ds .features-list .disabled:before {
	color: #ffffff;
}

.ds ul.list1 {
	color: #ffffff;
}

.ds ul.list1 li {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .owl-theme .owl-dots .owl-dot span {
	background-color: #ffffff;
}

.ds .testimonials-carousel .media + p {
	background-color: #292a2e;
}

.ds .side-item {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .entry-excerpt {
	color: #ffffff;
}

.ds .comment-meta {
	color: #ffffff;
}

.ds .comment-meta .author_url {
	color: #ffffff;
}

.ds .comment-list article:after {
	background-color: rgba(255, 255, 255, 0.1);
}

.ds .comment-respond {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .entry-tags {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .widget_shopping_cart .cart_list,
.ds .widget_recent_entries li,
.ds .widget_recent_comments li,
.ds .widget_archive li,
.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_nav_menu li,
.ds .widget_pages li,
.ds .widget_popular_entries li {
	border-color: rgba(255, 255, 255, 0.1) !important;
}

.ds .widget_nav_menu ul ul {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .widget_popular_entries .media-heading {
	color: #ffffff;
}

.ds .tweet_list li + li .tweet_right {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .widget_tag_cloud a {
	color: #ffffff;
}

.ds #calendar_wrap {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .widget_calendar table {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .widget_calendar tfoot td a {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .widget_calendar caption,
.ds .widget_calendar thead,
.ds .widget_calendar th {
	color: #ffffff;
}

.ds .widget_calendar tbody td a:hover {
	color: #ffffff;
}

.ds .grid-view h3:before, .ds .grid-view h3:after {
	background-color: rgba(255, 255, 255, 0.1);
}

.ds .owl-carousel.product-thumbnails .owl-nav > div:after {
	color: #ffffff;
}

.ds .ui-slider {
	background-color: #292a2e;
}

.ds .ui-slider .ui-slider-handle {
	background-color: #ffffff;
}

.ds #sort_view {
	border-color: rgba(255, 255, 255, 0.1);
	color: #ffffff;
}

.ds #toggle_shop_view.grid-view:after, .ds #toggle_shop_view:before {
	background-color: #222326;
}

.ds .widget_layered_nav .color-filters a:before {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .pagination > li > a, .ds .pagination > li > span {
	color: #ffffff;
	background-color: #292a2e;
}

.ds .pagination > li > a:hover, .ds .pagination > li > a:focus, .ds .pagination > li > span:hover, .ds .pagination > li > span:focus {
	background-color: #f96380;
}

.ds .pagination > li.active > a,
.ds .pagination > li.active > span {
	background-color: #f96380;
}

.ds .thumbnail {
	background-color: transparent;
}

.ds .thumbnail h3 a {
	color: #ffffff;
}

.ds .thumbnail .caption {
	border-color: transparent;
	background-color: #27282b;
}

.ds .progress {
	background-color: rgba(255, 255, 255, 0.1);
}

.ds .panel-group .panel-heading + .panel-collapse .panel-body {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds .panel-title > a.collapsed {
	color: #ffffff;
	background-color: #292a2e;
}

.ds .panel-title > a.collapsed:after {
	color: #ffffff;
}

.ds .panel-body {
	background-color: #292a2e;
}

.ds .vertical-tabs .nav > li > a {
	color: #ffffff;
	background-color: #292a2e;
}

.ds .vertical-tabs .nav > li.active > a,
.ds .vertical-tabs .nav > li > a:hover {
	background-color: #f96380;
}

.ds .entry-meta .comments-link a {
	color: #ffffff;
}

.ds #timetable tbody th,
.ds #timetable tbody td {
	border-color: rgba(255, 255, 255, 0.1);
}

.ds.contacts-form-sec .container-fluid:before {
	filter: alpha(opacity=20);
	opacity: 0.2;
}

.ds.other-offices {
	background: #292a2e;
	/* For browsers that do not support gradients */
	background: -webkit-linear-gradient(bottom, #1b1c1f 120px, #292a2e 120px);
	/* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(bottom, #1b1c1f 120px, #292a2e 120px);
	/* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(bottom, #1b1c1f 120px, #292a2e 120px);
	/* For Firefox 3.6 to 15 */
	background: linear-gradient(to top, #1b1c1f 120px, #292a2e 120px);
	/* Standard syntax */
}

.ds .day-cell {
	border-color: rgba(255, 255, 255, 0.1);
	background-color: #292a2e;
}

.ds .day-cell .day-date {
	color: #ffffff;
}

.ds .day-cell.today {
	background-color: #f96380;
}

.ds .day-cell.not-cur-month {
	background-color: #212225;
}

.ds .day-cell.selected-day {
	background-color: #212225;
}

.ds .day-cell.selected-day .day-date {
	color: #ffffff;
}

.ds .week-row {
	background-color: #212225;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
	border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.ds .appointment-list {
	border-color: rgba(255, 255, 255, 0.1);
	background-color: #212225;
}

.ds .appointment-list + .week-row .day-cell {
	border-top-color: rgba(255, 255, 255, 0.1);
}

.page_breadcrumbs + .contacts + .ds.contacts-form-sec input,
.page_breadcrumbs + .contacts + .ds.contacts-form-sec select,
.page_breadcrumbs + .contacts + .ds.contacts-form-sec textarea {
	border: 2px solid rgba(255, 255, 255, 0.1) !important;
}

/**color section*/
.cs.main_color2 {
	background-color: #f96380;
}

.cs.main_color2 .highlight {
	color: #f96380;
}

.cs {
	background-color: #f96380;
	color: #ffffff;
}

.cs h1,
.cs h2,
.cs h3,
.cs h4,
.cs h5,
.cs h6 {
	color: #ffffff;
}

.cs h1 a,
.cs h2 a,
.cs h3 a,
.cs h4 a,
.cs h5 a,
.cs h6 a {
	color: #ffffff;
}

.cs h1 a:hover,
.cs h2 a:hover,
.cs h3 a:hover,
.cs h4 a:hover,
.cs h5 a:hover,
.cs h6 a:hover {
	color: rgba(255, 255, 255, 0.6);
}

.cs a {
	color: #ffffff;
}

.cs a:hover {
	color: #fff6f8;
}

.cs blockquote, .cs .blockquote {
	border-color: #fb94a8;
}

.cs .theme_buttons a.with-bg,
.cs .theme_button.with-bg {
	color: #ffffff;
	border-width: 1px;
	background-color: transparent;
}

.cs .theme_buttons a.with-bg:hover, .cs .theme_buttons a.with-bg:focus,
.cs .theme_button.with-bg:hover,
.cs .theme_button.with-bg:focus {
	color: #f96380;
	background-color: #ffffff;
}

.cs .theme_buttons a.color1,
.cs .theme_button.color1 {
	background-color: #ffffff;
	border-color: #ffffff;
	color: #f96380;
}

.cs .theme_buttons a.color1:hover,
.cs .theme_button.color1:hover {
	color: rgba(249, 99, 128, 0.7);
}

.cs .theme_buttons a:hover, .cs .theme_buttons a:focus,
.cs .theme_button:hover,
.cs .theme_button:focus {
	color: #292a2e;
}

.cs .theme_buttons.color2 a,
.cs .theme_button.color2 {
	color: #e96ac2;
	border-color: #ffffff;
	background-color: #ffffff;
}

.cs .theme_buttons.color2 a:hover, .cs .theme_buttons.color2 a:focus,
.cs .theme_button.color2:hover,
.cs .theme_button.color2:focus {
	color: rgba(233, 106, 194, 0.7);
	text-shadow: none;
}

.cs .theme_buttons.inverse a,
.cs .theme_button.inverse {
	background-color: transparent;
	border-color: #292a2e;
	color: #292a2e;
}

.cs .theme_buttons.inverse a:hover, .cs .theme_buttons.inverse a:focus,
.cs .theme_button.inverse:hover,
.cs .theme_button.inverse:focus {
	color: #ffffff;
}

section .cs .theme_buttons a,
section .cs .theme_button {
	color: #292a2e;
	background-color: #ffffff;
	border-color: #ffffff;
}

section .cs .theme_buttons a:hover,
section .cs .theme_button:hover {
	background-color: #292a2e;
	border-color: #292a2e;
	color: #ffffff;
}

.cs .with_background {
	background-color: rgba(255, 255, 255, 0.1);
}

.cs .with_border {
	border-color: rgba(255, 255, 255, 0.1);
}

.cs.with_top_border .container:before,
.cs.with_bottom_border .container:after {
	background-color: #fb94a8;
}

.cs .section_header:before {
	background-color: #fb94a8;
}

.cs .greylinks a {
	color: #ffffff;
	opacity: 0.7;
}

.cs .greylinks a:hover {
	opacity: 1;
	color: #ffffff !important;
}

.cs .black {
	border-color: #ffffff;
	color: #ffffff;
}

.cs .grey {
	border-color: #292a2e;
	color: #292a2e;
}

.cs .response,
.cs .highlight {
	color: #ffffff;
}

.cs ul.list1 li {
	border-color: rgba(255, 255, 255, 0.1) !important;
}

.cs [class*='soc-'] {
	color: #ffffff;
	border-color: #ffffff;
}

.cs .color-icon.bg-icon {
	background-color: #ffffff;
}

.cs .color-icon.bg-icon:hover {
	color: #ffffff !important;
}

.cs .widget_recent_entries li + li,
.cs .widget_recent_comments li + li,
.cs .widget_archive li + li,
.cs .widget_categories li + li,
.cs .widget_meta li + li,
.cs .widget_popular_entries li + li,
.cs .widget_nav_menu li + li,
.cs .widget_pages li + li {
	border-top-color: rgba(255, 255, 255, 0.1);
}

/* boxed_layout */
.boxed #box_wrapper.container {
	padding: 0;
}

@media (min-width: 1590px) {
	.boxed #box_wrapper {
		width: 1560px;
	}
}

@media (min-width: 1301px) and (max-width: 1589px) {
	.boxed #box_wrapper {
		width: 1270px;
	}
}

@media (min-width: 1341px) {
	.boxed .header-contacts {
		margin-right: -15px;
		margin-left: -15px;
	}
}

@media (min-width: 1301px) and (max-width: 1589px) {
	.boxed .sf-menu > li > a {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (min-width: 768px) and (max-width: 1589px) {
	.boxed .gallery .isotope-item .btn-type2.date-btn {
		display: none;
	}
}

/*
**template styles
*/
.page_header_wrapper {
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.page_header {
	-webkit-transition: background 0.2s ease-in-out 0s;
	-moz-transition: background 0.2s ease-in-out 0s;
	-o-transition: background 0.2s ease-in-out 0s;
	-ms-transition: background 0.2s ease-in-out 0s;
	transition: background 0.2s ease-in-out 0s;
	top: 0;
	left: 0;

	position: relative;
	z-index: 1000;
	background-color: #365510;
	/* Header Affix */
}

.page_header a {
	color: #FFFAE5;
}

.page_header .profile-name {
	color: #50cc60;
}

.page_header h2 {
	color: #FFFAE5;
}

.page_header.mobile-active {
	z-index: 1002;
}

.page_header.affix {
	position: fixed;
	width: 100%;
}

.page_header div[class^="col-"] {
	height: 120px;
	line-height: 120px;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.page_header.affix div[class^="col-"] {
	height: 80px;
	line-height: 80px;
}

.page_header.affix .header-logo img {
	display: block;
	width: 105px;
	margin-top: 5px;
}

.page_header.affix .site-name {
	margin-left: 100px;
}

.page_header.affix .header-contacts p {
	display: none;
}

.page_header.affix .header-contacts-image {
	display: none;
}

/* Logo and Site Name */
.header-logo {
	position: relative;
}

.header-logo a {
	position: absolute;
	top: 0;
	left: 0;
}

.header-logo img {
	display: block;
	width: 162px;
	margin: 5px;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	z-index: 1003;
}

.site-name {
	display: inline-block;
	margin-left: 45%;
	vertical-align: middle;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.site-name p {
	margin: 0;
	color: #50cc60;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.2em;
	/*text-transform: uppercase;*/
}

.site-name strong {
	font-weight: 900 !important;
}

.site-name h2 {
	margin: 0;
	word-break: normal;
	font-size: 27px;
}

/* Contacts */
.header-contacts {
	display: inline-block;
	font-size: 32px;
	font-weight: 100;
	letter-spacing: -0.05em;
	padding-right: 25px;
	line-height: normal;
	vertical-align: middle;
	/*text-transform: uppercase;*/
}

.header-contacts-image {
	float: right;
	max-height: 85px;
	height: 100%;
	margin-top: 17px;
}

.header-contacts span {
	color: #ffffff;
}

.header-contacts span span {
	color: #50cc60;
}

.header-contacts > a.social-icon {
	font-size: 14px;
	letter-spacing: normal;
	margin-left: 0;
}

.header-contacts > a.social-icon + a.social-icon {
	margin-left: 0;
}

.header-contacts > a:first-of-type {
	margin-left: 17px;
}

.header-contacts p {
	margin: 10px 0 0;
	color: #FFFAE5;
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.1em;
}

@media (max-width: 1625px) {
	.site-name {
		margin-left: 50%;
	}
	.site-name p {
		font-size: 10px;
	}
	.site-name h2 {
		font-size: 24px;
	}
	.header-contacts {
		font-size: 24px;
		padding-right: 10px;
	}
	.header-contacts p {
		font-size: 12px;
	}
}

@media (max-width: 1340px) {
	.site-name {
		margin-left: 125px;
	}
	.site-name h2 {
		font-size: 20px;
	}
	.header-logo img {
		width: 140px;
		margin-top: 4px;
	}
	.header-contacts {
		font-size: 20px;
		padding-right: 0;
	}
	.header-contacts-image {
		margin-left: 10px;
	}
	.header-contacts p {
		font-size: 8px;
	}
}

@media (max-width: 767px) {
	.page_header div[class^="col-"] {
		height: 80px;
		line-height: 80px;
	}
	.header-contacts-image {
		display: none;
	}
	.header-logo img {
		width: 80px;
	}
	.site-name {
		display: none;
	}
	.header-contacts span,
	.header-contacts p {
		display: none;
	}
}

/* modal search */
.modal-open .page_header_wrapper,
.modal-open .page_header.affix,
.modal-open .modal#search_modal {
	right: 16px !important;
}

#search_modal {
	color: #fff;
}

#search_modal a {
	color: #ffffff;
	opacity: 0.6;
	font-size: 12px;
	text-transform: uppercase;
}

#search_modal a:hover {
	color: #f96380;
	opacity: 1;
}

#search_modal .searchform-respond {
	width: 300px;
	margin: 20px auto;
}

#search_modal .form-control {
	border: none;
	background-color: transparent;
	height: 60px;
	color: #ffffff;
}

#search_modal .theme_button {
	padding: 18px;
}

#search_modal .form-control:-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

#search_modal .form-control::-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

#search_modal .form-control:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}

#search_modal .form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}

/* Footer */
.page_footer.footer_dark {
	background-color: #212225;
	background-color: #1b1c1f;
	/* Firefox 19+ */
	/* Firefox 18- */
}

.page_footer.footer_dark input[type="email"] {
	color: #ffffff;
	background-color: #292a2e !important;
	border-color: #292a2e !important;
}

.page_footer.footer_dark .invertlink:hover {
	color: #ffffff !important;
}

.page_footer.footer_dark ::-webkit-input-placeholder {
	color: #ffffff;
}

.page_footer.footer_dark ::-moz-placeholder {
	color: #ffffff;
}

.page_footer.footer_dark :-moz-placeholder {
	color: #ffffff;
}

.page_footer.footer_dark :-ms-input-placeholder {
	color: #ffffff;
}

.page_footer.footer_dark .form-control:focus:-moz-placeholder {
	color: transparent;
	opacity: 1;
}

.page_footer.footer_dark .form-control:focus::-moz-placeholder {
	color: transparent;
	opacity: 1;
}

.page_footer.footer_dark .form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.page_footer.footer_dark .form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

.page_footer {
	position: relative;
	padding: 25px 0 15px;
	margin-top: -1px;
	background-color: #745241;
}

.page_footer h3 {
	color: #FFFAE4;
	margin-bottom: 30px;
}

.open-hours {
	max-width: 350px;
	text-align: left;
}

.open-hours div {
	height: 50px;
	line-height: 50px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.open-hours div:first-of-type {
	height: 51px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.open-hours div span {
	float: right;
	color: #50cc60;
}

@media (max-width: 991px) {
	.open-hours,
	.widget_mailchimp,
	.widget_flickr {
		margin: 60px auto 0;
	}
}

.logo-footer {
	width: 100px;
	position: absolute;
	left: 50%;
	bottom: -70px;
	margin-left: -40px;
}

.logo-footer.light-logo {
	display: block;
}

.logo-footer.dark-logo {
	display: none;
}

/* Copyright */
.page_copyright {
	padding: 70px 0 0px;
}

.page_copyright a:hover {
	color: #f96380;
}

/**
* intro_section styles
*/
/* flexslider */
.intro_section {
	position: relative;
	overflow: hidden;
}

.intro_section > img {
	width: 100%;
}

.intro_section .flexslider:before, .intro_section .flexslider:after {
	content: '';
	display: block;
	clear: both;
}

.intro_section .embed-placeholder > img {
	max-width: 510px;
	display: inline-block !important;
}

.intro_section .container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.intro_section [class*="col-"] {
	position: static;
}

.intro_section .slide_description_wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 100%;
	z-index: 5;
	padding: 55px 0 20px;
}

@media (min-width: 600px) {
	.boxed .intro_section .slide_description_wrapper {
		padding-left: 60px;
		padding-right: 60px;
	}
}

.intro_section .slide_description {
	display: inline-block;
	max-width: 720px;
	vertical-align: middle;
}

.intro_section .slide_description_wrapper:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}

.intro_section .slide_description > div {
	margin: 10px 0;
}

.intro_section p {
	max-width: 600px;
	color: #ffffff;
	font-family: 'Raleway', sans-serif;
	line-height: 30px;
}

.intro_section .big {
	font-size: 22px;
}

.intro_section h3 {
	font-size: 40px;
	line-height: 0.9;
	color: #ffffff;
}

.intro_section .top-corner {
	max-width: 120px;
}

.intro_section .top-corner div {
	margin-top: -3px;
	text-transform: uppercase;
	padding: 20px;
}

.intro_section .flex-control-nav {
	text-align: left;
}

@media (min-width: 992px) {
	.intro_section .container {
		width: 100%;
	}
	.intro_section .slide_description_wrapper {
		padding-left: 50px;
		margin-left: 50px;
	}
	.intro_section .slide_description {
		max-width: 500px;
	}
}

@media (min-width: 1200px) {
	.intro_section .big {
		font-size: 28px;
	}
	.intro_section p {
		font-size: 24px;
	}
}

.boxed .intro_section h3 {
	font-size: 50px;
	font-weight: 100;
}

.boxed .intro_section .big {
	font-size: 30px;
	font-weight: 100;
}

.boxed .intro_section p {
	font-size: 24px;
}

.boxed .intro_section .topmargin_40 {
	margin-top: 30px;
}

.boxed .intro_section .topmargin_30 {
	margin-top: 20px;
}

@media (min-width: 1450px) {
	.intro_section .slide_description_wrapper {
		padding-left: 100px;
	}
	.intro_section .slide_description {
		max-width: 720px;
	}
	.intro_section .container {
		width: 100%;
	}
	.intro_section h3 {
		font-size: 90px;
		font-weight: 100;
	}
	.intro_section .big {
		font-size: 40px;
		font-weight: 100;
	}
	.intro_section p {
		font-size: 26px;
	}
}

@media (min-width: 1600px) {
	.intro_section .container {
		width: 100%;
		max-width: 1850px;
	}
}

@media (max-width: 767px) {
	.intro_section .flex-control-nav {
		left: 10px;
	}
	.intro_section h3 {
		font-size: 30px;
	}
}

@media (max-width: 1200px) {
	.intro_section p.margin-b50 {
		margin-bottom: 20px;
	}
}

@media (max-width: 992px) {
	.intro_section {
		line-height: 1;
		text-align: center;
	}
	.intro_section .slides li:nth-of-type(1) {
		background-color: #365510;
	}
	.intro_section .slides li:nth-of-type(2) {
		background-color: #365510;
	}
	.intro_section .slides li:nth-of-type(3) {
		background-color: #365510;
	}
	.intro_section .slides li:nth-of-type(3) h3 {
		font-size: 30px !important;
	}
	.intro_section .nav-container {
		padding: 0;
	}
	.intro_section .container {
		position: static;
		width: auto;
		max-width: 100%;
	}
	.intro_section .slide_description_wrapper:before {
		display: none;
	}
	.intro_section .slide_description {
		padding: 20px 20px 30px;
		display: block;
		max-width: 100%;
	}
	.intro_section .slide_description p {
		margin: auto;
	}
	.intro_section .slide_description_wrapper {
		padding: 0;
		position: static;
	}
	.intro_section .flex-direction-nav .flex-prev {
		top: 14%;
	}
	.intro_section .flex-direction-nav .flex-next {
		top: 14%;
	}
}

/*
**shop
*/
/* topline buttons */
.currency-dropdown .dropdown-menu {
	min-width: 0;
	padding: 20px 10px;
}

.currency-dropdown .dropdown-menu > li > a {
	color: inherit;
	padding: 5px 20px;
}

.currency-dropdown .dropdown-menu > li > a:hover,
.currency-dropdown .dropdown-menu > li.active-currency {
	color: #292a2e;
}

.login-dropdown .dropdown-menu {
	min-width: 400px;
	padding: 30px 30px;
}

.login-dropdown .dropdown-menu .form-control {
	border-color: rgba(0, 0, 0, 0.1);
}

.cart-dropdown .dropdown-menu {
	min-width: 400px;
	padding: 20px 30px;
}

@media (max-width: 767px) {
	.login-dropdown,
	.cart-dropdown {
		position: static;
	}
	.login-dropdown .dropdown-menu,
	.cart-dropdown .dropdown-menu {
		margin-left: 0;
		margin-right: 0;
		right: 0;
		left: 0;
		min-width: 0;
	}
}

/* sort panel */
.storefront-sorting {
	line-height: 1;
	padding: 35px 35px 20px;
}

.storefront-sorting .form-group {
	margin-bottom: 15px;
}

.storefront-sorting label {
	padding-right: 10px;
}

#toggle_shop_view {
	margin-left: 14px;
}

#toggle_shop_view:before,
#toggle_shop_view:after {
	font-family: 'FontAwesome';
	display: inline-block;
	background-color: #292a2e;
	color: #ffffff;
	padding: 12px 12px 13px;
	font-size: 14px;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

#toggle_shop_view:before {
	content: "\f00a";
	margin-right: 5px;
}

#toggle_shop_view:after {
	content: "\f03a";
	background-color: #f96380;
}

#toggle_shop_view.grid-view:before {
	background-color: #f96380;
}

#toggle_shop_view.grid-view:after {
	background-color: #292a2e;
}

#sort_view {
	color: #292a2e;
	display: inline-block;
	padding: 12px 11px 13px;
	font-size: 14px;
	background-color: #ffffff;
}

.columns-1 .product {
	width: 100%;
	margin-right: 0;
	float: none;
}

@media (min-width: 992px) {
	.columns-2 .product {
		width: 50%;
		float: left;
	}
	.columns-3 .product {
		width: 33.33333333%;
		float: left;
	}
	.columns-4 .product {
		width: 25%;
		float: left;
	}
}

#products {
	margin-left: -15px;
	margin-right: -15px;
}

#products:before, #products:after {
	content: '';
	clear: both;
	display: block;
}

li.product {
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 30px;
}

.owl-item .product {
	padding: 0;
}

.product .row {
	position: relative;
}

.product [class*="col-"] {
	position: static;
}

.product [class*="col-"].images a {
	display: block;
	position: relative;
}

.product h3 {
	margin-bottom: 20px;
}

.product .rating-title {
	font-size: 1.2em;
}

.product [class*="col-"]:first-child h3 {
	text-align: center;
	font-size: 20px;
	font-weight: 700;
	margin: 18px 0 24px;
	position: relative;
	letter-spacing: 0;
}

.product .theme_button {
	margin: 4px 0 11px;
	padding-right: 25px;
	padding-left: 25px;
	min-width: auto;
}

.product .product-media {
	position: relative;
}

.product [class*="col-"]:first-child a span,
.product [class*="col-"]:first-child .product-media span {
	position: absolute;
	background-color: #e96ac2;
	color: #ffffff;
	padding: 8px 18px;
	text-transform: uppercase;
	top: 20px;
	right: 20px;
}

.product [class*="col-"]:first-child a span.onsale,
.product [class*="col-"]:first-child .product-media span.onsale {
	background-color: #f96380;
	left: 20px;
	bottom: 20px;
	right: auto;
	top: auto;
}

.related > h2 {
	margin: 20px 0 30px;
}

.star-rating {
	overflow: hidden;
	position: relative;
	height: 1em;
	line-height: 1em;
	font-size: 12px;
	width: 5.5em;
	font-family: "rt-icons-2";
	margin-bottom: 4px;
}

.star-rating:before,
.star-rating span:before {
	content: "\e7aa\e7aa\e7aa\e7aa\e7aa";
	float: left;
	top: 0;
	left: 0;
	position: absolute;
	letter-spacing: 0.1em;
	letter-spacing: 0\9;
	color: #e5e5e5;
}

.star-rating span {
	overflow: hidden;
	float: left;
	top: 0;
	left: 0;
	position: absolute;
	padding-top: 1.5em;
}

.star-rating span:before {
	color: #f96380;
}

.price {
	position: absolute;
	left: 20px;
	top: 20px;
	color: #292a2e;
	background-color: #ffffff;
	padding: 8px 18px;
}

.price del {
	opacity: 0.5;
	font-size: 12px;
}

.price ins {
	text-decoration: none;
}

.product-buttons {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

.product-buttons a {
	float: left;
	width: 40px;
	height: 40px;
	font-size: 16px;
	text-align: center;
}

.product-buttons a i {
	line-height: 40px;
}

.product-buttons a.favorite_button {
	background-color: #ffffff;
	color: #292a2e;
}

.product-buttons a.add_to_cart_button {
	color: #ffffff;
	background-color: #f96380;
}

.product-buttons a.add_to_cart_button.loading [class*='rt-icon'] {
	display: inline-block;
	-moz-animation: spin 2s infinite linear;
	-o-animation: spin 2s infinite linear;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
}

.product-buttons a.add_to_cart_button.loading [class*='rt-icon']:before {
	content: "\e61b";
}

.product-buttons a:hover {
	background-color: #292a2e;
	color: #ffffff;
}

.product-buttons a.added_to_cart {
	background-color: #ffffff;
	font-size: 0;
	color: transparent;
}

.product-buttons a.added_to_cart:hover {
	background-color: #f96380;
}

.product-buttons a.added_to_cart:before {
	content: "\e6c0";
	line-height: 40px;
	color: #292a2e;
	font-family: 'rt-icons-2';
	font-size: 26px;
}

.grid-view {
	text-align: center;
}

.grid-view .row {
	position: relative;
}

.grid-view .product [class*="col-"]:last-child {
	padding: 20px 35px 20px 35px;
	margin-top: 0 !important;
	position: absolute;
	top: 0;
}

.grid-view .product [class*="col-"]:last-child div, .grid-view .product [class*="col-"]:last-child h3 {
	display: none;
}

.grid-view .item-content h4 {
	display: none;
}

.grid-view .star-rating {
	display: none;
}

.grid-view .product-description {
	display: none;
}

.grid-view [class*="col-"] {
	width: 100%;
	float: none;
}

.list-view .product {
	float: none;
	width: 100%;
}

.list-view .product [class*="col-"]:first-child h3 {
	display: none;
}

.list-view .product [class*="col-"]:last-child {
	margin-top: 0 !important;
}

/* single-product */
.summary h1 {
	margin-bottom: 3px;
}

.summary .product-rating,
.summary .woocommerce-product-rating {
	margin-bottom: 10px;
}

.summary .price {
	position: static;
	margin-top: 20px;
	font-size: 50px;
	background-color: transparent;
	padding: 0;
	line-height: 1;
	word-wrap: break-word;
}

.summary .price del {
	font-size: 28px;
}

.summary .star-rating {
	display: inline-block;
	top: 4px;
	margin-right: 20px;
}

.summary .stock {
	margin-bottom: 0;
}

.summary .email-to {
	margin-bottom: 10px;
}

.summary .product-option-name {
	display: inline-block;
	margin: 10px 0;
}

.summary .theme_button {
	margin-top: 0;
}

.summary .posted_in {
	margin: 10px 0;
}

.summary .posted_in .small_button {
	margin-left: 5px;
	padding-left: 6px;
	padding-right: 6px;
}

.summary ul.list1 li {
	padding-top: 15px;
	padding-bottom: 20px;
}

.summary .reset_variations {
	padding: 0 5px;
}

.product_meta_bottom {
	margin: 0 0 20px;
}

.product-tabs {
	margin-top: 30px;
}

.owl-carousel.product-thumbnails {
	margin: 30px 0 30px;
}

.owl-carousel.product-thumbnails .owl-dots {
	margin: 20px 0 5px;
}

.owl-carousel.product-thumbnails .owl-dots span {
	width: 8px !important;
	height: 8px !important;
	border-width: 1px !important;
}

.owl-carousel.product-thumbnails .owl-nav {
	margin-top: -18px;
}

.owl-carousel.product-thumbnails .owl-nav > div {
	right: 0;
}

.owl-carousel.product-thumbnails .owl-nav > div:first-child {
	left: 0;
}

.owl-carousel.product-thumbnails .owl-nav > div:after {
	font-size: 16px;
	color: #292a2e;
}

.product-icons .teaser [class*="_bg_color"] {
	padding: 10px;
}

.product + .nav-tabs {
	margin-top: 60px;
}

.tab-content .comments-area {
	margin-top: 10px;
}

.tab-content .comment-respond {
	margin-top: 30px;
}

.tab-content .comment-respond h4 {
	margin-bottom: 0;
	font-size: 28px;
}

.comment .star-rating {
	display: inline-block;
	top: 5px;
	margin-left: 10px;
}

.stars {
	position: relative;
}

.stars a {
	font-size: 12px;
	display: inline-block;
	font-weight: 700;
	text-indent: -9999px;
	position: relative;
	border-bottom: 2px solid transparent;
	color: #f96380;
	letter-spacing: 0.1em;
}

.stars a.active {
	border-bottom: 1px solid #f96380;
}

.stars a:after {
	font-family: "rt-icons-2";
	text-indent: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.stars a.star-1 {
	width: 2em;
}

.stars a.star-1:after {
	content: "\e7aa";
}

.stars a.star-2 {
	width: 3em;
}

.stars a.star-2:after {
	content: "\e7aa\e7aa";
}

.stars a.star-3 {
	width: 4em;
}

.stars a.star-3:after {
	content: "\e7aa\e7aa\e7aa";
}

.stars a.star-4 {
	width: 5em;
}

.stars a.star-4:after {
	content: "\e7aa\e7aa\e7aa\e7aa";
}

.stars a.star-5 {
	width: 6em;
}

.stars a.star-5:after {
	content: "\e7aa\e7aa\e7aa\e7aa\e7aa";
}

.comment-form-rating .stars span {
	display: block;
	padding: 20px 0 20px;
	text-align: center;
}

/* cart */
.shop-adds {
	font-size: 20px;
	font-weight: 300;
	line-height: 1.4;
}

.shop-adds.teaser {
	margin-bottom: 0;
}

.shop-adds.teaser [class*="_bg_color"] {
	padding: 12px;
}

.cart-table {
	margin-bottom: 30px;
}

.cart_item .media {
	margin-left: -15px;
}

.cart_item img {
	max-width: 80px;
}

.cart_item h4 {
	margin-bottom: 12px;
}

.cart_item td {
	vertical-align: middle !important;
	padding: 18px 10px !important;
}

.cart_totals h4 {
	margin: 60px 0 30px;
}

.cart_totals table {
	margin: 0 0 50px;
}

.cart_totals td {
	padding: 20px 10px !important;
}

.cart_totals .shipping-calculator-form {
	display: block !important;
	height: auto !important;
}

.cart_totals .shipping-calculator-button {
	display: none;
}

/* cart gradient section with teasers */
.quantity {
	position: relative;
	display: inline-block;
}

.quantity [type="button"] {
	position: absolute;
	right: 0;
	top: 0;
	line-height: 1;
	border: none;
	width: 22px;
	height: 24px;
	background-color: transparent;
}

.quantity [type="button"].minus {
	top: auto;
	bottom: 0;
}

.quantity [type="number"] {
	padding-right: 30px;
	padding-left: 20px;
	max-width: 70px;
	min-width: 70px;
}

/* chekcout */
.shop-info {
	margin-bottom: 15px;
}

#createaccount {
	margin-top: 0;
}

#ship-to-different-address label {
	display: inline-block;
}

#ship-to-different-address label + input {
	margin: 0 12px;
}

.wc_payment_method img {
	display: inline-block;
	padding: 0 10px;
}

.form-row:before, .form-row:after {
	content: '';
	display: block;
	clear: both;
}

.form-row .checkbox {
	margin: -5px 0 0;
}

.form-row .lost_password {
	margin: 15px 0 -15px;
}

.form-row label {
	text-align: left !important;
	display: block;
}

@media (min-width: 768px) {
	.form-row label {
		padding-right: 15px;
		width: 30%;
		float: left;
	}
	.form-row label + *,
	.form-row.address-field input {
		float: right;
		width: 70% !important;
	}
}

.select2-container .select2-choice {
	border: none;
	padding: 10px 20px;
	bordr-radius: none;
}

.shop-checkout-payment {
	margin-top: 40px;
}

.shop-checkout-payment .radio {
	margin: 2px 0;
}

/* shop widgets */
/* cart */
.widget_shopping_cart img {
	max-width: 80px;
}

.widget_shopping_cart h4 {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
}

.widget_shopping_cart .cart_list {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 20px;
	margin: 20px 0;
}

.widget_shopping_cart .media-body:last-child {
	width: 1000px;
}

.widget_shopping_cart .media-body:last-child i {
	padding: 0 0 0 10px;
}

/* price filter */
.widget_price_filter input[readonly] {
	background-color: transparent;
	padding-right: 3px;
	padding-left: 3px;
	max-width: 89px;
	margin-bottom: 4px;
	display: inline-block;
}

.widget_price_filter .ui-slider-range {
	background-color: #f96380;
}

.widget_price_filter .ui-slider-horizontal {
	margin-bottom: 30px;
}

.widget_price_filter label[for] {
	display: inline-block !important;
	padding-right: 4px;
}

.widget_price_filter .form-group {
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}

.widget_price_filter .theme_button {
	margin-top: 16px;
	padding-top: 8px;
	padding-bottom: 8px;
}

/*! jQuery UI - v1.11.1 - 2014-10-03
* http://jqueryui.com
* Includes: core.css, slider.css
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
	display: none;
}

.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
	content: "";
	display: table;
	border-collapse: collapse;
}

.ui-helper-clearfix:after {
	clear: both;
}

.ui-helper-clearfix {
	min-height: 0;
	/* support: IE7 */
}

.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0);
	/* support: IE8 */
}

.ui-front {
	z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
	cursor: default !important;
}

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
}

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ui-slider {
	position: relative;
	text-align: left;
	background-color: #fafafa;
}

.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 12px;
	height: 12px;
	cursor: pointer;
	-ms-touch-action: none;
	touch-action: none;
	background-color: #292a2e;
}

.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
	filter: inherit;
}

.ui-slider-horizontal {
	height: 10px;
}

.ui-slider-horizontal .ui-slider-handle {
	top: -1px;
	margin-left: -.6em;
}

.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}

.ui-slider-vertical {
	width: .8em;
	height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
	left: -.3em;
	margin-left: 0;
	margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}

/* color layered nav */
.widget_layered_nav .color-filters li {
	display: inline-block;
	margin: 0 29px 14px 0;
}

.widget_layered_nav .color-filters a {
	display: inline-block;
	width: 23px;
	height: 23px;
	font-size: 0;
	background-color: #ccc;
	color: transparent;
	position: relative;
}

.widget_layered_nav .color-filters a:before {
	content: '';
	display: block;
	position: absolute;
	border: 1px solid rgba(0, 0, 0, 0.1);
	top: -2px;
	left: -2px;
	bottom: -2px;
	right: -2px;
}

.widget_layered_nav .color-filters span {
	position: relative;
	top: 2px;
	left: 5px;
}

/* widget_products */
.widget_products img {
	max-width: 80px;
}

.widget_products .media h3 {
	font-size: inherit;
	line-height: 1.4;
	margin: 3px 0 10px;
}

.widget_products .star-rating {
	margin-bottom: 10px;
}

.widget_products .price {
	position: static;
	background-color: transparent;
	padding: 0;
}

.widget_products .add_to_cart_button {
	display: inline-block;
	width: 20px;
	height: 20px;
	font-size: 14px;
	text-align: center;
	background-color: #f96380;
	color: #ffffff;
	margin-right: 5px;
}

.widget_products .add_to_cart_button:hover {
	color: #ffffff;
	background-color: #292a2e;
}

/*widget_top_rated_products*/
.widget_top_rated_products img {
	max-width: 80px;
}

/*widget_recent_reviews*/
.widget_recent_reviews li {
	margin-bottom: 16px;
}

.widget_recent_reviews li:before, .widget_recent_reviews li:after {
	content: '';
	display: block;
	clear: both;
}

.widget_recent_reviews img {
	display: block;
	float: left;
	max-width: 80px;
	margin-right: 10px;
}

/*
**main menu (min 992px) styles
*/
.mobile-active .mainmenu_wrapper {
	line-height: 40px;
}

.mainmenu_wrapper {
	line-height: normal;
}

@media (min-width: 1200px) {
	/* menu layout */
	.mainmenu_wrapper {
		display: block;
		height: 100%;
	}
	.sf-menu {
		height: 100%;
	}
	.sf-menu > li {
		padding-top: 34px;
		padding-bottom: 34px;
	}
	.sf-menu > li > a {
		display: inline-block;
		line-height: 12px;
	}
	header.affix .sf-menu > li {
		padding-top: 14px;
		padding-bottom: 14px;
	}
	header .sf-menu > li {
		-webkit-transition: all 0.2s ease-in-out 0s;
		-moz-transition: all 0.2s ease-in-out 0s;
		-o-transition: all 0.2s ease-in-out 0s;
		-ms-transition: all 0.2s ease-in-out 0s;
		transition: all 0.2s ease-in-out 0s;
	}
	.sf-menu, .sf-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	.sf-menu li {
		position: relative;
	}
	.sf-menu li > ul {
		position: absolute;
		display: none;
		top: 100%;
		z-index: 99;
	}
	.sf-menu a {
		display: block;
		position: relative;
	}
	.sf-menu li > ul > li > ul {
		top: 0;
		left: 100%;
	}
	.sf-menu li > ul > li > ul a {
		border-top: 1px solid rgba(255, 255, 255, 0.1);
	}
	.sf-menu li > ul > li:first-child ul {
		top: -20px;
	}
	/* menu skin */
	.sf-menu a {
		font-family: 'Raleway', sans-serif;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 500;
		line-height: 1;
		letter-spacing: 0.2em;
		zoom: 1;
		/* IE7 */
		transition-property: color, background-color, border-color;
	}
	.sf-menu li {
		white-space: nowrap;
		/* no need for Supersubs plugin */
		*white-space: normal;
		/* ...unless you support IE7 (let it wrap) */
		transition: background .2s;
	}
	.sf-menu li li {
		padding-right: 35px;
		padding-left: 35px;
	}
	.sf-menu li li a {
		line-height: 1;
		min-width: 200px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}
	.sf-menu li li:first-child a {
		/* style */
	}
	.sf-menu li ul > li:first-child > a {
		border-top: none;
	}
	.sf-menu > li {
		display: inline-block;
		position: relative;
	}
	.sf-menu > li:before {
		content: "";
		position: absolute;
		left: -2px;
		top: 50%;
		-webkit-transform: translateY(-2px);
		-ms-transform: translateY(-2px);
		-o-transform: translateY(-2px);
		transform: translateY(-2px);
		display: inline-block;
		width: 4px;
		height: 4px;
		background-color: #50CC60;
		border-radius: 50%;
		vertical-align: middle;
	}
	.sf-menu > li:first-child:before {
		display: none;
	}
	.sf-menu > li > a {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.sf-menu > li > a:hover,
	.sf-menu > li.active > a {
		color: #50cc60;
	}
	.bordered_items .sf-menu {
		text-align: center;
	}
	.bordered_items .sf-menu > li {
		padding: 0;
	}
	.bordered_items .sf-menu > li + li:before {
		content: '';
		width: 1px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.1);
	}
	.header_white .sf-menu > li > a {
		color: #292a2e;
	}
	.header_white .sf-menu > li > a:hover,
	.header_white .sf-menu > li.active > a {
		color: #f96380;
	}
	.header_white .sf-menu > li + li:before {
		background-color: rgba(0, 0, 0, 0.1);
	}
	.header_darkgrey .sf-menu > li > a {
		color: #ffffff;
	}
	.header_darkgrey .sf-menu > li > a:hover,
	.header_darkgrey .sf-menu > li.active > a {
		color: #f96380;
	}
	.header_gradient .sf-menu > li > a {
		color: #ffffff;
	}
	.header_gradient .sf-menu > li > a:hover,
	.header_gradient .sf-menu > li.active > a {
		color: #292a2e;
	}
	.sf-menu li:hover,
	.sf-menu li.sfHover {
		/* only transition out, not in */
		transition: none;
	}
	/* second and next levels */
	.sf-menu > li .mega-menu,
	.sf-menu li > ul {
		margin-top: 1px;
		text-align: left;
		list-style: none;
		padding: 20px 0;
		background-color: #ffffff;
		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
		min-width: 12em;
		/* allow long menu items to determine submenu width */
		*width: 12em;
		/* no auto sub width for IE7, see white-space comment below */
	}
	.sf-menu ul a {
		color: #375416;
		padding-top: 18px;
		padding-bottom: 18px;
		text-transform: none;
		font-size: 15px;
		letter-spacing: 0.1em;
	}
	.sf-menu .mega-menu ul {
		box-shadow: none;
		padding: 0;
	}
	.sf-menu .mega-menu ul a {
		padding-left: 0;
		padding-right: 0;
	}
	.sf-menu ul a:hover,
	.sf-menu ul li.active a {
		color: #50cc60;
	}
	/* third level */
	.sf-menu ul ul {
		margin-top: 0;
		background-color: #292a2e;
	}
	.sf-menu ul ul a {
		color: #ffffff;
		border-top: 1px solid rgba(255, 255, 255, 0.1);
	}
	.sf-menu ul ul a:hover {
		color: #f96380;
	}
	/*** arrows (for all except IE7) **/
	/* styling for both css and generated arrows */
	/*top menu level*/
	/* styling for right-facing arrows */
}

/* common paddings */
@media (min-width: 992px) {
	.sf-menu > li > a {
		padding-left: 2px;
		padding-right: 0px;
	}
	.bordered_items .sf-menu > li > a {
		padding-right: 40px;
		padding-left: 44px;
	}
}

/* -md- paddings */
@media (min-width: 992px) and (max-width: 1199px) {
	.sf-menu > li > a {
		padding-left: 9px;
		padding-right: 8px;
	}
	.bordered_items .sf-menu > li > a {
		padding-right: 20px;
		padding-left: 24px;
	}
}

/* -lg- paddings */
@media (min-width: 1200px) {
	.sf-menu a {
		font-size: 10px;
	}
	.sf-menu > li > a {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (min-width: 1301px) {
	.sf-menu a {
		font-size: 12px;
	}
	.sf-menu > li > a {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (min-width: 1500px) {
	.sf-menu > li > a {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.toggle_menu {
	display: none;
	width: 60px;
	height: 60px;
	line-height: normal;
	cursor: pointer;
	z-index: 1001;
	visibility: visible;
}

.header_white .toggle_menu span,
.header_white .toggle_menu span:before,
.header_white .toggle_menu span:after {
	background-color: #292a2e;
}

.page_toplogo .toggle_menu {
	position: fixed;
	top: 0;
	left: auto;
	right: 0;
	background-color: #292a2e;
	margin: 0;
}

.toggle_menu:hover span:before {
	top: -7px;
}

.toggle_menu:hover span:after {
	top: 7px;
}

.toggle_menu span,
.toggle_menu span:before,
.toggle_menu span:after {
	display: block;
	width: 24px;
	height: 2px;
	position: absolute;
	background-color: #292a2e;
	content: '';
	left: 0;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.toggle_menu span {
	left: 18px;
	top: 50%;
	margin-top: -1px;
}

.toggle_menu span:before {
	top: -6px;
}

.toggle_menu span:after {
	top: 6px;
}

/*
**main menu mobile styles
*/
@media (max-width: 1199px) {
	.mainmenu_wrapper {
		/*position: relative;*/
		position: fixed;
		visibility: hidden;
		z-index: 10;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: transparent;
		opacity: 0;
		-webkit-transition: all 0.3s ease 0s;
		-moz-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		-ms-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
	.mobile-active .mainmenu_wrapper {
		visibility: visible;
		opacity: 1;
		background-color: #111;
		background-color: rgba(0, 0, 0, 0.9);
	}
	.toggle_menu {
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}
	.toggle_menu.mobile-active {
		background-color: #292a2e !important;
		border: none;
		left: auto;
		top: 0;
		right: 0;
		position: fixed;
		margin: 0;
		z-index: 3000;
	}
	.toggle_menu.mobile-active:after {
		position: absolute;
		top: 10px;
		left: 10px;
		font-family: 'rt-icons-2';
		content: "\e117";
		font-size: 40px;
		color: #ffffff;
	}
	.toggle_menu.mobile-active span {
		display: none;
	}
	.nav-justified > li > a {
		text-align: left;
	}
	.sf-menu {
		position: fixed;
		overflow: auto;
		background-color: #365510;;
		padding: 10px 20px 10px 10px;
		top: 0px;
		left: 0px;
		bottom: 0px;
		right: 100%;
		font-size: 1.1em;
		text-transform: uppercase;
		font-weight: 500;
		word-wrap: break-word;
		text-align: left;
		-webkit-transition: all 0.4s ease-out 0s;
		-moz-transition: all 0.4s ease-out 0s;
		-o-transition: all 0.4s ease-out 0s;
		-ms-transition: all 0.4s ease-out 0s;
		transition: all 0.4s ease-out 0s;
		opacity: 0;
	}
	.mobile-active .sf-menu {
		opacity: 1;
		right: 20px;
	}
	.sf-menu > li > a,
	.sf-menu a {
		padding-top: 15px;
		padding-bottom: 15px;
		color: #ffffff;
	}
	.sf-menu ul {
		list-style: none;
		padding-left: 10px;
	}
	.sf-menu ul li a {
		display: block;
		padding: 10px;
		font-size: 16px;
		text-transform: none;
	}
	.sf-menu ul li a:before {
		content: '-';
		padding-right: 5px;
	}
	.sf-menu ul ul li a:before {
		content: '--';
		padding-right: 5px;
	}
	/*** arrows (for all except IE7) **/
	.sf-arrows .sf-with-ul {
		padding-right: 2.5em;
		*padding-right: 1em;
		/* no CSS arrows for IE7 (lack pseudo-elements) */
	}
	/* styling for both css and generated arrows */
	.sf-arrows .sf-with-ul:after {
		font-family: 'rt-icons-2';
		content: "\e7bf";
		position: absolute;
		right: 2.88em;
		height: 0;
		width: 0;
		font-size: 30px;
	}
	/*top menu level*/
	.sf-arrows .sfHover > a,
	.sf-arrows .sfHover > a:hover {
		color: #ffffff;
		background-color: #292a2e;
	}
}

/* Mega Menu */
@media screen and (min-width: 992px) {
	.primary-navigation ul .mega-menu ul {
		position: static;
		float: none;
		width: 100%;
		display: table;
		table-layout: fixed;
	}
	.primary-navigation .menu-item-has-mega-menu {
		position: relative;
	}
	.primary-navigation .mega-menu {
		display: none;
		position: absolute;
		left: -500px;
		top: 100%;
		z-index: 10;
	}
	.primary-navigation .menu-item-has-mega-menu:hover .mega-menu {
		display: block;
	}
	/* row */
	.primary-navigation .mega-menu-row {
		position: static;
		width: 100%;
		display: table;
		table-layout: fixed;
	}
	/* column */
	.primary-navigation .mega-menu-col {
		display: table-cell;
		width: 1px;
		padding: 0 30px;
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		/* overrides from settings / styling / mega-menu */
	}
	.primary-navigation .mega-menu-col:first-child {
		border-left: none;
	}
}

@media screen and (max-width: 991px) {
	.primary-navigation .mega-menu {
		position: static;
	}
}

/*
**widgets
*/
aside > div + div {
	margin-top: 10px;
}

aside > div + div > .widget-title {
	margin-top: 22px;
}

aside .widget + .widget {
	margin-top: 80px;
}

/* affix-aside */
.affix-aside {
	position: relative;
}

.affix-aside.affix {
	position: fixed;
	top: 50px;
}

@media (max-width: 767px) {
	.affix-aside, .affix-aside.affix {
		margin-top: 50px !important;
		position: static !important;
		top: auto !important;
		left: auto !important;
		width: inherit !important;
	}
}

.module-header {
	margin: 0 0 38px;
	text-transform: capitalize;
	font-size: 40px;
}

.page_footer .module-header {
	font-size: 28px;
}

.widget > h3,
.widget-title {
	font-size: 30px;
	margin: 0 0 35px;
	font-weight: 300;
	position: relative;
}

.widget ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.widget ul ul li {
	padding-left: 20px;
}

.widget li {
	position: relative;
}

.widget .vertical-item h4 {
	font-size: 20px;
	margin: 17px 0 0;
}

.widget .vertical-item + .widget .vertical-item {
	margin-top: 16px;
}

.widget select {
	width: 100%;
	height: 60px;
}

.page_footer .widget li,
.page_footer .widget ul {
	border: none !important;
}

.page_footer .widget-title {
	font-size: 28px;
	margin-bottom: 5px;
}

/*
widget_search,
widget_mailchimp
*/
.page_footer .widget_mailchimp h3 {
	text-align: left !important;
}

.widget_mailchimp {
	overflow: hidden;
}

.widget_mailchimp p {
	padding-top: 10px;
}

.widget_search .form-inline,
.widget_mailchimp .form-inline {
	position: relative;
}

.widget_search .form-inline .form-group,
.widget_mailchimp .form-inline .form-group {
	display: block;
	vertical-align: top;
	position: relative;
}

.widget_search .form-inline input[type="text"],
.widget_mailchimp .form-inline input[type="email"] {
	padding-right: 50px;
	display: block;
	width: 100%;
}

@media (min-width: 768px) {
	.widget_search .form-group.inline-block,
	.widget_mailchimp .form-group.inline-block {
		min-width: 370px;
		margin-right: 5px;
	}
}

.widget_search .form-inline .theme_button,
.widget_mailchimp .form-inline .theme_button {
	font-size: 24px;
	padding: 18px 0 17px;
}

.widget_search .form-inline .theme_button,
.widget_mailchimp .form-inline .theme_button {
	position: absolute;
	right: 20px;
	top: 0;
	width: auto;
	border: none !important;
	background-color: transparent;
	color: #f96380;
}

.widget_search .form-inline .theme_button:hover,
.widget_mailchimp .form-inline .theme_button:hover {
	text-shadow: none;
}

.widget_mailchimp {
	padding: 0 5px;
	font-weight: 300;
	line-height: 30px;
}

.widget_mailchimp form {
	position: relative;
}

.widget_mailchimp .form-group {
	width: 100%;
}

.widget_mailchimp p {
	margin-top: 0px;
	margin-left: 0px;
	margin-bottom: 0;
	font-size: 18px;
	color: #FFFAE4;
}

.widget_mailchimp .mailchimp_email {
	width: 100%;
}

.sidebar .widget_mailchimp {
	padding: 25px 40px;
	background-color: #292a2e;
}

.sidebar .widget_mailchimp h3 {
	margin-top: 10px;
	color: #ffffff;
}

.sidebar .widget_mailchimp p {
	margin-top: 25px;
	text-align: left;
}

/*
widget_categiries
widget_archive
*/
.widget_categories,
.widget_archive {
	padding: 35px;
}

.widget_categories select,
.widget_archive select {
	position: relative;
	z-index: 1;
}

.widget_categories i,
.widget_archive i {
	position: absolute;
	top: 23px;
	right: 23px;
	color: #f96380;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	z-index: 0;
}

.widget_categories .select-group:hover i,
.widget_archive .select-group:hover i {
	filter: alpha(opacity=50);
	opacity: 0.5;
}

/*
widget_recent_entries
widget_recent_comments
widget_meta
widget_nav_menu
widget_pages
*/
.widget_recent_comments li {
	padding: 15px 0;
}

.widget_recent_comments li:first-child {
	padding-top: 0;
}

.widget_recent_comments li:before {
	font-family: 'rt-icons-2';
	content: "\e65c";
	font-size: 16px;
	line-height: 1;
	position: relative;
	top: 4px;
	padding-right: 10px;
	color: #f96380;
}

.widget_recent_entries li {
	margin-bottom: 30px;
}

.widget_recent_entries img {
	max-width: 110px;
}

.widget_recent_entries .media-left {
	padding-right: 30px;
}

.widget_recent_entries .media-body p {
	line-height: 24px;
	margin-bottom: 10px;
}

.widget_recent_entries .media-body a {
	font-weight: 600;
}

.widget_recent_entries > ul > li:first-child,
.widget_recent_comments > ul > li:first-child,
.widget_meta > ul > li:first-child,
.widget_popular_entries > ul > li:first-child,
.widget_nav_menu > ul > li:first-child,
.widget_pages > ul > li:first-child {
	border-top: none;
	margin-top: -0.8em;
}

.widget_meta a,
.widget_nav_menu a,
.widget_pages a {
	display: inline-block;
	padding: 12px 0 12px 16px;
	position: relative;
}

.widget_meta a:before,
.widget_nav_menu a:before,
.widget_pages a:before {
	font-family: 'rt-icons-2';
	content: "\e7c1";
	font-size: 18px;
	color: #f96380;
	position: absolute;
	left: -3px;
}

.widget_nav_menu ul ul li:first-child a {
	padding-top: 13px;
}

.widget_nav_menu a i {
	padding-right: 7px;
}

@media (min-width: 768px) {
	.page_copyright .widget_nav_menu li, .page_topline .widget_nav_menu li {
		display: inline-block;
		position: relative;
		border: none;
		padding-right: 5px;
	}
	.page_copyright .widget_nav_menu li:before, .page_topline .widget_nav_menu li:before {
		content: '';
		position: absolute;
		padding: 0;
		left: -5px;
		top: 5px;
		bottom: 5px;
		width: 1px;
		background-color: rgba(0, 0, 0, 0.1);
		opacity: 0.3;
		display: block;
	}
	.page_copyright .widget_nav_menu li:first-child:before, .page_topline .widget_nav_menu li:first-child:before {
		display: none;
	}
	.page_copyright .widget_nav_menu a, .page_topline .widget_nav_menu a {
		padding: 0 10px;
	}
	.page_copyright .widget_nav_menu a:before, .page_topline .widget_nav_menu a:before {
		display: none;
	}
}

/*widget_tag_cloud*/
.widget_tag_cloud a {
	padding-right: 20px;
	padding-left: 20px;
	margin: 5px 3px;
}

/*widget_flickr*/
.widget_flickr {
	max-width: 360px;
	padding: 0;
}

.widget_flickr h3 {
	margin-left: 5px;
}

.widget_flickr ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.widget_flickr ul li {
	position: relative;
	display: inline-block;
	margin: 5px;
}

.widget_flickr ul li:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(41, 42, 46, 0.8);
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.widget_flickr ul li:hover:before {
	filter: alpha(opacity=0);
	opacity: 0;
}

.widget_flickr ul li img {
	width: 80px;
}

.sidebar .widget_flickr li:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(41, 42, 46, 0.8);
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	filter: alpha(opacity=0);
	opacity: 0;
}

.sidebar .widget_flickr li:hover:before {
	filter: alpha(opacity=100);
	opacity: 1;
}

/*widget_popular_entries*/
.widget_popular_entries p {
	padding-top: 6px;
	margin-bottom: 10px;
	line-height: 24px;
}

.widget_popular_entries .media-body {
	overflow: visible;
}

.widget_popular_entries .media-left {
	padding-right: 20px;
}

.widget_popular_entries .media-object {
	max-width: 100px;
}

@media (min-width: 768px) and (max-width: 1199px) {
	.widget_popular_entries .media-object {
		max-width: 40px;
	}
}

.widget_popular_entries li {
	margin-top: 2px;
	padding: 20px 0 18px 0;
}

.widget_popular_entries li:first-child {
	padding-top: 0;
}

.widget_popular_entries li:last-child {
	padding-bottom: 0;
}

/*widget_banner*/
.widget_banner {
	overflow: hidden;
	line-height: 1;
	padding: 35px;
}

.widget_banner .item-content {
	padding: 20px;
}

.widget_banner .item-content h3 {
	margin: 0;
	padding: 8px 0;
	font-size: 28px;
}

.widget_banner .item-content span {
	display: inline-block;
	text-transform: uppercase;
	padding: 4px 10px 1px;
	min-width: 120px;
}

.widget_banner .small-text {
	margin-bottom: 0;
}

/*widget_slider*/
.widget_slider .owl-carousel {
	margin-bottom: 0;
}

.widget_slider .owl-carousel:hover .owl-nav {
	top: -43px;
}

.widget_slider .owl-carousel .owl-nav {
	opacity: 1;
	top: -43px;
}

.widget_slider .owl-carousel .owl-nav > div {
	width: auto;
	height: auto;
	right: 0;
	background-color: transparent;
}

.widget_slider .owl-carousel .owl-nav > div:first-child {
	left: auto;
	right: 22px;
}

.widget_slider .owl-carousel .owl-nav > div:after {
	font-size: 12px;
	color: inherit;
	color: #212225;
}

/*widget_calendar*/
#calendar_wrap {
	width: 100%;
	margin-bottom: 0;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (min-width: 768px) and (max-width: 992px) {
	#calendar_wrap {
		overflow-x: auto;
		overflow-y: hidden;
	}
}

.widget_calendar table {
	width: 100%;
	position: relative;
	text-align: center;
}

.widget_calendar caption {
	line-height: 1;
	font-size: 24px;
	padding: 18px 0 18px;
	text-align: center;
	color: #ffffff;
	background-color: #f96380;
}

.widget_calendar thead {
	background-color: #292a2e;
	border: 1px solid #292a2e;
	border-width: 7px 1px 7px 1px;
	color: #ffffff;
}

.widget_calendar thead th {
	font-weight: 300;
}

.widget_calendar thead th:first-child {
	padding-left: 30px;
}

.widget_calendar thead th:last-child {
	padding-right: 30px;
}

.widget_calendar tbody {
	font-weight: 300;
}

.widget_calendar tbody tr:first-child td {
	padding-top: 29px;
}

.widget_calendar tbody tr:last-child td {
	padding-bottom: 30px;
}

.widget_calendar tbody td {
	border: none;
	padding: 3px 4px;
	position: relative;
}

.widget_calendar tbody td:first-child {
	padding-left: 30px;
}

.widget_calendar tbody td:last-child {
	padding-right: 30px;
}

.widget_calendar tbody td a {
	font-weight: 700;
	color: #f96380;
}

.widget_calendar tbody td a:hover {
	color: #f96380;
}

.widget_calendar th {
	text-align: center;
}

.widget_calendar tfoot {
	position: absolute;
	top: -3px;
	right: 0;
	left: 0;
}

.widget_calendar tfoot td {
	position: absolute;
	top: 0;
}

.widget_calendar tfoot td a {
	color: #ffffff;
	text-align: center;
	font-size: 0;
	width: 60px;
	height: 60px;
	line-height: 60px;
	display: inline-block;
}

.widget_calendar tfoot td a:hover {
	color: #ffffff;
	background-color: #f96380;
	border-color: #f96380;
}

.widget_calendar tfoot #prev {
	left: 0;
}

.widget_calendar tfoot #prev a {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #prev a:before {
	font-size: 14px;
	font-family: 'rt-icons-2';
	content: '\e7c4';
}

.widget_calendar tfoot #next {
	right: 0;
}

.widget_calendar tfoot #next a {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #next a:after {
	font-size: 14px;
	font-family: 'rt-icons-2';
	content: '\e7c5';
}

/*widget_twitter*/
.tweet_avatar {
	float: left;
}

.tweet_right {
	overflow: hidden;
	zoom: 1;
	margin-left: 60px;
}

.tweet_list li + li {
	margin-top: 10px;
	clear: both;
}

.tweet_list li + li .tweet_right {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.tweet_list li + li .tweet_right, .tweet_list li + li .tweet_avatar {
	padding-top: 10px;
}

/* widget schedule */
.widget_schedule ul {
	margin: 0;
}

.widget_text p {
	line-height: 21px;
}

.widget_tabs .nav-tabs > li > a {
	padding: 10px 15px;
}

.widget_rss li {
	margin-bottom: 15px;
}

.widget_rss li .rsswidget {
	font-size: 1.1em;
}

.widget_rss .rss-date {
	display: block;
	font-weight: 700;
}

.widget_rss cite {
	display: block;
	text-align: right;
	font-weight: 700;
}

/* Social Icons */
/*
**shortcodes
*/
/* media component with icon */
.small-teaser {
	line-height: 20px;
}

.small-teaser i {
	line-height: inherit;
}

/*teasers*/
.teaser {
	position: relative;
	margin: 10px 0;
	overflow: visible;
}

.teaser[class*="_bg_color"], .teaser[class*="with_"] {
	padding: 36px 55px;
}

@media (max-width: 500px) {
	.teaser[class*="_bg_color"], .teaser[class*="with_"] {
		padding: 16px 20px;
	}
}

.teaser [class*="_bg_color"] {
	padding: 21px;
	margin-bottom: 2px;
	text-align: center;
}

.teaser [class*="_bg_color"] + h4 {
	margin-top: 24px;
}

.teaser .border_icon {
	padding: 0.35em;
	min-width: 1.8em;
	border-width: 3px;
	border-style: solid;
}

.teaser i {
	min-width: 1em;
	display: inline-block;
}

.teaser h2 {
	font-size: 50px;
	font-weight: 400;
	margin-bottom: 33px;
	position: relative;
	padding-bottom: 10px;
}

.teaser h2:after {
	content: '';
	position: absolute;
	width: 50px;
	margin-left: -25px;
	height: 1px;
	background-color: rgba(204, 204, 204, 0.2);
	bottom: -12px;
	left: 50%;
}

.teaser h3 {
	margin-bottom: 16px;
	font-size: 24px;
	color: #50cc60;
}

.teaser h4 {
	font-size: 20px;
	margin-bottom: 18px;
}

.teaser .media-left {
	padding-right: 45px;
}

@media (max-width: 500px) {
	.teaser .media-left {
		padding-right: 20px;
	}
}

.teaser .media-body h3 {
	margin-bottom: 20px;
	font-size: 28px;
}

.teaser .media-body h4 {
	margin-bottom: 6px;
}

@media (max-width: 500px) {
	.teaser h2, .teaser h3, .teaser h4 {
		font-size: 1.4em !important;
	}
	.teaser h2 span, .teaser h3 span, .teaser h4 span {
		font-size: 1.2em !important;
	}
}

.teaser [class*="count"] {
	font-size: 56px;
	font-weight: 700;
	margin: 17px 0 6px;
}

.teaser [class*="count"] + p {
	margin: 0;
	font-size: 20px;
}

.teaser .theme_button {
	margin: 22px 0 0;
}

.section_padding_bottom_0 .teaser {
	margin-bottom: 0;
}

.teaser_icon {
	display: inline-block;
	line-height: 1;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.teaser_icon + p {
	margin-top: 27px;
}

.teaser:hover .teaser_icon {
	-webkit-animation: scaleDisappear 0.5s;
	-moz-animation: scaleDisappear 0.5s;
	-ms-animation: scaleDisappear 0.5s;
	-o-animation: scaleDisappear 0.5s;
	animation: scaleDisappear 0.5s;
}

@media (min-width: 1200px) {
	.borders-angle {
		position: relative;
	}
	.borders-angle:before, .borders-angle:after {
		content: '';
		position: absolute;
		width: 1px;
		top: -30px;
		bottom: 28px;
		left: -1px;
		background-color: rgba(33, 34, 37, 0.1);
		-moz-transform: rotate(15deg);
		-webkit-transform: rotate(15deg);
		-o-transform: rotate(15deg);
		-ms-transform: rotate(15deg);
		transform: rotate(15deg);
	}
	.borders-angle:after {
		left: auto;
		right: -5px;
	}
}

.size_big {
	font-size: 64px;
}

.size_normal {
	font-size: 42px;
}

.size_small {
	font-size: 32px;
}

/*horizontal teaser*/
.teaser.table_section {
	margin-top: 26px;
}

.teaser.table_section + .teaser.table_section {
	margin-top: 70px;
}

@media (min-width: 768px) {
	.teaser.table_section [class*="col-"] * {
		margin: 0;
		top: 0;
	}
}

@media (max-width: 767px) {
	.teaser.table_section [class*="col-"] * {
		text-align: center !important;
	}
}

/* background teaser */
.bg_teaser {
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	color: #ffffff;
	overflow: hidden;
}

.bg_teaser .bg_overlay {
	position: absolute;
	width: 100%;
	height: 1000px;
}

.bg_teaser .highlight {
	color: #ffffff;
}

.bg_teaser h4 + h3 {
	margin-top: -14px;
	font-size: 50px;
}

.bg_teaser.rectangular .teaser_content {
	padding: 200px 30px 50px;
}

.bg_teaser.rectangular .teaser_content h3 {
	font-size: 40px;
}

.bg_teaser > img {
	display: none;
	visibility: hidden;
}

.bg_teaser .teaser_content {
	margin: 0;
	padding: 40px 40px 35px;
	z-index: 2;
}

.bg_teaser .teaser_content > .media-left {
	padding-right: 35px;
}

.bg_teaser .teaser_content.media {
	padding: 75px 40px 60px;
}

.color_bg_1 .bg_overlay {
	background-color: rgba(241, 196, 15, 0.85);
}

.color_bg_2 .bg_overlay {
	background-color: rgba(39, 174, 96, 0.9);
}

.color_bg_3 .bg_overlay {
	background-color: rgba(121, 85, 72, 0.9);
}

.darkgrey_bg .bg_overlay {
	background-color: rgba(41, 42, 46, 0.8);
}

.square_teaser .item-media:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(41, 42, 46, 0.8);
}

.square_teaser .item-content {
	top: 0;
	margin: 20px 30px 80px;
	overflow: hidden;
}

.square_teaser .teaser_icon {
	color: #f96380;
	position: absolute;
	bottom: 30px;
	left: 30px;
}

.square_teaser .item-media:after, .square_teaser .teaser_icon, .square_teaser h4, .square_teaser p {
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.square_teaser h4 {
	color: #ffffff;
	font-size: 28px;
	font-weight: 700;
}

.square_teaser p {
	color: #ffffff;
	opacity: 0;
}

.square_teaser:hover h4, .square_teaser:hover .teaser_icon {
	color: #292a2e;
}

.square_teaser:hover p {
	opacity: 1;
}

.square_teaser:hover .item-media:after {
	background-color: #f96380;
}

/*thumbnail*/
.thumbnail {
	position: relative;
	text-align: center;
	border-radius: 0;
	padding: 0;
	border: none;
	overflow: hidden;
	background-color: #ffffff;
}

.isotope-item .thumbnail {
	margin-bottom: 30px;
}

.thumbnail h3 {
	font-size: 20px;
	margin-bottom: 2px;
}

.thumbnail h3 a {
	color: inherit;
}

.thumbnail h3 + p {
	color: #f96380;
	margin-bottom: 10px;
}

.thumbnail .caption {
	color: #989898;
	background-color: #ffffff;
	padding: 16px 20px 26px;
}

/*price tables*/
.price-table,
.price-table ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}

.price-table {
	margin: 0;
}

/* price table with background */
.bg_teaser .plan-name {
	background-color: #fefefe;
}

.bg_teaser .plan-price {
	background-color: rgba(255, 255, 255, 0.1);
}

.bg_teaser.color_bg_1 .plan-name h3 {
	color: #f96380;
}

.bg_teaser.color_bg_2 .plan-name h3 {
	color: #ffa276;
}

.bg_teaser.color_bg_3 .plan-name h3 {
	color: #e96ac2;
}

.bg_teaser .features-list li + li {
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.plan-name {
	padding: 30px 0;
}

.plan-name h3 {
	font-size: 32px;
	margin: 0;
}

.plan-price {
	padding: 35px 0 25px;
	position: relative;
	/* dollar sign */
	/* price */
	/* per month */
}

.plan-price span:first-child {
	font-size: 28px;
	font-weight: 300;
}

.plan-price span + span {
	font-size: 60px;
	font-weight: 700;
}

.plan-price p {
	display: inline;
	font-size: 28px;
	font-weight: 300;
}

.features-list {
	margin-top: 20px;
}

.features-list li {
	margin: 10px 40px;
	padding-top: 10px;
}

.features-list .enabled:before,
.features-list .disabled:before {
	font-family: 'rt-icons-2';
	font-size: 24px;
	line-height: 1;
	position: relative;
	top: 5px;
	right: 4px;
	content: "\e116";
	padding-right: 8px;
	color: #292a2e;
}

.features-list .disabled:before {
	content: "\e117";
}

.call-to-action {
	padding: 20px 40px 38px;
}

.price-table.style1 {
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.price-table.style1 .features-list li + li {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.price-table.style1 .plan-price {
	background-color: #f96380;
	color: #ffffff;
	margin: 1px -1px;
}

.price-table.style2 {
	background-color: #ecedef;
}

.price-table.style2 .plan-name {
	background-color: #f96380;
	padding: 30px 0;
}

.price-table.style2 .plan-name h3 {
	color: #ffffff;
}

.price-table.style2 .plan-price {
	padding-bottom: 0;
	color: #292a2e;
}

.price-table.style2 .enabled:before,
.price-table.style2 .disabled:before {
	font-family: 'rt-icons-2';
	content: "\e621";
	padding-right: 13px;
	color: #f96380;
}

.price-table.style2 .disabled:before {
	color: #292a2e;
	content: "\e622";
}

/*contacts*/
.contact-form {
	position: relative;
	z-index: 3;
}

.contact-form textarea {
	max-height: 200px;
}

.contact-form.parallax {
	padding: 50px 50px 35px;
	background-image: url(../images/parallax/progress.jpg);
}

.contact-form-respond {
	display: block;
	font-weight: 800;
}

.contact-form-respond:before {
	font-family: 'rt-icons-2';
	content: "\e71a";
	padding-right: 10px;
}

.contact-form p {
	margin-bottom: 10px;
	position: relative;
}

.contact-form-submit .theme_button {
	margin-right: 6px;
}

.contact-form.transparent-background .form-control {
	background-color: transparent;
}

.contact-email {
	font-size: 40px;
	text-transform: uppercase;
}

@media (max-width: 767px) {
	.contact-email {
		font-size: 20px;
		margin-bottom: 6px;
	}
	.contact-form.parallax {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 40px;
	}
}

.border-paragraphs {
	margin: 25px 0 25px;
}

.border-paragraphs p {
	padding: 9px 0 10px;
	margin: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.border-paragraphs p:first-child {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* partners carousel */
.owl-carousel.partners-carousel {
	margin-bottom: 0;
}

.light-partner-image a {
	display: block;
	background-color: #989898;
}

.light-partner-image a:hover {
	background-color: #f96380;
}

.highlight-partner-image a {
	display: block;
	background-color: #f96380;
}

.highlight-partner-image a:hover {
	background-color: #292a2e;
}

/* Blog Items */
.single-item {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
}

.single-item.small-item {
	max-width: 370px;
}

.blog-item {
	min-height: 730px;
}

.date-post {
	color: #365510;
}

.post-short-description {
	color: #365510;
}

.single-item.small-item img {
	width: 100%;
}

.single-item.small-item .single-item-body > * {
	max-width: 290px;
	margin-right: auto;
	margin-left: auto;
}

.single-item.small-item .single-item-body > a {
	font-weight: 900;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: #f96380;
}

.single-item.small-item .single-item-body > a:hover {
	color: #292a2e;
}

.single-item.small-item h4 {
	line-height: 30px;
	margin-bottom: 25px;
	margin-top: 5px;
}

.single-item.small-item h4 a:hover {
	color: #50cc60;
}

.single-item.small-item p {
	margin-bottom: 20px;
}

.single-item-body {
	position: relative;
	display: block;
	padding: 35px 15px;
	text-align: justify;
}

.single-item-body h3 {
	margin-top: 10px;
	margin-bottom: 5px;
	line-height: 30px;
}

.single-item-body p.post-category {
	margin-bottom: 28px;
	line-height: 1;
}

.single-item-body .add-btn {
	margin-top: 40px;
}

.big-item .single-item-body {
	padding: 55px 60px;
}

@media (max-width: 991px) {
	.big-item .single-item-body {
		padding: 40px 30px;
	}
}

.small-item .single-item-body {
	padding-bottom: 60px;
	text-align: center;
}

.small-item .single-item-body p {
	line-height: 24px;
}

.media-links .abs-link {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
}

.media-links .abs-link:hover {
	opacity: 1;
}

.single-item .abs-link {
	background-color: rgba(41, 42, 46, 0.8);
}

.entry-meta-corner {
	position: absolute;
	z-index: 2;
	text-align: center;
}

.entry-meta-corner .date {
	display: block;
	min-width: 60px;
	padding: 0 3px;
	color: #292a2e;
	text-transform: uppercase;
	background-image: url("/themes/bach/images/system/bg-60-boxed.jpg");
}

.entry-meta-corner .date strong {
	color: #ffffff;
	display: block;
	font-size: 42px;
	font-weight: 300;
	padding: 12px 0 0;
}

.entry-meta-corner .comments-link {
	line-height: 1;
	display: block;
	padding: 2px 5px;
	background-color: #292a2e;
}

.entry-meta-corner .comments-link a {
	color: #ffffff;
}

.entry-meta-corner .comments-link i {
	color: #f96380;
}

/*
**utilites
*/
.alignleft {
	float: left;
	margin: 5px 30px 15px 0;
	max-width: 50%;
}

.alignright {
	float: right;
	margin: 5px 0 15px 30px;
	max-width: 50%;
}

.round {
	border-radius: 50%;
}

.inline-block {
	display: inline-block;
	max-width: 100%;
}

.with_padding {
	padding: 35px 35px;
}

.table_section_content {
	padding: 30px 50px;
}

.table_section_content h3 {
	font-size: 42px;
	font-weight: 300;
	margin: 0 0 32px;
}

.with_background {
	background-color: #ecedef;
}

.muted_background {
	background-color: rgba(41, 42, 46, 0.06);
}

.transp_background,
.single-item-body.transp_background {
	background-color: rgba(255, 255, 255, 0.9);
}

.img_background {
	background-image: url("/themes/bach/images/system/light_muted_bg.jpg");
}

.img_background_before:before {
	/*background-image: url("/themes/bach/images/system/light_muted_bg.jpg");*/
}

.with_border {
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.with_border_pseudo:after,
.with_border_pseudo:before {
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.before_cover,
.after_cover,
.before_cover > *,
.after_cover > * {
	position: relative;
	z-index: 2;
}

.before_cover:before,
.after_cover:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.top-corner {
	position: absolute;
	top: 0;
	left: 0;
	line-height: 1;
}

.big {
	font-size: 42px;
	line-height: 0.9;
}

.response,
.highlight {
	color: #E6DF36;
	border-color: #50cc60;
}

a.highlight:hover {
	filter: alpha(opacity=50);
	opacity: 0.5;
}

.highlight2 {
	color: #e96ac2;
	border-color: #e96ac2;
}

.red, .required {
	color: #fa5c5d;
}

.black {
	color: #212225;
	border-color: #212225;
}

.grey {
	color: #fff;
}

.green {
	color: #50cc60;
}

.greylinks a {
	color: #989898;
}

.darklinks a {
	color: #292a2e;
}

.lightlink {
	color: #ffffff !important;
}

.lightlink:hover {
	color: #f96380 !important;
}

.invertlink {
	color: #50cc60 !important;
}

.invertlink:hover {
	color: #ffffff !important;
}

.thin {
	font-weight: 300;
}

.bold {
	font-weight: 700;
}

.quotesign {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
	line-height: 1;
}

.quotesign:before {
	font-size: 150px;
	font-family: 'PT Sans', sans-serif;
	content: '‘‘';
	position: absolute;
	left: 0;
	top: 0;
	line-height: 1;
}

.light_bg_color {
	background-color: #ffffff;
}

.main_bg_color {
	color: #ffffff;
	background-color: #f96380;
}

.main_bg_color2 {
	color: #ffffff;
	background-color: #e96ac2;
}

.dark_bg_color {
	color: #ffffff;
	background-color: #212225;
}

.dark_bg_color h1, .dark_bg_color h2, .dark_bg_color h3, .dark_bg_color h4, .dark_bg_color h5, .dark_bg_color h6 {
	color: #ffffff;
}

.darkgrey_bg_color {
	color: #ffffff;
	background-color: #292a2e;
}

.darkgrey_bg_color h1, .darkgrey_bg_color h2, .darkgrey_bg_color h3, .darkgrey_bg_color h4, .darkgrey_bg_color h5, .darkgrey_bg_color h6 {
	color: #ffffff;
}

/*dividers, margins and paddings*/
.divider_20 {
	margin: 20px 0;
}

.divider_30 {
	margin: 30px 0;
}

.divider_40 {
	margin: 40px 0;
}

.topmargin_0 {
	margin-top: 0 !important;
}

.topmargin_1 {
	margin-top: 1px;
}

.topmargin_10 {
	margin-top: 10px;
}

.topmargin_20 {
	margin-top: 20px;
}

.topmargin_30 {
	margin-top: 30px;
}

.topmargin_40 {
	margin-top: 40px;
}

.topmargin_60 {
	margin-top: 60px;
}

.bottommargin_0 {
	margin-bottom: 0;
}

.bottommargin_10 {
	margin-bottom: 10px;
}

.bottommargin_20 {
	margin-bottom: 20px;
}

.bottommargin_30 {
	margin-bottom: 30px;
}

.bottommargin_40 {
	margin-bottom: 40px;
}

.bottommargin_60 {
	margin-bottom: 60px;
}

.margin_0 {
	margin: 0;
}

.padding_0 {
	padding: 0;
}

.padding_10 {
	padding: 10px;
}

.padding_20 {
	padding: 20px;
}

.padding_30 {
	padding: 30px;
}

.padding_40 {
	padding: 40px;
}

.fontsize_16 {
	font-size: 16px;
}

.fontsize_18 {
	font-size: 18px;
}

.fontsize_20 {
	font-size: 20px;
}

.fontsize_24 {
	font-size: 24px;
}

.fontsize_32 {
	font-size: 32px;
}

.columns_padding_0 [class*="col-"] {
	padding: 0;
}

.columns_padding_1 [class*="col-"] {
	padding-left: 0;
	padding-right: 1px;
}

.columns_padding_2 [class*="col-"] {
	padding-left: 1px;
	padding-right: 1px;
}

.columns_padding_5 [class*="col-"] {
	padding-left: 5px;
	padding-right: 5px;
}

.columns_padding_0 > .container,
.columns_padding_2 > .container,
.columns_padding_1 > .container {
	padding-left: 30px;
	padding-right: 30px;
}

.columns_padding_0.table_section > .container {
	padding: 0;
}

.columns_padding_5 > .container {
	padding-left: 25px;
	padding-right: 25px;
}

.row.columns_padding_2 {
	margin-left: -1px;
	margin-right: -1px;
}

.row.columns_padding_2 > [class*="col-"] {
	padding-left: 1px;
	padding-right: 1px;
}

.row.columns_padding_5 {
	margin-left: -5px;
	margin-right: -5px;
}

@media (min-width: 500px) {
	.columns_padding_25 > [class*="container"] > .row {
		margin-right: -25px;
		margin-left: -25px;
	}
	.columns_padding_25 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 25px;
		padding-right: 25px;
	}
}

/*alignment*/
.display_table {
	display: table;
	min-width: 100%;
	height: 100%;
	margin: auto;
}

.display_table_cell {
	display: table-cell;
	vertical-align: middle;
	float: none;
	overflow: hidden;
	zoom: 1;
}

@media (min-width: 1200px) {
	.text-lg-right {
		text-align: right;
	}
	.text-lg-left {
		text-align: left;
	}
	.text-lg-center {
		text-align: center;
	}
	.display_table_lg {
		display: table;
		min-width: 100%;
		height: 100%;
		margin: auto;
	}
	.display_table_cell_lg {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
	}
}

@media (min-width: 992px) {
	.text-md-right {
		text-align: right;
	}
	.text-md-left {
		text-align: left;
	}
	.text-md-center {
		text-align: center;
	}
	.display_inline_md {
		display: inline;
	}
	.display_table_md {
		display: table;
		min-width: 100%;
		height: 100%;
		margin: auto;
	}
	.display_table_cell_md {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
	}
}

@media (min-width: 768px) {
	.text-sm-right {
		text-align: right;
	}
	.text-sm-left {
		text-align: left;
	}
	.text-sm-center {
		text-align: center;
	}
	.display_table_sm {
		display: table;
		min-width: 100%;
		height: 100%;
		margin: auto;
	}
	.display_table_cell_sm {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
	}
}

@media (max-width: 767px) {
	.text-xs-right {
		text-align: right;
	}
	.text-xs-left {
		text-align: left;
	}
	.text-xs-center {
		text-align: center;
	}
	.not_found {
		font-size: 130px;
	}
}

@media (max-width: 500px) {
	.hidden-xxs {
		display: none;
	}
}

.inline-dropdown {
	list-style: none;
	margin: 0;
	padding: 0;
}

.inline-dropdown > li {
	display: inline-block;
}

.small-text {
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

/* common side and vertical items */
.with_border .item-media {
	margin: -1px -1px -1px -1px;
	z-index: 1;
}

.side-item h2, .side-item h3, .side-item h4 {
	margin: 0;
}

.side-item .row {
	margin: 0;
}

.side-item [class*="col-"] {
	padding: 0;
}

.side-item .item-content h4 {
	padding-bottom: 20px;
}

.side-item.content-padding .item-content {
	padding-top: 27px;
	padding-bottom: 17px;
	padding-left: 30px !important;
	padding-right: 30px !important;
}

.side-item.full-padding {
	padding: 30px 30px 30px 30px;
}

.side-item [class*="col-"] + [class*="col-"] {
	margin-top: 0 !important;
}

.side-item.with_border [class*="col-"] > .item-title {
	margin-right: -1px;
	margin-left: -1px;
}

/*-md- paddings and margins*/
@media (max-width: 991px) {
	.side-item [class*="-md-"] .item-content {
		padding-top: 27px;
	}
}

@media (min-width: 992px) {
	.side-item [class*="-md-"] .item-content {
		padding-left: 30px;
	}
	.side-item [class*="-md-"][class*="pull-"] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}
	.side-item.with_border [class*="-md-"] > .item-title {
		margin-top: -1px;
	}
}

/*-sm- paddings and margins*/
@media (max-width: 767px) {
	.side-item [class*="-sm-"] .item-content {
		padding-top: 27px;
	}
}

@media (min-width: 768px) {
	.side-item [class*="-sm-"] .item-content {
		padding-left: 30px;
	}
	.side-item [class*="-sm-"][class*="pull-"] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}
	.side-item.with_border [class*="-sm-"] > .item-title {
		margin-top: -1px;
	}
}

.item-title h2, .item-title h3, .item-title h4 {
	margin: 0;
	padding: 30px;
}

.vertical-item.full-padding {
	padding: 30px 30px 30px 30px;
}

.vertical-item.content-padding .item-content {
	padding: 0 30px 20px;
}

.vertical-item.content-absolute {
	position: relative;
}

.vertical-item.content-absolute .item-content {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 30px 10px;
}

.vertical-item.content-absolute.vertical-center .item-content {
	top: 0;
	line-height: 1.1;
}

/* item meta */
.item-meta {
	margin-bottom: 10px;
}

.item-meta i {
	position: relative;
	font-size: 20px;
	top: 3px;
	padding: 0;
	margin: 0 !important;
}

[class*="item"]:hover .links-wrap a {
	top: 0px;
	filter: alpha(opacity=100);
	opacity: 1;
}

[class*="item"]:hover .media-links div {
	filter: alpha(opacity=100);
	opacity: 1;
}

/* item icons */
.item-icons {
	display: table;
	width: 100%;
	border-top: 1px solid rgba(128, 128, 128, 0.1);
}

.item-icons i {
	display: block;
	font-size: 16px;
}

.item-icons > div {
	padding: 22px 10px 18px;
	display: table-cell;
}

.item-icons > div + div {
	border-left: 1px solid rgba(128, 128, 128, 0.1);
}

.bottom-border {
	position: relative;
}

.bottom-border:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
	background-color: rgba(0, 0, 0, 0.1);
}

.block-header {
	text-transform: uppercase;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 0.1em;
	line-height: 1;
	padding-bottom: 9px;
}

.block-header .highlight {
	position: relative;
}

.block-header .highlight:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: -11px;
	height: 3px;
	z-index: 2;
	background-color: #f96380;
}

/* item media links */
.item-media {
	position: relative;
	overflow: hidden;
}

.media-links {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.isotope-item .media-links {
	padding: 25px 40px;
}

.isotope-item .media-links div {
	position: absolute;
	z-index: 4;
}

.isotope-item:hover .media-links .btn-type2 {
	filter: alpha(opacity=100);
	opacity: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.isotope-item .media-links:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #292a2e;
	filter: alpha(opacity=80);
	opacity: 0.8;
}

.isotope-item .btn-type2 {
	color: #ffffff;
	margin: 0;
	height: 50px;
	filter: alpha(opacity=0);
	opacity: 0;
	transition: all 0.6s;
	-moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

.isotope-item .btn-type2:before {
	color: #f96380;
}

.isotope-item .btn-type2.like-btn, .isotope-item .btn-type2.comment-btn {
	background-image: none;
	left: 100px;
}

.isotope-item .btn-type2.like-btn:hover:before, .isotope-item .btn-type2.comment-btn:hover:before {
	color: #ffffff;
}

.isotope-item .btn-type2.add-btn {
	height: 60px;
	margin-top: 35px;
	top: 100px;
}

.isotope-item .btn-type2.add-btn:before {
	color: #ffffff;
}

.isotope-item .btn-type2.add-btn:hover:before {
	color: #f96380;
}

.isotope-item .btn-type2.date-btn {
	top: -100px;
}

.isotope-item .btn-type2.comment-btn {
	left: -100px;
}

@media (min-width: 1590px) {
	.isotope-item .media-links {
		padding: 0 40px;
	}
}

@media (min-width: 1200px) and (max-width: 1350px) {
	.isotope-item .btn-type2.date-btn {
		display: none;
	}
	.vertical-item .isotope-item .btn-type2.add-btn {
		margin-top: 20px;
	}
}

@media (min-width: 768px) and (max-width: 1589px) {
	.isotope-item .media-links {
		padding: 0 40px;
	}
	.isotope-item .btn-type2 {
		height: 50px;
	}
	.isotope-item .btn-type2.add-btn {
		height: 60px;
		margin-top: 10px;
	}
}

@media (max-width: 480px) {
	.isotope-item .media-links {
		padding: 5px 20px;
	}
}

@media (max-width: 480px) and (max-width: 380px) {
	.isotope-item .media-links {
		padding: 0px 20px;
	}
}

@media (max-width: 480px) and (max-width: 380px) {
	.isotope-item .links-wrap .btn-type2 {
		height: 40px;
	}
}

@media (max-width: 480px) {
	.isotope-item .links-wrap .btn-type2.add-btn {
		height: 60px;
		margin-top: 10px;
	}
}

/*
**plugins
*/
.owl-carousel {
	position: relative;
	margin-bottom: 50px;
}

.owl-carousel:hover .owl-nav > div {
	top: 50%;
	opacity: 1;
	filter: alpha(opacity=100);
}

.owl-carousel .owl-nav {
	position: absolute;
	top: -5%;
	opacity: 0;
	filter: alpha(opacity=0);
	margin-top: -30px;
	right: 0;
	left: 0;
	-webkit-transition: all 0.6s ease 0s;
	-moz-transition: all 0.6s ease 0s;
	-o-transition: all 0.6s ease 0s;
	-ms-transition: all 0.6s ease 0s;
	transition: all 0.6s ease 0s;
}

.owl-carousel .owl-nav > div {
	color: transparent;
	font-size: 0;
	position: absolute;
	right: 10px;
	text-align: center;
	width: 60px;
	height: 60px;
	background-color: #ffffff;
	opacity: 0;
	filter: alpha(opacity=0);
}

.owl-carousel .owl-nav > div:first-child {
	left: 10px;
	right: auto;
}

.owl-carousel .owl-nav > div:after {
	font-family: 'rt-icons-2';
	font-size: 20px;
	line-height: 60px;
	content: "\e7c5";
	color: #292a2e;
}

.owl-carousel .owl-nav > div:first-child:after {
	content: '\e7c4';
}

.owl-carousel .owl-nav > div:hover:after {
	color: #f96380;
}

.owl-carousel.owl-center .owl-nav {
	top: 0;
	bottom: 0;
	position: static;
	margin-top: 0;
}

.owl-carousel.owl-center .owl-nav > div {
	right: -15px;
	width: 50px;
	top: 0;
	bottom: 0;
	background-color: rgba(41, 42, 46, 0.6);
}

.owl-carousel.owl-center .owl-nav > div:first-child {
	left: -15px;
	right: auto;
}

.owl-carousel.owl-center .owl-nav > div:first-child:after {
	left: 16px;
}

.owl-carousel.owl-center .owl-nav > div:after {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	color: #ffffff;
	font-size: 20px;
	left: 21px;
}

/*
 * Owl Carousel CSS File
 *  v2.0
 */
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/*
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel .owl-item img {
	display: block;
	width: 100%;
	-webkit-transform-style: preserve-3d;
}

.owl-carousel.owl-text-select-on .owl-item {
	-webkit-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/*
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
	transform-style: preserve-3d;
}

.owl-theme .owl-dots {
	text-align: center;
	margin-top: 40px;
	-webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
	*display: inline;
}

.owl-theme .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 5px 6px;
	border: 2px solid #292a2e;
	display: block;
	-webkit-backface-visibility: visible;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
	border-color: #f96380;
}

.owl-custom-nav {
	text-align: center;
}

.owl-custom-nav [class*="custom"] {
	cursor: pointer;
	position: relative;
	bottom: 2px;
}

.owl-custom-nav > div {
	display: inline-block;
}

/* owl images fix */
.owl-carousel .owl-item {
	-webkit-transition: opacity 0.4s ease 0.2s;
	-moz-transition: opacity 0.4s ease 0.2s;
	-o-transition: opacity 0.4s ease 0.2s;
	-ms-transition: opacity 0.4s ease 0.2s;
	transition: opacity 0.4s ease 0.2s;
	opacity: 0;
}

.owl-carousel .owl-item.active {
	opacity: 1;
}

.owl-center .owl-item {
	opacity: 1;
}

/*
flexslider
*/
/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */
/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
	outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
	margin: 0;
	padding: 0;
}

.flexslider .slides > li {
	display: none;
	-webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
	width: 100%;
	display: block;
}

.flex-pauseplay span {
	text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .slides {
	display: block;
}

* html .slides {
	height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
	display: block;
}

/* FlexSlider Default Theme
*********************************/
.flexslider {
	margin: 0 0;
	position: relative;
	zoom: 1;
}

.flex-viewport {
	max-height: 2000px;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}

.loading .flex-viewport {
	max-height: 300px;
}

.flexslider .slides {
	zoom: 1;
}

/*.carousel li { margin-right: 5px; }*/
/* Direction Nav */
.flex-direction-nav {
	*height: 0;
}

.flex-direction-nav a {
	text-decoration: none;
	display: block;
	width: 40px;
	height: 40px;
	margin: -20px 0 0;
	position: absolute;
	top: 50%;
	z-index: 10;
	overflow: hidden;
	opacity: 0;
	cursor: pointer;
	font-size: 0;
	color: transparent;
	text-align: center;
}

.flex-direction-nav .flex-prev {
	left: 40px;
}

.flex-direction-nav .flex-next {
	right: 40px;
	text-align: right;
}

.flexslider:hover .flex-prev {
	opacity: 0.7;
	left: 40px;
}

.flexslider:hover .flex-next {
	opacity: 0.7;
	right: 40px;
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
	opacity: 1;
}

.flex-direction-nav .flex-disabled {
	opacity: 0 !important;
	filter: alpha(opacity=0);
	cursor: default;
}

.flex-direction-nav a:before {
	font-family: "fontello";
	font-size: 40px;
	content: '\e833';
	color: #ffffff;
	position: absolute;
	top: 5px;
	left: 12px;
}

.flex-direction-nav a.flex-next:before {
	content: '\e834';
	left: 14px;
}

/* Pause/Play */
.flex-pauseplay a {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: 5px;
	left: 10px;
	opacity: 0.8;
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	color: #000;
}

.flex-pauseplay a:before {
	font-family: 'rt-icons-2';
	font-size: 20px;
	display: inline-block;
	content: "\e6a2";
}

.flex-pauseplay a:hover {
	opacity: 1;
}

.flex-pauseplay a.flex-play:before {
	content: "\e711";
}

/* Control Nav */
.flex-control-nav {
	position: absolute;
	left: 55px;
	top: 50%;
	margin-top: -48px;
	text-align: center;
	z-index: 10;
}

.flex-control-nav li {
	text-align: center;
	display: block;
	padding: 10px 0;
	zoom: 1;
	*display: inline;
}

.flex-control-paging li a {
	width: 12px;
	height: 12px;
	display: block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 0;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: transparent;
	cursor: pointer;
	/* style */
}

.ds .flex-control-paging li a,
.cs .flex-control-paging li a {
	border-color: #ffffff;
}

.flex-control-paging li a:before {
	content: "\f10c";
	color: #ffffff;
	font-size: 12px;
}

.flex-control-paging li a:hover:before {
	color: #f96380;
	text-shadow: -1px -1px 0 #000,
 1px -1px 0 #000,
 -1px 1px 0 #000,
 1px 1px 0 #000;
}

.flex-control-paging li a.flex-active:before {
	color: #50cc60;
}

.flex-control-thumbs {
	margin: 5px 0 0;
	position: static;
	overflow: hidden;
}

.flex-control-thumbs li {
	width: 25%;
	float: left;
	margin: 0;
}

.flex-control-thumbs img {
	width: 100%;
	display: block;
	opacity: .7;
	cursor: pointer;
}

.flex-control-thumbs img:hover {
	opacity: 1;
}

.flex-control-thumbs .flex-active {
	opacity: 1;
	cursor: default;
}

@media screen and (max-width: 600px) {
	.flex-direction-nav .flex-prev {
		top: 14%;
	}
	.flex-direction-nav .flex-next {
		top: 14%;
	}
}

/* custom nav for flex slider*/
.flex-custom-nav {
	text-align: center;
	position: absolute;
	bottom: 38px;
	right: 0;
	left: 0;
	z-index: 3;
	line-height: 1;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.flex-custom-nav .flex-control-nav {
	width: auto;
	display: inline-block;
	position: static;
}

.flex-custom-nav .flex-custom-link {
	font-size: 12px;
	position: relative;
	bottom: 7px;
	margin: 0 8px;
	color: #292a2e;
}

.ds .flex-custom-nav .flex-custom-link,
.cs .flex-custom-nav .flex-custom-link {
	color: #ffffff;
}

.flex-custom-nav .flex-custom-link:hover {
	color: #f96380;
}

.intro_section:hover .flex-custom-nav {
	opacity: 1;
}

/*
prettyPhoto
*/
div.facebook .pp_expand {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;
	cursor: pointer;
}

div.facebook .pp_expand:hover {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;
	cursor: pointer;
}

div.facebook .pp_contract {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;
	cursor: pointer;
}

div.facebook .pp_contract:hover {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;
	cursor: pointer;
}

div.facebook .pp_close {
	width: 22px;
	height: 22px;
	background: url(/themes/bach/images/system/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;
	cursor: pointer;
	z-index: 3000;
}

div.facebook .pp_description {
	margin: 0 37px 0 0;
}

div.facebook .pp_loaderIcon {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/loader.gif) center center no-repeat;
}

div.facebook .pp_arrow_previous {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;
	height: 22px;
	margin-top: 0;
	width: 22px;
}

div.facebook .pp_arrow_previous.disabled {
	background-position: 0 -96px;
	cursor: default;
}

div.facebook .pp_arrow_next {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;
	height: 22px;
	margin-top: 0;
	width: 22px;
}

div.facebook .pp_arrow_next.disabled {
	background-position: -32px -96px;
	cursor: default;
}

div.facebook .pp_nav {
	margin-top: 0;
}

div.facebook .pp_nav p {
	font-size: 15px;
	padding: 0 3px 0 4px;
	margin-top: -2px;
}

div.facebook .pp_nav .pp_play {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;
	height: 22px;
	width: 22px;
}

div.facebook .pp_nav .pp_pause {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;
	height: 22px;
	width: 22px;
}

div.facebook .pp_next:hover {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/btnNext.png) center right no-repeat;
	cursor: pointer;
}

div.facebook .pp_previous:hover {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/btnPrevious.png) center left no-repeat;
	cursor: pointer;
}

div.pp_pic_holder a:focus {
	outline: none;
}

div.pp_overlay {
	background: #000;
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9500;
}

div.pp_pic_holder {
	display: none;
	position: absolute;
	width: 100px;
	z-index: 10000;
}

.pp_content {
	height: 40px;
	min-width: 40px;
	overflow: hidden;
}

* html .pp_content {
	width: 40px;
}

.pp_content_container {
	position: relative;
	text-align: left;
	width: 100%;
}

.pp_content_container .pp_left {
	padding-left: 20px;
}

.pp_content_container .pp_right {
	padding-right: 20px;
}

.pp_content_container .pp_details {
	float: left;
	/*margin:10px 0 2px;*/
	padding: 7px 10px;
	background-color: #ffffff;
}

.pp_description {
	display: none;
	margin: 0;
}

.pp_social {
	float: left;
	margin: 0;
}

.pp_social .facebook {
	float: left;
	margin-left: 5px;
	width: auto;
	overflow: hidden;
}

.pp_social .twitter {
	float: left;
}

.pp_nav {
	clear: right;
	float: left;
	margin: 3px 10px 0 0;
}

.pp_nav p {
	float: left;
	white-space: nowrap;
	margin: 2px 4px;
}

.pp_nav .pp_play, .pp_nav .pp_pause {
	float: left;
	margin-right: 4px;
	text-indent: -10000px;
}

a.pp_arrow_previous, a.pp_arrow_next {
	display: block;
	float: left;
	height: 15px;
	margin-top: 3px;
	overflow: hidden;
	text-indent: -10000px;
	width: 14px;
}

.pp_hoverContainer {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2000;
}

.pp_gallery {
	display: none;
	left: 50%;
	margin-top: -50px;
	position: absolute;
	z-index: 10000;
}

.pp_gallery div {
	float: left;
	overflow: hidden;
	position: relative;
}

.pp_gallery ul {
	float: left;
	height: 35px;
	position: relative;
	white-space: nowrap;
	margin: 0 0 0 5px;
	padding: 0;
}

.pp_gallery ul a {
	border: 1px rgba(0, 0, 0, 0.5) solid;
	display: block;
	float: left;
	height: 33px;
	overflow: hidden;
}

.pp_gallery ul a img {
	border: 0;
}

.pp_gallery li {
	display: block;
	float: left;
	margin: 0 5px 0 0;
	padding: 0;
}

.pp_gallery li.default a {
	background: url(/themes/bach/images/system/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
	display: block;
	height: 33px;
	width: 50px;
}

.pp_gallery .pp_arrow_previous, .pp_gallery .pp_arrow_next {
	margin-top: 7px !important;
}

a.pp_next {
	background: url(/themes/bach/images/system/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
	display: block;
	float: right;
	height: 100%;
	text-indent: -10000px;
	width: 49%;
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	-ms-transition: none;
	transition: none;
}

a.pp_previous {
	background: url(/themes/bach/images/system/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
	display: block;
	float: left;
	height: 100%;
	text-indent: -10000px;
	width: 49%;
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	-ms-transition: none;
	transition: none;
}

a.pp_expand, a.pp_contract {
	cursor: pointer;
	display: none;
	height: 20px;
	position: absolute;
	right: 30px;
	text-indent: -10000px;
	top: 10px;
	width: 20px;
	z-index: 20000;
}

a.pp_close {
	position: absolute;
	right: 6px;
	top: -16px;
	display: block;
	line-height: 22px;
	text-indent: -10000px;
}

.pp_loaderIcon {
	display: block;
	height: 24px;
	left: 50%;
	position: absolute;
	top: 50%;
	width: 24px;
	margin: -12px 0 0 -12px;
}

#pp_full_res {
	line-height: 1 !important;
}

#pp_full_res .pp_inline {
	text-align: left;
}

#pp_full_res .pp_inline p {
	margin: 0 0 15px;
}

div.ppt {
	color: #ffffff;
	display: none;
	font-size: 17px;
	z-index: 9999;
	margin: 0 0 5px 15px;
}

.pp_top, .pp_bottom {
	height: 20px;
	position: relative;
}

* html .pp_top, * html .pp_bottom {
	padding: 0 20px;
}

.pp_top .pp_left, .pp_bottom .pp_left {
	height: 20px;
	left: 0;
	position: absolute;
	width: 20px;
}

.pp_top .pp_middle, .pp_bottom .pp_middle {
	height: 20px;
	left: 20px;
	position: absolute;
	right: 20px;
}

* html .pp_top .pp_middle, * html .pp_bottom .pp_middle {
	left: 0;
	position: static;
}

.pp_top .pp_right, .pp_bottom .pp_right {
	height: 20px;
	left: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
}

.pp_fade, .pp_gallery li.default a img {
	display: none;
}

/*
easy pie chart
*/
.chart {
	position: relative;
	display: inline-block;
	width: 270px;
	height: 270px;
	margin-top: 20px;
	margin-bottom: 10px;
	text-align: center;
}

.chart canvas {
	position: absolute;
	top: 0;
	left: 0;
}

.percent {
	display: inline-block;
	padding-top: 103px;
	font-size: 70px;
	font-weight: 100;
	z-index: 2;
	letter-spacing: -0.05em;
}

.percent:after {
	content: '%';
}

.angular {
	margin-top: 100px;
}

.angular .chart {
	margin-top: 0;
}

/*
timetable
*/
#timetable span {
	display: block;
}

#timetable th, #timetable td {
	vertical-align: middle;
	font-weight: normal;
	text-align: center;
	padding: 25px 5px;
}

#timetable thead {
	color: #ffffff;
}

#timetable thead th {
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-width: 0 1px;
}

#timetable tbody th, #timetable tbody td {
	border: 1px solid rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

#timetable tbody .current {
	background-color: #f96380;
	border-color: #f96380;
}

#timetable tbody .current a {
	color: #ffffff;
}

/*
totop
*/
#toTop {
	bottom: 0;
	right: 0;
	display: none;
	width: 60px;
	height: 40px;
	overflow: hidden;
	position: fixed;
	text-decoration: none;
	z-index: 9999;
	font-size: 0;
	color: transparent;
	background-color: #50cc60;
	opacity: 0.7;
}

#toTop:after {
	color: #ffffff;
	content: '\f106';
	font-size: 24px;
	line-height: 40px;
	font-family: 'rt-icons-2';
	text-align: center;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

#toTopHover, #toTop:hover {
	bottom: 3px;
	opacity: 1;
}

#toTop:hover:after {
	color: #ffffff;
}

#toTop:active, #toTop:focus {
	outline: medium none;
}

.modal-open #toTop {
	display: none !important;
}

/* jQuery Countdown styles 2.0.2. */
#comingsoon-countdown {
	text-align: center;
}

#comingsoon-countdown:before,
#comingsoon-countdown:after {
	clear: both;
	display: table;
	content: ' ';
}

.countdown-rtl {
	direction: rtl;
}

.countdown-row {
	clear: both;
	display: table;
	margin: auto;
	padding: 20px 0;
	margin-bottom: 20px;
	text-align: center;
}

.countdown-section {
	display: table-cell;
	padding: 10px 30px 20px;
	text-align: center;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.countdown-section:first-child {
	border-left-width: 0;
}

.countdown-amount {
	font-size: 90px;
	line-height: 1;
	font-weight: 100;
	color: #f96380;
}

.countdown-period {
	display: block;
	font-size: 28px;
	font-weight: 100;
}

.countdown-descr {
	display: block;
}

@media (max-width: 767px) {
	.countdown-section {
		padding: 20px 8px 0;
	}
	.countdown-amount {
		font-size: 40px;
	}
	.countdown-period {
		font-size: 16px;
	}
}

/**
* Add to Any button
*/
.a2a_menu {
	border-radius: 0 !important;
}

.addtoany_list.a2a_kit_size_32 a > span {
	border-radius: 0 !important;
}

.post-adds .addtoany_list a {
	padding: 0;
	line-height: 0;
	position: relative;
}

.post-adds .addtoany_list a:before {
	content: "\e70a";
	font-family: 'rt-icons-2';
	font-size: 18px;
	color: #ffffff;
	position: absolute;
	top: 18px;
	left: 10px;
}

.post-adds .a2a_s_a2a {
	background: #292a2e;
	width: 40px !important;
	height: 40px !important;
}

.post-adds .addtoany_list a:hover span {
	opacity: 1;
}

.post-adds .a2a_kit + span {
	margin-left: -4px;
}

/*
**icons
*/
a.social-icon {
	display: inline-block;
	line-height: 1;
	text-align: center;
	vertical-align: middle;
}

a.social-icon span {
	opacity: 0.7;
	display: block;
	font-size: 14px;
}

a.social-icon span:first-child {
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon [class*="rt-icon"] + span {
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon + a.social-icon,
.tooltip + a.social-icon {
	margin-left: 16px;
}

a.social-icon.bg-icon + a.social-icon.bg-icon {
	margin-left: 7px;
}

a.social-icon.color-bg-icon + a.social-icon.color-bg-icon {
	margin-left: 1px;
}

a.social-icon.block-icon {
	width: 100%;
}

a.social-icon {
	color: #989898;
}

a[class*='soc-']:before {
	font-family: 'socicon';
	font-size: 16px;
}

a.border-icon {
	border: 1px solid #989898;
	border-color: rgba(152, 152, 152, 0.4);
	padding: 10px 11px;
	margin-bottom: 4px;
}

a.bg-icon {
	color: #ffffff;
	background-color: #989898;
	padding: 11px 12px;
	margin-bottom: 4px;
}

a.img-bg-icon {
	color: #ffffff;
	padding: 7px 7px 6px 7px;
	margin-bottom: 4px;
	background-image: url("/themes/bach/images/system/new/bg30-1.jpg");
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

a.img-bg-icon:hover {
	color: #ffffff !important;
	background-image: url("/themes/bach/images/system/bg-hover.jpg");
}

a.bg-icon:hover {
	color: #ffffff !important;
}

a.color-bg-icon {
	color: #ffffff;
	background-color: #292a2e;
	margin-bottom: 4px;
	padding: 11px 12px;
}

a.color-bg-icon:hover {
	background-color: #50cc60;
	color: #ffffff !important;
}

a.color-icon:hover {
	color: #292a2e !important;
}

a.color-icon.border-icon:hover {
	color: #ffffff !important;
}

a[class*='soc-'].rounded-icon {
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

a.big-icon {
	width: 116px;
	height: 116px;
	padding-top: 20px;
	padding-bottom: 20px;
	overflow: hidden;
	border-radius: 10px;
}

a.big-icon[class*='soc-']:before {
	font-size: 32px;
}

a.big-icon [class*='rt-icon']:before {
	font-size: 32px;
}

/*corporate colors*/
a.color-icon.soc-twitter, a[class*="soc-"].soc-twitter:hover {
	color: #00abef;
	border-color: #00abef;
}

a.color-icon.soc-facebook, a[class*="soc-"].soc-facebook:hover {
	color: #507cbe;
	border-color: #507cbe;
}

a.color-icon.soc-google, a[class*="soc-"].soc-google:hover {
	color: #d93e2d;
	border-color: #d93e2d;
}

a.color-icon.soc-pinterest, a[class*="soc-"].soc-pinterest:hover {
	color: #c92619;
	border-color: #c92619;
}

a.color-icon.soc-foursquare, a[class*="soc-"].soc-foursquare:hover {
	color: #23b7e0;
	border-color: #23b7e0;
}

a.color-icon.soc-yahoo, a[class*="soc-"].soc-yahoo:hover {
	color: #6e2a85;
	border-color: #6e2a85;
}

a.color-icon.soc-skype, a[class*="soc-"].soc-skype:hover {
	color: #28abe3;
	border-color: #28abe3;
}

a.color-icon.soc-yelp, a[class*="soc-"].soc-yelp:hover {
	color: #c83218;
	border-color: #c83218;
}

a.color-icon.soc-feedburner, a[class*="soc-"].soc-feedburner:hover {
	color: #ffcc00;
	border-color: #ffcc00;
}

a.color-icon.soc-linkedin, a[class*="soc-"].soc-linkedin:hover {
	color: #3371b7;
	border-color: #3371b7;
}

a.color-icon.soc-viadeo, a[class*="soc-"].soc-viadeo:hover {
	color: #e4a000;
	border-color: #e4a000;
}

a.color-icon.soc-xing, a[class*="soc-"].soc-xing:hover {
	color: #005a60;
	border-color: #005a60;
}

a.color-icon.soc-myspace, a[class*="soc-"].soc-myspace:hover {
	color: #323232;
	border-color: #323232;
}

a.color-icon.soc-soundcloud, a[class*="soc-"].soc-soundcloud:hover {
	color: #fe3801;
	border-color: #fe3801;
}

a.color-icon.soc-spotify, a[class*="soc-"].soc-spotify:hover {
	color: #7bb342;
	border-color: #7bb342;
}

a.color-icon.soc-grooveshark, a[class*="soc-"].soc-grooveshark:hover {
	color: #f67e03;
	border-color: #f67e03;
}

a.color-icon.soc-lastfm, a[class*="soc-"].soc-lastfm:hover {
	color: #d41316;
	border-color: #d41316;
}

a.color-icon.soc-youtube, a[class*="soc-"].soc-youtube:hover {
	color: #d12121;
	border-color: #d12121;
}

a.color-icon.soc-vimeo, a[class*="soc-"].soc-vimeo:hover {
	color: #51b5e7;
	border-color: #51b5e7;
}

a.color-icon.soc-dailymotion, a[class*="soc-"].soc-dailymotion:hover {
	color: #004e72;
	border-color: #004e72;
}

a.color-icon.soc-vine, a[class*="soc-"].soc-vine:hover {
	color: #00b389;
	border-color: #00b389;
}

a.color-icon.soc-flickr, a[class*="soc-"].soc-flickr:hover {
	color: #0062dd;
	border-color: #0062dd;
}

a.color-icon.soc-500px, a[class*="soc-"].soc-500px:hover {
	color: #58a9de;
	border-color: #58a9de;
}

a.color-icon.soc-instagram, a[class*="soc-"].soc-instagram:hover {
	color: #9c7c6e;
	border-color: #9c7c6e;
}

a.color-icon.soc-wordpress, a[class*="soc-"].soc-wordpress:hover {
	color: #464646;
	border-color: #464646;
}

a.color-icon.soc-tumblr, a[class*="soc-"].soc-tumblr:hover {
	color: #45556c;
	border-color: #45556c;
}

a.color-icon.soc-blogger, a[class*="soc-"].soc-blogger:hover {
	color: #ec661c;
	border-color: #ec661c;
}

a.color-icon.soc-technorati, a[class*="soc-"].soc-technorati:hover {
	color: #5cb030;
	border-color: #5cb030;
}

a.color-icon.soc-reddit, a[class*="soc-"].soc-reddit:hover {
	color: #e74a1e;
	border-color: #e74a1e;
}

a.color-icon.soc-dribbble, a[class*="soc-"].soc-dribbble:hover {
	color: #e84d88;
	border-color: #e84d88;
}

a.color-icon.soc-stumbleupon, a[class*="soc-"].soc-stumbleupon:hover {
	color: #e64011;
	border-color: #e64011;
}

a.color-icon.soc-digg, a[class*="soc-"].soc-digg:hover {
	color: #1d1d1b;
	border-color: #1d1d1b;
}

a.color-icon.soc-envato, a[class*="soc-"].soc-envato:hover {
	color: #597c3a;
	border-color: #597c3a;
}

a.color-icon.soc-behance, a[class*="soc-"].soc-behance:hover {
	color: #007eff;
	border-color: #007eff;
}

a.color-icon.soc-delicious, a[class*="soc-"].soc-delicious:hover {
	color: #31a9ff;
	border-color: #31a9ff;
}

a.color-icon.soc-deviantart, a[class*="soc-"].soc-deviantart:hover {
	color: #c5d200;
	border-color: #c5d200;
}

a.color-icon.soc-forrst, a[class*="soc-"].soc-forrst:hover {
	color: #5b9a68;
	border-color: #5b9a68;
}

a.color-icon.soc-play, a[class*="soc-"].soc-play:hover {
	color: #5acdbd;
	border-color: #5acdbd;
}

a.color-icon.soc-zerply, a[class*="soc-"].soc-zerply:hover {
	color: #9dbc7a;
	border-color: #9dbc7a;
}

a.color-icon.soc-wikipedia, a[class*="soc-"].soc-wikipedia:hover {
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-apple, a[class*="soc-"].soc-apple:hover {
	color: #b9bfc1;
	border-color: #b9bfc1;
}

a.color-icon.soc-flattr, a[class*="soc-"].soc-flattr:hover {
	color: #f67c1a;
	border-color: #f67c1a;
}

a.color-icon.soc-github, a[class*="soc-"].soc-github:hover {
	color: #221e1b;
	border-color: #221e1b;
}

a.color-icon.soc-chimein, a[class*="soc-"].soc-chimein:hover {
	color: #888688;
	border-color: #888688;
}

a.color-icon.soc-friendfeed, a[class*="soc-"].soc-friendfeed:hover {
	color: #2f72c4;
	border-color: #2f72c4;
}

a.color-icon.soc-newsvine, a[class*="soc-"].soc-newsvine:hover {
	color: #075b2f;
	border-color: #075b2f;
}

a.color-icon.soc-identica, a[class*="soc-"].soc-identica:hover {
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-bebo, a[class*="soc-"].soc-bebo:hover {
	color: #ef1011;
	border-color: #ef1011;
}

a.color-icon.soc-zynga, a[class*="soc-"].soc-zynga:hover {
	color: #dc0606;
	border-color: #dc0606;
}

a.color-icon.soc-steam, a[class*="soc-"].soc-steam:hover {
	color: #8f8d8a;
	border-color: #8f8d8a;
}

a.color-icon.soc-xbox, a[class*="soc-"].soc-xbox:hover {
	color: #92c83e;
	border-color: #92c83e;
}

a.color-icon.soc-windows, a[class*="soc-"].soc-windows:hover {
	color: #00bdf6;
	border-color: #00bdf6;
}

a.color-icon.soc-outlook, a[class*="soc-"].soc-outlook:hover {
	color: #0072c6;
	border-color: #0072c6;
}

a.color-icon.soc-coderwall, a[class*="soc-"].soc-coderwall:hover {
	color: #3e8dcc;
	border-color: #3e8dcc;
}

a.color-icon.soc-tripadvisor, a[class*="soc-"].soc-tripadvisor:hover {
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-appnet, a[class*="soc-"].soc-appnet:hover {
	color: #494949;
	border-color: #494949;
}

a.color-icon.soc-goodreads, a[class*="soc-"].soc-goodreads:hover {
	color: #1abc9c;
	border-color: #1abc9c;
}

a.color-icon.soc-tripit, a[class*="soc-"].soc-tripit:hover {
	color: #1982c3;
	border-color: #1982c3;
}

a.color-icon.soc-lanyrd, a[class*="soc-"].soc-lanyrd:hover {
	color: #3c80c9;
	border-color: #3c80c9;
}

a.color-icon.soc-slideshare, a[class*="soc-"].soc-slideshare:hover {
	color: #4ba3a6;
	border-color: #4ba3a6;
}

a.color-icon.soc-buffer, a[class*="soc-"].soc-buffer:hover {
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-rss, a[class*="soc-"].soc-rss:hover {
	color: #f26109;
	border-color: #f26109;
}

a.color-icon.soc-vkontakte, a[class*="soc-"].soc-vkontakte:hover {
	color: #5a7fa6;
	border-color: #5a7fa6;
}

a.color-icon.soc-disqus, a[class*="soc-"].soc-disqus:hover {
	color: #2e9fff;
	border-color: #2e9fff;
}

a.color-icon.soc-houzz, a[class*="soc-"].soc-houzz:hover {
	color: #7cc04b;
	border-color: #7cc04b;
}

a.color-icon.soc-mail, a[class*="soc-"].soc-mail:hover {
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-patreon, a[class*="soc-"].soc-patreon:hover {
	color: #e44727;
	border-color: #e44727;
}

a.color-icon.soc-paypal, a[class*="soc-"].soc-paypal:hover {
	color: #009cde;
	border-color: #009cde;
}

a.color-icon.soc-playstation, a[class*="soc-"].soc-playstation:hover {
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-smugmug, a[class*="soc-"].soc-smugmug:hover {
	color: #acfd32;
	border-color: #acfd32;
}

a.color-icon.soc-swarm, a[class*="soc-"].soc-swarm:hover {
	color: #fc9d3c;
	border-color: #fc9d3c;
}

a.color-icon.soc-triplej, a[class*="soc-"].soc-triplej:hover {
	color: #e53531;
	border-color: #e53531;
}

a.color-icon.soc-yammer, a[class*="soc-"].soc-yammer:hover {
	color: #1175c4;
	border-color: #1175c4;
}

a.color-icon.soc-stackoverflow, a[class*="soc-"].soc-stackoverflow:hover {
	color: #fd9827;
	border-color: #fd9827;
}

a.color-icon.soc-drupal, a[class*="soc-"].soc-drupal:hover {
	color: #00598e;
	border-color: #00598e;
}

a.color-icon.soc-odnoklassniki, a[class*="soc-"].soc-odnoklassniki:hover {
	color: #f48420;
	border-color: #f48420;
}

a.color-icon.soc-android, a[class*="soc-"].soc-android:hover {
	color: #8ec047;
	border-color: #8ec047;
}

a.color-icon.soc-meetup, a[class*="soc-"].soc-meetup:hover {
	color: #e2373c;
	border-color: #e2373c;
}

a.color-icon.soc-persona, a[class*="soc-"].soc-persona:hover {
	color: #e6753d;
	border-color: #e6753d;
}

a.color-icon.soc-amazon, a[class*="soc-"].soc-amazon:hover {
	color: #ff9900;
	border-color: #ff9900;
}

a.color-icon.soc-ello, a[class*="soc-"].soc-ello:hover {
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-mixcloud, a[class*="soc-"].soc-mixcloud:hover {
	color: #314359;
	border-color: #314359;
}

a.color-icon.soc-8tracks, a[class*="soc-"].soc-8tracks:hover {
	color: #122c4b;
	border-color: #122c4b;
}

a.color-icon.soc-twitch, a[class*="soc-"].soc-twitch:hover {
	color: #6441a5;
	border-color: #6441a5;
}

a.color-icon.soc-airbnb, a[class*="soc-"].soc-airbnb:hover {
	color: #ff5a5f;
	border-color: #ff5a5f;
}

a.color-icon.soc-pocket, a[class*="soc-"].soc-pocket:hover {
	color: #ed4055;
	border-color: #ed4055;
}

/*corporate background colors*/
.color-bg-icon {
	color: #ffffff;
}

.color-bg-icon.soc-twitter, a.soc-twitter.bg-icon:hover, .soc-twitter.color-icon.border-icon:hover {
	background-color: #00abef;
}

.color-bg-icon.soc-facebook, a.soc-facebook.bg-icon:hover, .soc-facebook.color-icon.border-icon:hover {
	background-color: #507cbe;
}

.color-bg-icon.soc-google, a.soc-google.bg-icon:hover, .soc-google.color-icon.border-icon:hover {
	background-color: #d93e2d;
}

.color-bg-icon.soc-pinterest, a.soc-pinterest.bg-icon:hover, .soc-pinterest.color-icon.border-icon:hover {
	background-color: #c92619;
}

.color-bg-icon.soc-foursquare, a.soc-foursquare.bg-icon:hover, .soc-foursquare.color-icon.border-icon:hover {
	background-color: #23b7e0;
}

.color-bg-icon.soc-yahoo, a.soc-yahoo.bg-icon:hover, .soc-yahoo.color-icon.border-icon:hover {
	background-color: #6e2a85;
}

.color-bg-icon.soc-skype, a.soc-skype.bg-icon:hover, .soc-skype.color-icon.border-icon:hover {
	background-color: #28abe3;
}

.color-bg-icon.soc-yelp, a.soc-yelp.bg-icon:hover, .soc-yelp.color-icon.border-icon:hover {
	background-color: #c83218;
}

.color-bg-icon.soc-feedburner, a.soc-feedburner.bg-icon:hover, .soc-feedburner.color-icon.border-icon:hover {
	background-color: #ffcc00;
}

.color-bg-icon.soc-linkedin, a.soc-linkedin.bg-icon:hover, .soc-linkedin.color-icon.border-icon:hover {
	background-color: #3371b7;
}

.color-bg-icon.soc-viadeo, a.soc-viadeo.bg-icon:hover, .soc-viadeo.color-icon.border-icon:hover {
	background-color: #e4a000;
}

.color-bg-icon.soc-xing, a.soc-xing.bg-icon:hover, .soc-xing.color-icon.border-icon:hover {
	background-color: #005a60;
}

.color-bg-icon.soc-myspace, a.soc-myspace.bg-icon:hover, .soc-myspace.color-icon.border-icon:hover {
	background-color: #323232;
}

.color-bg-icon.soc-soundcloud, a.soc-soundcloud.bg-icon:hover, .soc-soundcloud.color-icon.border-icon:hover {
	background-color: #fe3801;
}

.color-bg-icon.soc-spotify, a.soc-spotify.bg-icon:hover, .soc-spotify.color-icon.border-icon:hover {
	background-color: #7bb342;
}

.color-bg-icon.soc-grooveshark, a.soc-grooveshark.bg-icon:hover, .soc-grooveshark.color-icon.border-icon:hover {
	background-color: #f67e03;
}

.color-bg-icon.soc-lastfm, a.soc-lastfm.bg-icon:hover, .soc-lastfm.color-icon.border-icon:hover {
	background-color: #d41316;
}

.color-bg-icon.soc-youtube, a.soc-youtube.bg-icon:hover, .soc-youtube.color-icon.border-icon:hover {
	background-color: #d12121;
}

.color-bg-icon.soc-vimeo, a.soc-vimeo.bg-icon:hover, .soc-vimeo.color-icon.border-icon:hover {
	background-color: #51b5e7;
}

.color-bg-icon.soc-dailymotion, a.soc-dailymotion.bg-icon:hover, .soc-dailymotion.color-icon.border-icon:hover {
	background-color: #004e72;
}

.color-bg-icon.soc-vine, a.soc-vine.bg-icon:hover, .soc-vine.color-icon.border-icon:hover {
	background-color: #00b389;
}

.color-bg-icon.soc-flickr, a.soc-flickr.bg-icon:hover, .soc-flickr.color-icon.border-icon:hover {
	background-color: #0062dd;
}

.color-bg-icon.soc-500px, a.soc-500px.bg-icon:hover, .soc-500px.color-icon.border-icon:hover {
	background-color: #58a9de;
}

.color-bg-icon.soc-instagram, a.soc-instagram.bg-icon:hover, .soc-instagram.color-icon.border-icon:hover {
	background-color: #e1306c;
}

.color-bg-icon.soc-wordpress, a.soc-wordpress.bg-icon:hover, .soc-wordpress.color-icon.border-icon:hover {
	background-color: #464646;
}

.color-bg-icon.soc-tumblr, a.soc-tumblr.bg-icon:hover, .soc-tumblr.color-icon.border-icon:hover {
	background-color: #45556c;
}

.color-bg-icon.soc-blogger, a.soc-blogger.bg-icon:hover, .soc-blogger.color-icon.border-icon:hover {
	background-color: #ec661c;
}

.color-bg-icon.soc-technorati, a.soc-technorati.bg-icon:hover, .soc-technorati.color-icon.border-icon:hover {
	background-color: #5cb030;
}

.color-bg-icon.soc-reddit, a.soc-reddit.bg-icon:hover, .soc-reddit.color-icon.border-icon:hover {
	background-color: #e74a1e;
}

.color-bg-icon.soc-dribbble, a.soc-dribbble.bg-icon:hover, .soc-dribbble.color-icon.border-icon:hover {
	background-color: #e84d88;
}

.color-bg-icon.soc-stumbleupon, a.soc-stumbleupon.bg-icon:hover, .soc-stumbleupon.color-icon.border-icon:hover {
	background-color: #e64011;
}

.color-bg-icon.soc-digg, a.soc-digg.bg-icon:hover, .soc-digg.color-icon.border-icon:hover {
	background-color: #1d1d1b;
}

.color-bg-icon.soc-envato, a.soc-envato.bg-icon:hover, .soc-envato.color-icon.border-icon:hover {
	background-color: #597c3a;
}

.color-bg-icon.soc-behance, a.soc-behance.bg-icon:hover, .soc-behance.color-icon.border-icon:hover {
	background-color: #007eff;
}

.color-bg-icon.soc-delicious, a.soc-delicious.bg-icon:hover, .soc-delicious.color-icon.border-icon:hover {
	background-color: #31a9ff;
}

.color-bg-icon.soc-deviantart, a.soc-deviantart.bg-icon:hover, .soc-deviantart.color-icon.border-icon:hover {
	background-color: #c5d200;
}

.color-bg-icon.soc-forrst, a.soc-forrst.bg-icon:hover, .soc-forrst.color-icon.border-icon:hover {
	background-color: #5b9a68;
}

.color-bg-icon.soc-play, a.soc-play.bg-icon:hover, .soc-play.color-icon.border-icon:hover {
	background-color: #5acdbd;
}

.color-bg-icon.soc-zerply, a.soc-zerply.bg-icon:hover, .soc-zerply.color-icon.border-icon:hover {
	background-color: #9dbc7a;
}

.color-bg-icon.soc-wikipedia, a.soc-wikipedia.bg-icon:hover, .soc-wikipedia.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-apple, a.soc-apple.bg-icon:hover, .soc-apple.color-icon.border-icon:hover {
	background-color: #b9bfc1;
}

.color-bg-icon.soc-flattr, a.soc-flattr.bg-icon:hover, .soc-flattr.color-icon.border-icon:hover {
	background-color: #f67c1a;
}

.color-bg-icon.soc-github, a.soc-github.bg-icon:hover, .soc-github.color-icon.border-icon:hover {
	background-color: #221e1b;
}

.color-bg-icon.soc-chimein, a.soc-chimein.bg-icon:hover, .soc-chimein.color-icon.border-icon:hover {
	background-color: #888688;
}

.color-bg-icon.soc-friendfeed, a.soc-friendfeed.bg-icon:hover, .soc-friendfeed.color-icon.border-icon:hover {
	background-color: #2f72c4;
}

.color-bg-icon.soc-newsvine, a.soc-newsvine.bg-icon:hover, .soc-newsvine.color-icon.border-icon:hover {
	background-color: #075b2f;
}

.color-bg-icon.soc-identica, a.soc-identica.bg-icon:hover, .soc-identica.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-bebo, a.soc-bebo.bg-icon:hover, .soc-bebo.color-icon.border-icon:hover {
	background-color: #ef1011;
}

.color-bg-icon.soc-zynga, a.soc-zynga.bg-icon:hover, .soc-zynga.color-icon.border-icon:hover {
	background-color: #dc0606;
}

.color-bg-icon.soc-steam, a.soc-steam.bg-icon:hover, .soc-steam.color-icon.border-icon:hover {
	background-color: #8f8d8a;
}

.color-bg-icon.soc-xbox, a.soc-xbox.bg-icon:hover, .soc-xbox.color-icon.border-icon:hover {
	background-color: #92c83e;
}

.color-bg-icon.soc-windows, a.soc-windows.bg-icon:hover, .soc-windows.color-icon.border-icon:hover {
	background-color: #00bdf6;
}

.color-bg-icon.soc-outlook, a.soc-outlook.bg-icon:hover, .soc-outlook.color-icon.border-icon:hover {
	background-color: #0072c6;
}

.color-bg-icon.soc-coderwall, a.soc-coderwall.bg-icon:hover, .soc-coderwall.color-icon.border-icon:hover {
	background-color: #3e8dcc;
}

.color-bg-icon.soc-tripadvisor, a.soc-tripadvisor.bg-icon:hover, .soc-tripadvisor.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-appnet, a.soc-appnet.bg-icon:hover, .soc-appnet.color-icon.border-icon:hover {
	background-color: #494949;
}

.color-bg-icon.soc-goodreads, a.soc-goodreads.bg-icon:hover, .soc-goodreads.color-icon.border-icon:hover {
	background-color: #1abc9c;
}

.color-bg-icon.soc-tripit, a.soc-tripit.bg-icon:hover, .soc-tripit.color-icon.border-icon:hover {
	background-color: #1982c3;
}

.color-bg-icon.soc-lanyrd, a.soc-lanyrd.bg-icon:hover, .soc-lanyrd.color-icon.border-icon:hover {
	background-color: #3c80c9;
}

.color-bg-icon.soc-slideshare, a.soc-slideshare.bg-icon:hover, .soc-slideshare.color-icon.border-icon:hover {
	background-color: #4ba3a6;
}

.color-bg-icon.soc-buffer, a.soc-buffer.bg-icon:hover, .soc-buffer.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-rss, a.soc-rss.bg-icon:hover, .soc-rss.color-icon.border-icon:hover {
	background-color: #f26109;
}

.color-bg-icon.soc-vkontakte, a.soc-vkontakte.bg-icon:hover, .soc-vkontakte.color-icon.border-icon:hover {
	background-color: #5a7fa6;
}

.color-bg-icon.soc-disqus, a.soc-disqus.bg-icon:hover, .soc-disqus.color-icon.border-icon:hover {
	background-color: #2e9fff;
}

.color-bg-icon.soc-houzz, a.soc-houzz.bg-icon:hover, .soc-houzz.color-icon.border-icon:hover {
	background-color: #7cc04b;
}

.color-bg-icon.soc-mail, a.soc-mail.bg-icon:hover, .soc-mail.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-patreon, a.soc-patreon.bg-icon:hover, .soc-patreon.color-icon.border-icon:hover {
	background-color: #e44727;
}

.color-bg-icon.soc-paypal, a.soc-paypal.bg-icon:hover, .soc-paypal.color-icon.border-icon:hover {
	background-color: #009cde;
}

.color-bg-icon.soc-playstation, a.soc-playstation.bg-icon:hover, .soc-playstation.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-smugmug, a.soc-smugmug.bg-icon:hover, .soc-smugmug.color-icon.border-icon:hover {
	background-color: #acfd32;
}

.color-bg-icon.soc-swarm, a.soc-swarm.bg-icon:hover, .soc-swarm.color-icon.border-icon:hover {
	background-color: #fc9d3c;
}

.color-bg-icon.soc-triplej, a.soc-triplej.bg-icon:hover, .soc-triplej.color-icon.border-icon:hover {
	background-color: #e53531;
}

.color-bg-icon.soc-yammer, a.soc-yammer.bg-icon:hover, .soc-yammer.color-icon.border-icon:hover {
	background-color: #1175c4;
}

.color-bg-icon.soc-stackoverflow, a.soc-stackoverflow.bg-icon:hover, .soc-stackoverflow.color-icon.border-icon:hover {
	background-color: #fd9827;
}

.color-bg-icon.soc-drupal, a.soc-drupal.bg-icon:hover, .soc-drupal.color-icon.border-icon:hover {
	background-color: #00598e;
}

.color-bg-icon.soc-odnoklassniki, a.soc-odnoklassniki.bg-icon:hover, .soc-odnoklassniki.color-icon.border-icon:hover {
	background-color: #f48420;
}

.color-bg-icon.soc-android, a.soc-android.bg-icon:hover, .soc-android.color-icon.border-icon:hover {
	background-color: #8ec047;
}

.color-bg-icon.soc-meetup, a.soc-meetup.bg-icon:hover, .soc-meetup.color-icon.border-icon:hover {
	background-color: #e2373c;
}

.color-bg-icon.soc-persona, a.soc-persona.bg-icon:hover, .soc-persona.color-icon.border-icon:hover {
	background-color: #e6753d;
}

.color-bg-icon.soc-amazon, a.soc-amazon.bg-icon:hover, .soc-amazon.color-icon.border-icon:hover {
	background-color: #ff9900;
}

.color-bg-icon.soc-ello, a.soc-ello.bg-icon:hover, .soc-ello.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.soc-mixcloud, a.soc-mixcloud.bg-icon:hover, .soc-mixcloud.color-icon.border-icon:hover {
	background-color: #314359;
}

.color-bg-icon.soc-8tracks, a.soc-8tracks.bg-icon:hover, .soc-8tracks.color-icon.border-icon:hover {
	background-color: #122c4b;
}

.color-bg-icon.soc-twitch, a.soc-twitch.bg-icon:hover, .soc-twitch.color-icon.border-icon:hover {
	background-color: #6441a5;
}

.color-bg-icon.soc-airbnb, a.soc-airbnb.bg-icon:hover, .soc-airbnb.color-icon.border-icon:hover {
	background-color: #ff5a5f;
}

.color-bg-icon.soc-pocket, a.soc-pocket.bg-icon:hover, .soc-pocket.color-icon.border-icon:hover {
	background-color: #ed4055;
}

.demo-icon {
	display: inline-block;
	text-align: center;
	padding: 15px 20px 10px;
}

.demo-icon span {
	display: block;
}

.demo-icon .name {
	font-size: 1.01em;
	font-weight: 700;
	color: #292a2e;
}

.demo-icon a[class*="soc-"] {
	color: #292a2e;
	font-size: 24px;
	margin: 10px 0 0;
}

.social-icons-demo a[class*="soc-"] {
	margin: 10px !important;
}

.social-icons-demo a + a {
	margin: 0 !important;
}

/*
isotope portfolio and gallery pos
*/
.gallery-item {
	text-align: center;
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	width: 50%;
}

.gallery-columns-3 .gallery-item {
	width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	width: 25%;
}

.gallery-columns-5 .gallery-item {
	width: 20%;
}

.gallery-columns-6 .gallery-item {
	width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	width: 11.11%;
}

.gallery-icon img {
	display: block;
	margin: 0 auto;
	width: 100%;
}

.gallery-caption {
	display: block;
	padding: 0.5em;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}

@media (max-width: 600px) {
	.gallery-columns-4 .gallery-item,
	.gallery-columns-5 .gallery-item,
	.gallery-columns-6 .gallery-item,
	.gallery-columns-7 .gallery-item,
	.gallery-columns-8 .gallery-item,
	.gallery-columns-9 .gallery-item {
		width: 33%;
	}
	.gallery-columns-4 .gallery-caption,
	.gallery-columns-5 .gallery-caption,
	.gallery-columns-6 .gallery-caption,
	.gallery-columns-7 .gallery-caption,
	.gallery-columns-8 .gallery-caption,
	.gallery-columns-9 .gallery-caption {
		display: none;
	}
}

/**
 * Post Formats titles
 */
.format-aside .entry-title,
.format-image .entry-title,
.format-video .entry-title,
.format-quote .entry-title,
.format-gallery .entry-title,
.format-status .entry-title,
.format-link .entry-title,
.format-audio .entry-title,
.format-chat .entry-title {
	font-size: 24px;
}

.isotope_container {
	-webkit-transition: height 0.2s ease-in-out 0s;
	-moz-transition: height 0.2s ease-in-out 0s;
	-o-transition: height 0.2s ease-in-out 0s;
	-ms-transition: height 0.2s ease-in-out 0s;
	transition: height 0.2s ease-in-out 0s;
}

/*switcher styles*/
#switcher {
	position: fixed;
	top: 22%;
	right: -200px;
	width: 200px;
	background-color: #404040;
	color: #ffffff;
	z-index: 9;
	font-size: 15px;
	padding-bottom: 20px;
	-webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	-ms-transition: all 0.6s ease;
	transition: all 0.6s ease;
}

#switcher.active {
	right: 0;
}

#switcher a {
	color: #f96380;
}

#switcher .checkbox {
	margin: 4px 0;
	line-height: 1.5;
}

#switcher h4 {
	line-height: 60px;
	font-size: 18px;
	text-align: center;
	color: #999999;
	background-color: #222;
	margin: 0;
	font-weight: 300;
	letter-spacing: 0;
	padding: 0 36px;
}

#switcher span[class^="rt-icon2-"] {
	display: block;
	position: absolute;
	width: 60px;
	height: 60px;
	line-height: 60px;
	color: #ffffff;
	font-size: 24px;
	background-color: #222;
	text-align: center;
	left: -60px;
	top: 0;
	cursor: pointer;
	z-index: 2;
}

#switcher span[class^="rt-icon2-"]:hover {
	color: #999999;
}

#switcher span[class="rt-icon2-delete-outline"] {
	background-color: transparent;
	z-index: 1;
	left: -20px;
}

#switcher li {
	margin-bottom: 0px;
	padding: 0 4px;
}

#switcher p {
	font-weight: 300;
	font-size: 16px;
	line-height: 1;
	margin: 0 0 10px;
}

#switcher label {
	color: #999999;
}

#switcher ul {
	margin-bottom: 0;
}

#switcher .tooltip {
	min-width: 110px;
}

#switcher .tooltip-arrow {
	display: none;
}

#switcher-version a {
	padding: 13px 14px;
	display: inline-block;
	line-height: 1;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
}

#switcher-version a:hover {
	color: #e4344e !important;
}

#switcher-version a.light {
	background-color: #ffffff;
	color: #292a2e;
}

#switcher-version a.dark {
	color: #ffffff;
	background-color: #262626;
}

#switcher-colors a {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 40px;
	background-color: #f96380;
	overflow: hidden;
}

#switcher-colors a:hover {
	opacity: 0.7;
}

#switcher-colors a.color2 {
	background-color: #e96ac2;
}

#switcher-colors a.color3 {
	background-color: #ffa276;
}

#switcher-colors {
	line-height: 0;
}

#switcher-patterns a {
	display: block;
	line-height: 1;
	height: 40px;
	width: 40px;
	overflow: hidden;
	background-color: #ffffff;
}

#switcher-patterns a img {
	opacity: 0;
}

#switcher-patterns a:hover {
	opacity: 0.7;
}

#switcher-patterns li:first-child a img {
	opacity: 1;
}

#switcher_accent_color,
#switcher_color_scheme,
#switcher_layout,
#switcher_patterns {
	padding: 26px 30px 0px;
}

.checkbox-slider--b-flat {
	position: relative;
}

.checkbox-slider--b-flat input {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 0%;
	margin: 0 0;
	cursor: pointer;
	zoom: 1;
	-webkit-opacity: 0;
	-moz-opacity: 0;
	opacity: 0;
	filter: alpha(opacity=0);
}

.checkbox-slider--b-flat input + span {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.checkbox-slider--b-flat input + span:before {
	position: absolute;
	left: 0px;
	display: inline-block;
}

.checkbox-slider--b-flat input + span > h4 {
	display: inline;
}

.checkbox-slider--b-flat input + span {
	padding-left: 40px;
}

.checkbox-slider--b-flat input + span:before {
	content: "";
	height: 20px;
	width: 40px;
	background: #ffffff;
}

.checkbox-slider--b-flat input + span:after {
	width: 20px;
	height: 20px;
	position: absolute;
	left: 0px;
	top: 0;
	display: block;
	background: #ffffff;
	transition: margin-left 0.4s ease-in-out;
	text-align: center;
	font-weight: bold;
	content: "";
}

.checkbox-slider--b-flat input:checked + span:after {
	margin-left: 20px;
	content: "";
}

.checkbox-slider--b-flat input:checked + span:before {
	transition: background 0.2s ease-in;
}

.checkbox-slider--b-flat input + span {
	padding-left: 30px;
}

.checkbox-slider--b-flat input + span:before {
	border-radius: 20px;
	width: 40px;
}

.checkbox-slider--b-flat input + span:after {
	background: #f96380;
	content: "";
	width: 20px;
	border: solid transparent 2px;
	background-clip: padding-box;
	border-radius: 20px;
}

.checkbox-slider--b-flat input:checked + span:after {
	content: "";
	margin-left: 20px;
	border: solid transparent 2px;
	background-clip: padding-box;
}

.checkbox-slider--b-flat input:checked + span:before {
	background: #ffffff;
}

.checkbox-slider--b-flat.checkbox-slider-md input + span:before {
	border-radius: 30px;
}

.checkbox-slider--b-flat.checkbox-slider-md input + span:after {
	border-radius: 30px;
}

.checkbox-slider--b-flat.checkbox-slider-lg input + span:before {
	border-radius: 40px;
}

.checkbox-slider--b-flat.checkbox-slider-lg input + span:after {
	border-radius: 40px;
}

.checkbox-slider--b-flat input + span:before {
	box-shadow: none;
}

/*# sourceMappingURL=../includes/maps/main.css.map */
